// Core
import React, { FC, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { FinalForm } from 'components/FinalForm/FinalForm';
import { RouteLeavingGuard } from 'components/RouteLeavingGuard/RouteLeavingGuard';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';

// Types
import { ClientType } from './clientTypes';

// Style
import './Client.scss';

const ClientInner: FC<ClientType> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { acceptRouteLeaving } = useSelector(
        (state: State) => state.ui,
        isEqual,
    );

    const [hasTheFormChange, setHasTheFormChange] = useState(false);

    const dontShowAcceptRouteLeaving = () => {
        dispatch(uiCombineActions.acceptRouteLeavingAction());
    };

    return (
        <div className="client-page">
            <RouteLeavingGuard
                when={hasTheFormChange && !acceptRouteLeaving}
                navigate={(path: any) => {
                    history.push(path);
                }}
                shouldBlockNavigation={() => {
                    if (hasTheFormChange) {
                        return true;
                    }
                    return false;
                }}
                acceptRouteLeaving={acceptRouteLeaving}
                dontShowAcceptRouteLeaving={dontShowAcceptRouteLeaving}
            />

            <FinalForm
                formName="clientForm"
                hasTheFormChange={hasTheFormChange}
                setHasTheFormChange={setHasTheFormChange}
            />
        </div>
    );
};

export const Client = memo(ClientInner);
