// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libraries
import { Tooltip } from '@material-ui/core';

// Assets
import { User } from 'assets/images/svgr/user';
import { Conteragent } from 'assets/images/svgr/contragent';
import { ArrowSimple } from 'assets/images/svgr/arrowSimple';

// Utils
import { getBlockNameTitle } from 'utils/constants';

// Types
import { FormBlockHeaderTypes } from './formBlockHeaderTypes';

// Style
import './FormBlockHeader.scss';

const FormBlockHeaderInner: FC<FormBlockHeaderTypes> = ({
    formName,
    isRollup,
    formPartName,
    agentsCounter,
    rollupToggler,
}) => {
    const [rollupTitle, setRollupTitle] = useState('');

    const definedRollupTitle = (
        name: 'sender' | 'receiver',
        counter: number,
        marker: boolean,
    ) => {
        let title = '';

        if (marker) {
            title += 'Показать';
        } else {
            title += 'Скрыть';
        }

        if (name === 'sender') {
            if (counter > 1) {
                title += ' отправителей';
            } else {
                title += ' отправителя';
            }
        }

        if (name === 'receiver') {
            if (counter > 1) {
                title += ' получателей';
            } else {
                title += ' получателя';
            }
        }

        return title;
    };

    useEffect(() => {
        if (
            (formPartName === 'sender' || formPartName === 'receiver') &&
            agentsCounter
        ) {
            setRollupTitle(
                definedRollupTitle(formPartName, agentsCounter, isRollup),
            );
        }
    }, [isRollup, agentsCounter, formPartName]);

    const triggerRollup = () => {
        rollupToggler(!isRollup);
    };

    return (
        <div className="form-part__header">
            <div className="form-part__header-icon">
                {formPartName === 'sender' && <User />}
                {formPartName === 'receiver' && <Conteragent />}
            </div>

            <div className="form-part__header-title">
                <span className="h--6">
                    {' '}
                    {getBlockNameTitle(formName, formPartName)}
                </span>
            </div>

            <Tooltip title={rollupTitle} placement="left">
                <button
                    type="button"
                    className="form-part__header-rollup"
                    onClick={triggerRollup}>
                    <ArrowSimple />
                </button>
            </Tooltip>
        </div>
    );
};

export const FormBlockHeader = memo(FormBlockHeaderInner);
