export interface FormState {
    formCleaner: boolean;
    ticket: null | string;
    loadFromFile: boolean;
    initialFormState: FormStateType;
    formErrors: FormStateType;
    wsErrors: ContractorTypes;
    operators: OperatorType[];
    suppliers: string[];
    statusInfo: StatusInfoResponseDataType;
    agentInfo: AgentInfoResponseDataType;
    agentsCounters: AgentsCounters;
    connectedId: string[][];
    notConnectedId: string[][];
    isFormInSubmiting: boolean;
}

export type Contractor = MainObjectType;

export type ContractorsType = keyof ContractorTypes;

export interface ContractorTypes {
    contractor_type_1: MainObjectsArrType | [];
    contractor_type_2: MainObjectsArrType | [];
    contractor_type_3: MainObjectsArrType | [];
    contractor_type_4: MainObjectsArrType | [];
}

export type MainObjectsArrType = Array<MainObjectType>;

export interface MainObjectType {
    contractor_type?: number;
    fid?: number;
    with_ticket?: boolean;
    inn?: string;
    kpp?: string;
    name?: string;
    lastname?: string;
    firstname?: string;
    patronymic?: string;
    id?: string;
    email?: string;
    supplier?: string;
    prefix?: string;
    message?: string;
    status?: 0 | 1;
    ticket?: null | string;
    application_identifier?: string;
    operator?: string;
    status_ticket?: string;
    autoLoadInn?: boolean;
    autoLoadId?: boolean;
    wsChecked?: boolean;
    isRolluped?: boolean;
}

export interface SenderMainObjectTypeForRequest extends MainObjectType {
    is_supplier?: string;
}

export interface DeleteWsErrorType {
    agentId: number;
    contractor: number;
}

export interface AddFieldErrorType {
    formName: FormNameType;
    formPart: FormPartNameType;
    formBlock: FormBlockNameType;
    blockId: string;
    fieldType: keyof MainObjectType | keyof LoginType;
    error: any;
}

export interface UpdateFieldsErrorType {
    contractor: ContractorsType;
    errorsArr: MainObjectsArrType;
}

export interface DeleteFieldErrorType {
    formName: FormNameType;
    formPartName: FormPartNameType;
    formBlockName: FormBlockNameType;
    blockId: number;
}

export interface OperatorType {
    agreement: boolean;
    invitation: boolean;
    name: string;
    prefix: string;
    roaming: boolean;
    state: string;
}

export interface FormStateType {
    clientForm?: COFormType;
    operatorForm?: COFormType;
    statusInfoForm?: StatusInfoFormType;
    agentInfoForm?: AgentInfoFormType;
    loginForm?: LoginFormType;
}

export interface COFormType {
    sender?: SenderType;
    receiver?: ReceiverType;
    application_identifier?: string;
}

export interface StatusInfoFormType {
    statusInfo?: StatusInfoType;
}

export interface AgentInfoFormType {
    agentInfo?: AgentInfoType;
}

export interface LoginFormType {
    login?: LoginType;
}

export type FormPartStateTypes = COFormType &
    StatusInfoFormType &
    AgentInfoFormType &
    LoginFormType;

export type FormBlockStateTypes = SenderType &
    ReceiverType &
    StatusInfoType &
    AgentInfoType &
    LoginType;

export interface SenderType {
    senders?: MainObjectsArrType;
    ticket?: null | string;
    isRolluped?: boolean;
}

export interface ReceiverType {
    receivers?: MainObjectsArrType;
    operator?: string;
    agreement?: Blob;
    supplier?: string;
    identifier?: string;
    isRolluped?: boolean;
}

export type StatusInfoType = {
    id: string;
    status_ticket: string;
};

export type AgentInfoType = {
    inn: string;
    kpp: string;
};

export type LoginType = {
    loginName?: string;
    password?: string;
};

export type FormNameType = keyof FormStateType;

export type FormPartNameType =
    | keyof COFormType
    | keyof StatusInfoFormType
    | keyof AgentInfoFormType
    | keyof LoginFormType;

export type FormBlockNameType =
    | keyof SenderType
    | keyof ReceiverType
    | keyof StatusInfoType
    | keyof AgentInfoType
    | keyof LoginType;

export type MainObjectNameType = keyof MainObjectType;

export interface FetchAbonentFormType {
    operator: string;
    sender: MainObjectsArrType;
    receiver: MainObjectsArrType;
    supplier: string;
    ticket?: string;
    agreement?: Blob;
}

export interface FetchOperatorFormType {
    request_id?: string;
    senders: MainObjectsArrType;
    receivers: MainObjectsArrType;
}

export interface FetchStatusFormType {
    prefix?: string;
    id: string;
    status_ticket: string;
}

export interface FetchConteragentFormType {
    inn: string;
    kpp?: string;
}

export interface AutofilType {
    name: string;
    value: string;
}

export interface AutoFillByIdOperatorAbonentType {
    id: string;
    inn: string;
    kpp: string;
    name: string;
    lastname: string;
    firstname: string;
    patronymic: string;
}

export interface AutoFillByIdOperatorType {
    abonents: AutoFillByIdOperatorAbonentType[];
    is_abonent: boolean;
}

export interface AutoFillByIdOperatorResponseType {
    status: 0 | 1;
    abonents: AutoFillByIdOperatorType;
    text: string;
}

export interface UpdateFromInnType {
    name: string;
    value:
        | {
              inn?: string;
              lastname: string;
              firstname: string;
              patronymic: string;
          }
        | {
              inn?: string;
              kpp: string;
              name: string;
          };
}

export interface InfoResponseAgentType {
    id?: string;
    inn: string;
    kpp?: string;
    name?: string;
    email?: string;
    lastname?: string;
    firstname?: string;
    patronymic?: string;
}

export type InfoResponseAgentArrType = InfoResponseAgentType[];

export interface StatusInfoTableDataType {
    created: string;
    operator: string;
    request_id: string;
    sender: InfoResponseAgentArrType;
    receiver: InfoResponseAgentArrType;
}

export interface StatusInfoResponseType {
    status: 0 | 1;
    jsdata: StatusInfoTableDataType;
    text: string;
}

export interface StatusInfoResponseDataType {
    id: string;
    ticket: number | null;
    tableData: StatusInfoTableDataType | null;
}

export interface AgentInfoResponseDataType {
    abonents: InfoResponseAgentArrType | null;
    is_abonent: boolean;
    inn: string;
    kpp?: string;
}

export interface AgentInfoResponseType {
    status: 0 | 1;
    abonents: {
        abonents: InfoResponseAgentArrType;
        is_abonent: boolean;
    };
    text: string;
}

// ABONENT METHOD RESPONS TYPES

export interface AbonentResponseSenderType {
    processed: boolean;
    text: string;
    input: {
        id: string;
        inn: string;
        kpp: string;
        name: string;
        firstname: string;
        lastname: string;
        patronymic: string;
        email: string;
        is_supplier: string;
        files: any;
    };
    errors: {
        id: string;
        inn: string;
        kpp: string;
        name: string;
        firstname: string;
        lastname: string;
        patronymic: string;
        email: string;
        is_supplier: string;
        files: any;
    };
}

export interface AbonentResponseReceiverType {
    processed: boolean;
    text: string;
    input: {
        inn: string;
        kpp: string;
        name: string;
        firstname: string;
        lastname: string;
        patronymic: string;
    };
    errors: {
        inn: string;
        kpp: string;
        name: string;
        firstname: string;
        lastname: string;
        patronymic: string;
    };
}

export interface AbonentResponseSendersType {
    id: string;
    name: string;
    inn: string;
    kpp: string;
    firstname: string;
    lastname: string;
    patronymic: string;
}

export interface AbonentResponseReceiversType {
    id: string;
    name: string;
    inn: string;
    kpp: string;
    firstname: string;
    lastname: string;
    patronymic: string;
}

export interface AbonentResponseType {
    status: 0 | 1 | 2;
    request_id?: number;
    receiver_operator: string;
    processing_result?: {
        status: 0 | 1 | 2;
        text: string;
        sender: AbonentResponseSenderType[];
        receiver: AbonentResponseReceiverType[];
        senders: AbonentResponseSendersType[];
        receivers: AbonentResponseReceiversType[];
    };
    senders: AbonentResponseSendersType[];
    receivers: AbonentResponseReceiversType[];
    text: string;
}

// OPERATOR METHOD RESPONS TYPES

export interface OperatorResponseAbonentType {
    connected: string[];
    not_connected: string[];
    processed: boolean;
    text: string;
    input: {
        id: string;
        inn: string;
        kpp: string;
        name: string;
        firstname: string;
        lastname: string;
        patronymic: string;
    };
    errors: {
        id: string;
        inn: string;
        kpp: string;
        name: string;
        firstname: string;
        lastname: string;
        patronymic: string;
    };
}

export interface OperatorResponseAbonentsType {
    id: string;
    name: string;
    inn: string;
    kpp: string;
    firstname: string;
    lastname: string;
    patronymic: string;
}

export interface OperatorResponseType {
    status: 0 | 1 | 2 | 401;
    request_id?: number;
    processing_result?: {
        status: 0 | 1 | 2;
        text: string;
        sender: OperatorResponseAbonentType[];
        receiver: OperatorResponseAbonentType[];
        senders: OperatorResponseAbonentsType[];
        receivers: OperatorResponseAbonentsType[];
    };
    text: string;
}

export interface AgentsCounters {
    senders: number;
    receivers: number;
    dublicates: number;
}

export interface ParsedAgent {
    agent: MainObjectType;
    id: number;
}

export const formTypes = {
    // Sync
    SET_FORM_CLEANER_STATE: 'SET_FORM_CLEANER_STATE',
    SET_TICKET_NUMBER: 'SET_TICKET_NUMBER',
    LOAD_FROM_FILE_ON: 'LOAD_FROM_FILE_ON',
    LOAD_FROM_FILE_OFF: 'LOAD_FROM_FILE_OFF',
    CLEAR_WS_ERRORS: 'CLEAR_WS_ERRORS',
    DELETE_WS_ERRORS: 'DELETE_WS_ERRORS',
    ADD_FIELD_ERROR: 'ADD_FIELD_ERROR',
    UPDATE_FIELDS_ERROR: 'UPDATE_FIELDS_ERROR',
    DELETE_FIELDS_ERROR: 'DELETE_FIELDS_ERROR',
    CLEAR_FIELDS_ERROR: 'CLEAR_FIELDS_ERROR',
    WS_ERRORS: 'WS_ERRORS',
    FILL_OPERATORS: 'FILL_OPERATORS',
    FILL_SUPPLIERS: 'FILL_SUPPLIERS',
    FILL_CLIENT_FORM_SENDER: 'FILL_CLIENT_FORM_SENDER',
    FILL_CLIENT_FORM_RECEIVER: 'FILL_CLIENT_FORM_RECEIVER',
    FILL_CLIENT_FORM_OPERATOR: 'FILL_CLIENT_FORM_OPERATOR',
    FILL_CLIENT_FORM_SUPPLIER: 'FILL_CLIENT_FORM_SUPPLIER',
    FILL_OPERATOR_FORM_SENDER: 'FILL_OPERATOR_FORM_SENDER',
    FILL_OPERATOR_FORM_RECEIVER: 'FILL_OPERATOR_FORM_RECEIVER',
    CLEAR_INITIAL_FORM_STATE: 'CLEAR_INITIAL_FORM_STATE',
    UPDATE_AGENT_DATA_FROM_INN: 'UPDATE_AGENT_DATA_FROM_INN',
    UPDATE_AGENT_DATA_FROM_ID: 'UPDATE_AGENT_DATA_FROM_ID',
    FILL_STATUS_TABLE_DATA: 'FILL_STATUS_TABLE_DATA',
    CLEAR_STATUS_TABLE_DATA: 'CLEAR_STATUS_TABLE_DATA',
    FILL_AGENT_TABLE_DATA: 'FILL_AGENT_TABLE_DATA',
    CLEAR_AGENT_TABLE_DATA: 'CLEAR_AGENT_TABLE_DATA',
    SET_AGENTS_COUNTERS_SENDERS: 'SET_AGENTS_COUNTERS_SENDERS',
    SET_AGENTS_COUNTERS_RECEIVERS: 'SET_AGENTS_COUNTERS_RECEIVERS',
    SET_AGENTS_COUNTERS_DUBLICATES: 'SET_AGENTS_COUNTERS_DUBLICATES',
    SET_CONNECTED_ID: 'SET_CONNECTED_ID',
    SET_NOT_CONNECTED_ID: 'SET_NOT_CONNECTED_ID',
    SET_FORM_SUBMITING: 'SET_FORM_SUBMITING',

    // Async
    FETCH_OPERATORS_ASYNC: 'FETCH_OPERATORS_ASYNC',
    FETCH_SUPPLIERS_ASYNC: 'FETCH_SUPPLIERS_ASYNC',
    PARSE_CLIENTS_FROM_FILE_ASYNC: 'PARSE_CLIENTS_FROM_FILE_ASYNC',
    PARSE_OPERATORS_FROM_FILE_ASYNC: 'PARSE_OPERATORS_FROM_FILE_ASYNC',
    FETCH_ABONENT_FORM_ASYNC: 'FETCH_ABONENT_FORM_ASYNC',
    FETCH_OPERATOR_FORM_ASYNC: 'FETCH_OPERATOR_FORM_ASYNC',
    FETCH_STATUS_FORM_ASYNC: 'FETCH_STATUS_FORM_ASYNC',
    FETCH_CONTERAGENT_FORM_ASYNC: 'FETCH_CONTERAGENT_FORM_ASYNC',
    AUTOFILL_BY_INN_NAVIGATOR_ASYNC: 'AUTOFILL_BY_INN_NAVIGATOR_ASYNC',
    AUTOFILL_BY_INN_OPERATOR_ASYNC: 'AUTOFILL_BY_INN_OPERATOR_ASYNC',
    AUTOFILL_BY_ID_OPERATOR_ASYNC: 'AUTOFILL_BY_ID_OPERATOR_ASYNC',
};

interface SetFormCleanerStateActionType {
    type: typeof formTypes.SET_FORM_CLEANER_STATE;
    payload: boolean;
}

interface SetTicketNumberActionType {
    type: typeof formTypes.SET_TICKET_NUMBER;
    payload: string;
}

interface LoadFromFileOnActionType {
    type: typeof formTypes.LOAD_FROM_FILE_ON;
    payload?: any;
}

interface LoadFromFileOffActionType {
    type: typeof formTypes.LOAD_FROM_FILE_OFF;
    payload?: any;
}

interface ClearWsErrorActionType {
    type: typeof formTypes.CLEAR_WS_ERRORS;
    payload?: any;
}

interface DeleteWsErrorActionType {
    type: typeof formTypes.DELETE_WS_ERRORS;
    payload: DeleteWsErrorType;
}

interface AddFieldErrorActionType {
    type: typeof formTypes.ADD_FIELD_ERROR;
    payload: AddFieldErrorType;
}

interface UpdateFieldsErrorActionType {
    type: typeof formTypes.UPDATE_FIELDS_ERROR;
    payload: UpdateFieldsErrorType;
}

interface DeleteFieldErrorActionType {
    type: typeof formTypes.DELETE_FIELDS_ERROR;
    payload: DeleteFieldErrorType;
}

interface ClearFieldsErrorActionType {
    type: typeof formTypes.CLEAR_FIELDS_ERROR;
    payload?: any;
}

interface UpdateWsErrorActionType {
    type: typeof formTypes.WS_ERRORS;
    payload: MainObjectType;
}

interface FillOperatorsActionType {
    type: typeof formTypes.FILL_OPERATORS;
    payload: OperatorType[];
}

interface FillSuppliersActionType {
    type: typeof formTypes.FILL_SUPPLIERS;
    payload: string[];
}

interface FillClientFormSenderActionType {
    type: typeof formTypes.FILL_CLIENT_FORM_SENDER;
    payload: ParsedAgent;
}

interface FillClientFormReceiverActionType {
    type: typeof formTypes.FILL_CLIENT_FORM_RECEIVER;
    payload: ParsedAgent;
}

interface FillClientFormOperatorActionType {
    type: typeof formTypes.FILL_CLIENT_FORM_OPERATOR;
    payload: string;
}

interface FillClientFormSupplierActionType {
    type: typeof formTypes.FILL_CLIENT_FORM_SUPPLIER;
    payload: string;
}

interface FillOperatorFormSenderActionType {
    type: typeof formTypes.FILL_OPERATOR_FORM_SENDER;
    payload: ParsedAgent;
}

interface FillOperatorFormReceiverActionType {
    type: typeof formTypes.FILL_OPERATOR_FORM_RECEIVER;
    payload: ParsedAgent;
}

interface ClearInitialFormStateActionType {
    type: typeof formTypes.CLEAR_INITIAL_FORM_STATE;
    payload?: any;
}

interface AutoFillByInnNavigatorActionType {
    type: typeof formTypes.AUTOFILL_BY_INN_NAVIGATOR_ASYNC;
    payload: AutofilType;
}

interface AutoFillByInnOperatorActionType {
    type: typeof formTypes.AUTOFILL_BY_INN_OPERATOR_ASYNC;
    payload: AutofilType;
}

interface AutoFillByIdOperatorActionType {
    type: typeof formTypes.AUTOFILL_BY_ID_OPERATOR_ASYNC;
    payload: AutofilType;
}

interface ParseClientsFromFileActionType {
    type: typeof formTypes.PARSE_CLIENTS_FROM_FILE_ASYNC;
    payload: Blob;
}

interface ParseOperatorsFromFileActionType {
    type: typeof formTypes.PARSE_OPERATORS_FROM_FILE_ASYNC;
    payload: Blob;
}

interface FetchAbonentFormActionType {
    type: typeof formTypes.FETCH_ABONENT_FORM_ASYNC;
    payload: FetchAbonentFormType;
}

interface FetchOperatorFormActionType {
    type: typeof formTypes.FETCH_OPERATOR_FORM_ASYNC;
    payload: FetchOperatorFormType;
}

interface FetchStatusFormActionType {
    type: typeof formTypes.FETCH_STATUS_FORM_ASYNC;
    payload: FetchStatusFormType;
}

interface FetchConteragentFormActionType {
    type: typeof formTypes.FETCH_CONTERAGENT_FORM_ASYNC;
    payload: FetchConteragentFormType;
}

interface UpdateAgentDataFromInnActionType {
    type: typeof formTypes.UPDATE_AGENT_DATA_FROM_INN;
    payload: UpdateFromInnType;
}

interface UpdateAgentDataFromIdActionType {
    type: typeof formTypes.UPDATE_AGENT_DATA_FROM_ID;
    payload: UpdateFromInnType;
}

interface FillStatusTableDataActionType {
    type: typeof formTypes.FILL_STATUS_TABLE_DATA;
    payload: StatusInfoResponseDataType;
}

interface ClearStatusTableDataActionType {
    type: typeof formTypes.CLEAR_STATUS_TABLE_DATA;
    payload?: any;
}

interface FillAgentTableDataActionType {
    type: typeof formTypes.FILL_AGENT_TABLE_DATA;
    payload: AgentInfoResponseDataType;
}

interface ClearAgentTableDataActionType {
    type: typeof formTypes.CLEAR_AGENT_TABLE_DATA;
    payload?: any;
}

interface SetAgentsCountersSendersActionType {
    type: typeof formTypes.SET_AGENTS_COUNTERS_SENDERS;
    payload: number;
}

interface SetAgentsCountersReceiversActionType {
    type: typeof formTypes.SET_AGENTS_COUNTERS_RECEIVERS;
    payload: number;
}

interface SetAgentsCountersDublicatesActionType {
    type: typeof formTypes.SET_AGENTS_COUNTERS_DUBLICATES;
    payload: number;
}

interface SetConnectedIdActionType {
    type: typeof formTypes.SET_CONNECTED_ID;
    payload: string[][];
}
interface SetNotConnectedIdActionType {
    type: typeof formTypes.SET_NOT_CONNECTED_ID;
    payload: string[][];
}
interface SetFormSubmitingActionType {
    type: typeof formTypes.SET_FORM_SUBMITING;
    payload: boolean;
}

export type FormActionTypes =
    | SetFormCleanerStateActionType
    | SetTicketNumberActionType
    | LoadFromFileOnActionType
    | LoadFromFileOffActionType
    | ClearWsErrorActionType
    | DeleteWsErrorActionType
    | AddFieldErrorActionType
    | UpdateFieldsErrorActionType
    | DeleteFieldErrorActionType
    | ClearFieldsErrorActionType
    | UpdateWsErrorActionType
    | FillOperatorsActionType
    | FillSuppliersActionType
    | FillClientFormSenderActionType
    | FillClientFormReceiverActionType
    | FillClientFormOperatorActionType
    | FillClientFormSupplierActionType
    | FillOperatorFormSenderActionType
    | FillOperatorFormReceiverActionType
    | ClearInitialFormStateActionType
    | AutoFillByInnNavigatorActionType
    | AutoFillByInnOperatorActionType
    | AutoFillByIdOperatorActionType
    | ParseClientsFromFileActionType
    | ParseOperatorsFromFileActionType
    | FetchAbonentFormActionType
    | FetchOperatorFormActionType
    | FetchStatusFormActionType
    | FetchConteragentFormActionType
    | UpdateAgentDataFromInnActionType
    | UpdateAgentDataFromIdActionType
    | FillStatusTableDataActionType
    | ClearStatusTableDataActionType
    | FillAgentTableDataActionType
    | ClearAgentTableDataActionType
    | SetAgentsCountersSendersActionType
    | SetAgentsCountersReceiversActionType
    | SetAgentsCountersDublicatesActionType
    | SetConnectedIdActionType
    | SetNotConnectedIdActionType
    | SetFormSubmitingActionType;
