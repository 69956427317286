// Core
import React, { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

// Third party libraries
import { Tooltip } from '@material-ui/core';

// Assets
import { Main } from 'assets/images/svgr/main';
import { Operator } from 'assets/images/svgr/operator';
import { Status } from 'assets/images/svgr/status';
import { AgentInfo } from 'assets/images/svgr/agent-info';

// Routes
import { link } from './menuLink';

// Types
import { MenuTypes } from './menuTypes';

// Style
import './Menu.scss';

const MenuInner: FC<MenuTypes> = ({ isMenuShow }) => (
    <nav className="menu">
        {link.map((elem) => (
            <NavLink
                key={`${elem.name}`}
                className="nav"
                activeClassName="nav--active"
                to={elem.link}
                tabIndex={-1}>
                <Tooltip
                    title={!isMenuShow ? elem.title : ''}
                    placement="right"
                    arrow
                    className="menu-tooltip">
                    <button type="button" className="menu__item">
                        <div className="menu__item-icon">
                            {elem.name === 'client' && <Main />}
                            {elem.name === 'statusInfo' && <Status />}
                            {elem.name === 'agentInfo' && <AgentInfo />}
                            {elem.name === 'operator' && <Operator />}
                        </div>

                        <div className="menu__item-title">
                            <span className="h--6">{elem.title}</span>
                        </div>
                    </button>
                </Tooltip>
            </NavLink>
        ))}
    </nav>
);

export const Menu = memo(MenuInner);
