// Core
import { put, apply } from 'redux-saga/effects';

// API
import { api } from 'api';

// Utils
import { deepTrim } from 'utils/deepTrim';

// Types
import { SagaIterator } from '@redux-saga/types';
import {
    StatusInfoResponseType,
    FetchStatusFormType,
} from 'redux/form/formTypes';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

export function* fetchStatusFormWorker({
    payload: statusData,
}: {
    type: string;
    payload: FetchStatusFormType;
}): SagaIterator {
    try {
        yield put(uiCombineActions.startFetchingAction());

        deepTrim(statusData);

        const response = yield apply(api, api.status.fetch, [
            statusData.id,
            parseInt(statusData.status_ticket, 10),
        ]);
        const { status, jsdata, text }: StatusInfoResponseType = yield apply(
            response,
            response.json,
            [],
        );

        if (status !== 0) {
            yield put(
                uiCombineActions.addToastMessageAction({
                    status,
                    text,
                }),
            );
        }

        yield put(
            formCombineActions.fillStatusInfoTableDataAction({
                id: statusData.id,
                ticket: parseInt(statusData.status_ticket, 10),
                tableData: jsdata,
            }),
        );

        if (response.status !== 200) {
            throw new Error(text);
        }
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(error, 'fetchStatusForm worker'),
        );
    } finally {
        yield put(uiCombineActions.stopFetchingAction());
    }
}
