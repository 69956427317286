// Core
import { combineReducers } from 'redux';

// Reducers
import { uiReducer } from './ui/uiReducer';
import { authReducer } from './auth/authReducer';
import { formReducer } from './form/formReducer';

export const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    form: formReducer,
});
