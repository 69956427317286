// Types
import {
    formTypes,
    AutofilType,
    ParsedAgent,
    OperatorType,
    MainObjectType,
    FormActionTypes,
    DeleteWsErrorType,
    AddFieldErrorType,
    UpdateFromInnType,
    FetchStatusFormType,
    DeleteFieldErrorType,
    FetchAbonentFormType,
    FetchOperatorFormType,
    UpdateFieldsErrorType,
    FetchConteragentFormType,
    AgentInfoResponseDataType,
    StatusInfoResponseDataType,
} from './formTypes';

export const formCombineActions = {
    // Sync
    setFormCleanerStateAction: (cleanerState: boolean): FormActionTypes => ({
        type: formTypes.SET_FORM_CLEANER_STATE,
        payload: cleanerState,
    }),
    setTicketNumberAction: (ticket: string | null): FormActionTypes => ({
        type: formTypes.SET_TICKET_NUMBER,
        payload: ticket,
    }),
    loadFromFileOnAction: (): FormActionTypes => ({
        type: formTypes.LOAD_FROM_FILE_ON,
        payload: undefined,
    }),
    loadFromFileOffAction: (): FormActionTypes => ({
        type: formTypes.LOAD_FROM_FILE_OFF,
        payload: undefined,
    }),
    clearWsErrorAction: (): FormActionTypes => ({
        type: formTypes.CLEAR_WS_ERRORS,
        payload: undefined,
    }),
    deleteWsErrorAction: (
        deleteWsErrorObj: DeleteWsErrorType,
    ): FormActionTypes => ({
        type: formTypes.DELETE_WS_ERRORS,
        payload: deleteWsErrorObj,
    }),
    addFieldErrorAction: (
        addFieldErrorObj: AddFieldErrorType,
    ): FormActionTypes => ({
        type: formTypes.ADD_FIELD_ERROR,
        payload: addFieldErrorObj,
    }),
    updateFieldsErrorAction: (
        updateFieldsErrorObj: UpdateFieldsErrorType,
    ): FormActionTypes => ({
        type: formTypes.UPDATE_FIELDS_ERROR,
        payload: updateFieldsErrorObj,
    }),
    deleteFieldErrorAction: (
        deleteFieldErrorObj: DeleteFieldErrorType,
    ): FormActionTypes => ({
        type: formTypes.DELETE_FIELDS_ERROR,
        payload: deleteFieldErrorObj,
    }),
    clearFieldsErrorAction: (): FormActionTypes => ({
        type: formTypes.CLEAR_FIELDS_ERROR,
        payload: undefined,
    }),
    updateWsErrorAction: (wsError: MainObjectType): FormActionTypes => ({
        type: formTypes.WS_ERRORS,
        payload: wsError,
    }),
    fillOperatorsAction: (operators: OperatorType[]): FormActionTypes => ({
        type: formTypes.FILL_OPERATORS,
        payload: operators,
    }),
    fillSuppliersAction: (suppliers: string[]): FormActionTypes => ({
        type: formTypes.FILL_SUPPLIERS,
        payload: suppliers,
    }),
    fillClientFormSenderAction: (sender: ParsedAgent): FormActionTypes => ({
        type: formTypes.FILL_CLIENT_FORM_SENDER,
        payload: sender,
    }),
    fillClientFormReceiverAction: (receiver: ParsedAgent): FormActionTypes => ({
        type: formTypes.FILL_CLIENT_FORM_RECEIVER,
        payload: receiver,
    }),
    fillClientFormOperatorAction: (operator: string): FormActionTypes => ({
        type: formTypes.FILL_CLIENT_FORM_OPERATOR,
        payload: operator,
    }),
    fillClientFormSupplierAction: (supplier: string): FormActionTypes => ({
        type: formTypes.FILL_CLIENT_FORM_SUPPLIER,
        payload: supplier,
    }),
    fillOperatorFormSenderAction: (sender: ParsedAgent): FormActionTypes => ({
        type: formTypes.FILL_OPERATOR_FORM_SENDER,
        payload: sender,
    }),
    fillOperatorFormReceiverAction: (
        receiver: ParsedAgent,
    ): FormActionTypes => ({
        type: formTypes.FILL_OPERATOR_FORM_RECEIVER,
        payload: receiver,
    }),
    clearInitialFormStateAction: (): FormActionTypes => ({
        type: formTypes.CLEAR_INITIAL_FORM_STATE,
        payload: undefined,
    }),
    updateAgentDataFromInnAction: (
        newAgentDataObj: UpdateFromInnType,
    ): FormActionTypes => ({
        type: formTypes.UPDATE_AGENT_DATA_FROM_INN,
        payload: newAgentDataObj,
    }),
    updateAgentDataFromIdAction: (
        newAgentDataObj: UpdateFromInnType,
    ): FormActionTypes => ({
        type: formTypes.UPDATE_AGENT_DATA_FROM_ID,
        payload: newAgentDataObj,
    }),
    fillStatusInfoTableDataAction: (
        statusInfoData: StatusInfoResponseDataType,
    ): FormActionTypes => ({
        type: formTypes.FILL_STATUS_TABLE_DATA,
        payload: statusInfoData,
    }),
    clearStatusInfoTableDataAction: (): FormActionTypes => ({
        type: formTypes.CLEAR_STATUS_TABLE_DATA,
        payload: undefined,
    }),
    fillAgentInfoTableDataAction: (
        tableData: AgentInfoResponseDataType,
    ): FormActionTypes => ({
        type: formTypes.FILL_AGENT_TABLE_DATA,
        payload: tableData,
    }),
    clearAgentInfoTableDataAction: (): FormActionTypes => ({
        type: formTypes.CLEAR_AGENT_TABLE_DATA,
        payload: undefined,
    }),
    setAgentsCountersSendersAction: (counter: number): FormActionTypes => ({
        type: formTypes.SET_AGENTS_COUNTERS_SENDERS,
        payload: counter,
    }),
    setAgentsCountersReceiversAction: (counter: number): FormActionTypes => ({
        type: formTypes.SET_AGENTS_COUNTERS_RECEIVERS,
        payload: counter,
    }),
    setAgentsCountersDublicatesAction: (counter: number): FormActionTypes => ({
        type: formTypes.SET_AGENTS_COUNTERS_DUBLICATES,
        payload: counter,
    }),
    setConnectedIdAction: (connectedIdArr: string[][]): FormActionTypes => ({
        type: formTypes.SET_CONNECTED_ID,
        payload: connectedIdArr,
    }),
    setNotConnectedIdAction: (
        notConnectedIdArr: string[][],
    ): FormActionTypes => ({
        type: formTypes.SET_NOT_CONNECTED_ID,
        payload: notConnectedIdArr,
    }),
    setFormSubmitingAction: (isFormSubmiting: boolean): FormActionTypes => ({
        type: formTypes.SET_FORM_SUBMITING,
        payload: isFormSubmiting,
    }),

    // Async
    fetchOperatorsAction: (): FormActionTypes => ({
        type: formTypes.FETCH_OPERATORS_ASYNC,
    }),
    fetchSuppliersAction: (): FormActionTypes => ({
        type: formTypes.FETCH_SUPPLIERS_ASYNC,
    }),
    parseClientsFromFileAction: (file: Blob): FormActionTypes => ({
        type: formTypes.PARSE_CLIENTS_FROM_FILE_ASYNC,
        payload: file,
    }),
    parseOperatorsFromFileAction: (file: Blob): FormActionTypes => ({
        type: formTypes.PARSE_OPERATORS_FROM_FILE_ASYNC,
        payload: file,
    }),
    fetchAbonentFormAction: (
        abonentData: FetchAbonentFormType,
    ): FormActionTypes => ({
        type: formTypes.FETCH_ABONENT_FORM_ASYNC,
        payload: abonentData,
    }),
    fetchOperatorFormAction: (
        operatorData: FetchOperatorFormType,
    ): FormActionTypes => ({
        type: formTypes.FETCH_OPERATOR_FORM_ASYNC,
        payload: operatorData,
    }),
    fetchStatusFormAction: (
        statusData: FetchStatusFormType,
    ): FormActionTypes => ({
        type: formTypes.FETCH_STATUS_FORM_ASYNC,
        payload: statusData,
    }),
    fetchConteragentFormAction: (
        contragentData: FetchConteragentFormType,
    ): FormActionTypes => ({
        type: formTypes.FETCH_CONTERAGENT_FORM_ASYNC,
        payload: contragentData,
    }),
    autoFillByInnNavigatorAction: (
        autiFillObj: AutofilType,
    ): FormActionTypes => ({
        type: formTypes.AUTOFILL_BY_INN_NAVIGATOR_ASYNC,
        payload: autiFillObj,
    }),
    autoFillByInnOperatorAction: (
        autiFillObj: AutofilType,
    ): FormActionTypes => ({
        type: formTypes.AUTOFILL_BY_INN_OPERATOR_ASYNC,
        payload: autiFillObj,
    }),
    autoFillByIdOperatorAction: (
        autiFillObj: AutofilType,
    ): FormActionTypes => ({
        type: formTypes.AUTOFILL_BY_ID_OPERATOR_ASYNC,
        payload: autiFillObj,
    }),
};
