export const getFieldLabel = (fieldType?: string) => {
    if (fieldType === 'inn') {
        return 'ИНН';
    }

    if (fieldType === 'kpp') {
        return 'КПП';
    }

    if (fieldType === 'name') {
        return 'Название организации';
    }

    if (fieldType === 'lastname') {
        return 'Фамилия';
    }

    if (fieldType === 'firstname') {
        return 'Имя';
    }

    if (fieldType === 'patronymic') {
        return 'Отчество';
    }

    if (fieldType === 'id') {
        return 'ИдЭДО';
    }

    if (fieldType === 'email') {
        return 'E-mail';
    }

    if (fieldType === 'ticket') {
        return 'Номер заявления';
    }

    if (fieldType === 'status_ticket') {
        return 'Номер заявления';
    }

    if (fieldType === 'application_identifier') {
        return 'Идентификатор заявки в системе роумингового оператора';
    }

    if (fieldType === 'supplier') {
        return 'Если Ваши контрагенты - клиенты операторов ЭДО Контур или Корус, то укажите, кем Вы являетесь:';
    }

    if (fieldType === 'operator') {
        return 'Укажите оператора Вашего контрагента';
    }

    if (fieldType === 'loginName') {
        return 'Логин';
    }

    if (fieldType === 'password') {
        return 'Пароль';
    }

    return '';
};

export const getPageHeaders: (pathname?: string) => string = (pathname) => {
    if (pathname === '/client') {
        return 'Заявление на подключение роуминга между контрагентами';
    }

    if (pathname === '/status-info') {
        return 'Информация о заявлении';
    }

    if (pathname === '/agent-info') {
        return 'Проверьте, кто обменивается документами через АО «Калуга Астрал»';
    }

    if (pathname === '/operator') {
        return 'Заявление на подключение роуминга между контрагентами';
    }

    return '';
};

export const getBlockNameTitle: (
    formName?: string,
    formBlockName?: string,
) => string = (formName, formBlockName) => {
    if (formName === 'clientForm' && formBlockName === 'sender')
        return 'Данные Вашей организации:';

    if (formName === 'operatorForm' && formBlockName === 'sender')
        return 'Данные Вашего клиента:';

    if (formName === 'clientForm' && formBlockName === 'receiver')
        return 'Данные контрагентов:';

    if (formName === 'operatorForm' && formBlockName === 'receiver')
        return 'Контрагенты в АО "Калуга Астрал":';

    return '';
};

export const ASTRAL_ID = '2AE';

export const OPERATORS_WITH_REQUEST_ID = ['2BM'];

export const OPERATOR_PREFIX_WITH_DUSH = [
    '2BM',
    '2BK',
    '2AL',
    '2JL',
    '2VO',
    '2GS',
    '2LT',
    '2LJ',
];

export const OPERATOR_PREFIX_WITH_DOT = ['2AK'];

export const AVAILABLE_FILE_EXTENSIONS = {
    agreement: ['pdf', 'png', 'jpg', 'jpeg', 'PDF', 'PNG', 'JPG', 'JPEG'],
    list: ['xlsx'],
};

export const KPP_REGEXP =
    /^([0-9]{1}[1-9]{1}|[1-9]{1}[0-9]{1})([0-9]{2})([0-9A-Z]{2})([0-9]{3})$/;
export const EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ID_REGEXP =
    /(?<PrefiX>2AE)(?<ID>([^\W_]{8})-([^\W_]{4})-([^\W_]{4})-([^\W_]{4})-([^\W_]{12}))/;

export const MAX_FILE_SIZE = 5242880;
