// Core
import React, { FC, memo } from 'react';

// Third party libreries
import { Tooltip } from '@material-ui/core';

// Utils
import { isEqual } from 'utils/isEqual';
import { fieldValidator } from 'utils/validator';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { SelectField } from 'components/SelectField/SelectField';

// Assets
import { InfoCircle } from 'assets/images/svgr/info-circle';

// Types
import { SupplierInfoType } from './supplierInfoTypes';

// Style
import './SupplierInfo.scss';

const SupplierInfoInner: FC<SupplierInfoType> = ({
    formName,
    formPartName,
    blockFormState,
}) => {
    const { suppliers, loadFromFile } = useSelector(
        (state: State) => state.form,
        isEqual,
    );
    const isFieldHaveError = () => {
        const answer = fieldValidator(blockFormState, 'supplier', false);

        if (typeof answer === 'string') return answer;

        return undefined;
    };

    return (
        <div className="supplier-info">
            <Tooltip title="Для ускорения настройки роуминга с контрагентами операторов ЭДО Контур и Корус необходимо указать, кем Вы являетесь: поставщиком товаров или услуг.">
                <div className="supplier-info__description">
                    <InfoCircle />
                </div>
            </Tooltip>

            <div className="supplier-info__select">
                <SelectField
                    name={`${formName}.${formPartName}`}
                    required
                    fieldType="supplier"
                    selectedArr={suppliers}
                    isLoadFromFile={loadFromFile}
                    wsError={isFieldHaveError()}
                />
            </div>
        </div>
    );
};

export const SupplierInfo = memo(SupplierInfoInner);
