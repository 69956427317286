// Core
import { put, apply } from 'redux-saga/effects';

// API
import { api } from 'api';

// Types
import { AutofilType } from 'redux/form/formTypes';
import { SagaIterator } from '@redux-saga/types';

// Actions
import { formCombineActions } from 'redux/form/formActions';
import { uiCombineActions } from 'redux/ui/uiActions';

export function* autoFillByInnOperatorWorker({
    payload: autoFillObj,
}: {
    type: string;
    payload: AutofilType;
}): SagaIterator {
    try {
        const response = yield apply(api, api.autoCompleteByInnOperator.fetch, [
            autoFillObj.value,
        ]);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { navigator_response, text } = yield apply(
            response,
            response.json,
            [],
        );

        if (response.status !== 200) {
            throw new Error(text);
        }

        yield put(formCombineActions.clearInitialFormStateAction());

        const optimizeData: () =>
            | undefined
            | { lastname: string; firstname: string; patronymic: string }
            | { kpp: string; name: string } = () => {
            if (navigator_response.inn.length === 10) {
                return {
                    kpp: navigator_response.kpp,
                    name: navigator_response.name.recommend,
                };
            }

            if (navigator_response.inn.length === 12) {
                return {
                    kpp: '',
                    lastname: navigator_response.chief[0].last,
                    firstname: navigator_response.chief[0].first,
                    patronymic: navigator_response.chief[0].middle,
                };
            }

            return undefined;
        };

        const newAgentData = optimizeData();

        if (newAgentData) {
            yield put(
                formCombineActions.updateAgentDataFromInnAction({
                    name: autoFillObj.name,
                    value: newAgentData,
                }),
            );
        }
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(
                error,
                'autoFillByInnOperator worker',
            ),
        );
    }
}
