import { MainObjectType } from 'redux/form/formTypes';

export const isAgentWithErrorRolluped = (
    agentsArr: MainObjectType[] | undefined,
    agentsIdWiError: number[],
) => {
    let answer = 0;

    if (agentsArr) {
        answer = agentsArr.reduce((acc: number, curr: MainObjectType, id) => {
            if (acc === 0 && id === agentsIdWiError[id]) {
                if (curr.isRolluped) {
                    return acc + 1;
                }

                return acc;
            }

            return acc;
        }, 0);
    }

    return answer;
};
