// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Store
import { State } from 'redux/store';
import { useSelector, useDispatch } from 'react-redux';

// Third party libreries
import { useForm, useFormState } from 'react-final-form';
import { merge } from 'lodash';
import { produce } from 'immer';

// Utils
import { isEqual } from 'utils/isEqual';
import { getBlockNameTitle } from 'utils/constants';
import { usePrevious } from 'hooks/usePrevious';

// Actions
import { formCombineActions } from 'redux/form/formActions';

// Components
import { Steper } from 'components/Steper/Steper';
import { COFormType } from 'redux/form/formTypes';
import { initialFormState as startState } from 'redux/form/formReducer';
import { BlockPartContent } from '../BlockPartContent/BlockPartContent';

// Types
import { OperatorFormTypes } from './operatorFormTypes';

// Style
import './OperatorForm.scss';

const OperatorFormInner: FC<OperatorFormTypes> = ({
    formName,
    isSendersValid,
    isReceiversValid,
    hasTheFormChange,
    setIsSendersValid,
    setIsReceiversValid,
    setHasTheFormChange,
    restart,
    push,
}) => {
    const dispatch = useDispatch();
    const formState = useFormState();
    const { mutators } = useForm();
    const {
        formErrors,
        loadFromFile,
        initialFormState,
        formCleaner,
        connectedId,
        notConnectedId,
    } = useSelector((state: State) => state.form, isEqual);
    const { isAuthenticate } = useSelector(
        (state: State) => state.auth,
        isEqual,
    );
    const { windowSize } = useSelector((state: State) => state.ui, isEqual);
    const [steperHeight, setSteperHeight] = useState(0);
    const prevLoadFromFile = usePrevious(loadFromFile);
    const prevFormCleaner = usePrevious(formCleaner);

    const restartForm = (state?: any) => {
        restart(state);
        dispatch(formCombineActions.loadFromFileOffAction());
    };

    useEffect(() => {
        setTimeout(() => {
            const agentsWithConnectedId =
                document.querySelectorAll('.agent--connected');

            if (agentsWithConnectedId.length > 0) {
                agentsWithConnectedId[0].scrollIntoView({ block: 'center' });
            }
        }, 0);
    }, [connectedId, notConnectedId]);

    useEffect(() => {
        dispatch(formCombineActions.clearInitialFormStateAction());
        dispatch(formCombineActions.setConnectedIdAction([]));
        dispatch(formCombineActions.setNotConnectedIdAction([]));
    }, []);

    useEffect(() => {
        if (
            (!prevLoadFromFile && loadFromFile) ||
            (!prevFormCleaner && formCleaner)
        ) {
            restart();
        }
    }, [loadFromFile, formCleaner]);

    useEffect(
        () => () => {
            if (isAuthenticate) {
                dispatch(formCombineActions.clearInitialFormStateAction());

                mutators.setValue(`${formName}`, startState.operatorForm);
            }
        },
        [isAuthenticate],
    );

    useEffect(() => {
        const newState = produce(
            formState.values[formName],
            (draftState: COFormType) =>
                merge(draftState, initialFormState[formName]),
        );

        mutators.setValue(`${formName}`, newState);
    }, [initialFormState]);

    useEffect(() => {
        if (setHasTheFormChange && formState.modified) {
            const doesThereSomeChange = Object.values(
                formState.modified,
            ).includes(true);

            if (loadFromFile || doesThereSomeChange) {
                setHasTheFormChange(true);
            }
        }
    }, [formState.modified]);

    useEffect(() => {
        dispatch(formCombineActions.setConnectedIdAction([]));
        dispatch(formCombineActions.setNotConnectedIdAction([]));
    }, [formState.values]);

    const definedSteperHeight = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                setSteperHeight(node.offsetHeight);
            }
        },
        [windowSize],
    );

    return (
        <section data-testid="operator-form" className="operator-form">
            <Steper
                steps={[
                    {
                        stepName: getBlockNameTitle(formName, 'sender'),
                        stepStatus: isSendersValid,
                    },
                    {
                        stepName: getBlockNameTitle(formName, 'receiver'),
                        stepStatus: isReceiversValid,
                    },
                ]}
                finishName="Готово к отправке"
                definedSteperHeight={definedSteperHeight}
            />

            <BlockPartContent
                formName={formName}
                steperHeight={steperHeight}
                isSendersValid={isSendersValid}
                formErrors={formErrors?.[formName]}
                isReceiversValid={isReceiversValid}
                hasTheFormChange={hasTheFormChange}
                formState={formState.values?.[formName]}
                setIsReceiversValid={setIsReceiversValid}
                setIsSendersValid={setIsSendersValid}
                restart={restartForm}
                push={push}
            />
        </section>
    );
};

export const OperatorForm = memo(OperatorFormInner);
