// Core
import React, { FC, memo } from 'react';

// Assets
import { List } from 'assets/images/svgr/list';
import { Sender } from 'assets/images/svgr/sender';
import { Receiver } from 'assets/images/svgr/receiver';
import { Warning } from 'assets/images/svgr/warning';

// Types
import { FormInfoType } from './formInfoTypes';

// Style
import './FormInfo.scss';

const FormInfoInner: FC<FormInfoType> = ({ agentsCounters }) => (
    <div className="form-info">
        <div className="form-info__header">
            <div className="form-info__header-icon">
                <List />
            </div>

            <div className="form-info__header-title">
                <span className="p--m">Основные данные</span>
            </div>
        </div>

        <div className="form-info__body">
            <div className="form-info__body-elem">
                <div className="form-info__body-icon">
                    <Sender />
                </div>

                <div className="form-info__body-title">
                    <span className="p--xs">Записей об отправителе:</span>
                </div>

                <div
                    className={`form-info__body-counter ${
                        agentsCounters.senders > 100 ||
                        (agentsCounters.senders > 1 &&
                            agentsCounters.receivers > 1)
                            ? 'form-info__body-counter--warning'
                            : ''
                    }`}>
                    <span className="p--m">{agentsCounters.senders}</span>
                </div>
            </div>

            <div className="form-info__body-elem">
                <div className="form-info__body-icon">
                    <Receiver />
                </div>

                <div className="form-info__body-title">
                    <span className="p--xs">Записей о получателе:</span>
                </div>

                <div
                    className={`form-info__body-counter ${
                        agentsCounters.receivers > 100 ||
                        (agentsCounters.receivers > 1 &&
                            agentsCounters.senders > 1)
                            ? 'form-info__body-counter--warning'
                            : ''
                    }`}>
                    <span className="p--m">{agentsCounters.receivers}</span>
                </div>
            </div>

            <div className="form-info__body-elem">
                <div className="form-info__body-icon">
                    <Warning />
                </div>

                <div className="form-info__body-title">
                    <span className="p--xs">
                        Записей с ошибками (дубликатами):
                    </span>
                </div>

                <div className="form-info__body-counter">
                    <span className="p--m">{agentsCounters.dublicates}</span>
                </div>
            </div>
        </div>
    </div>
);

export const FormInfo = memo(FormInfoInner);
