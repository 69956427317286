// Core
import React, { FC, useState, memo } from 'react';

// Third party libraries
import { Modal, Tooltip } from '@material-ui/core';

// Assets
import { Search } from 'assets/images/svgr/search';
import { Trash } from 'assets/images/svgr/trash';
import { X } from 'assets/images/svgr/x';

// Types
import { FileViewerTypes } from './fileViewerType';

// Styles
import './FileViewer.scss';

const FileViewerInner: FC<FileViewerTypes> = ({
    loadedFile,
    clearLoadedFile,
}) => {
    const [open, setOpen] = useState(false);

    const shownAgreement = (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    ) => {
        e.stopPropagation();

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <aside className="file-viewer">
            <div className="file-viewer__header">
                <span className="h--6">Файл:</span>
            </div>

            <div className="file-viewer__body">
                <ul className="files-list">
                    <li className="file-chield">
                        <Tooltip title={loadedFile.name} placement="bottom">
                            <span className="file-chield__name p--m">
                                {loadedFile.name}
                            </span>
                        </Tooltip>

                        <span className="file-chield__size p--m">
                            {' '}
                            - {(loadedFile.size / 1048576).toFixed(3)} Mb
                        </span>

                        <Tooltip title="Просмотреть файл" placement="bottom">
                            <button
                                type="button"
                                data-testid="show-img-btn"
                                className="file-chield__viewer"
                                onClick={(e) => shownAgreement(e)}>
                                <Search />
                            </button>
                        </Tooltip>

                        <Tooltip title="Удалить файл" placement="bottom">
                            <button
                                type="button"
                                data-testid="delete-img-btn"
                                className="file-chield__cleaner"
                                onClick={clearLoadedFile}>
                                <Trash />
                            </button>
                        </Tooltip>
                    </li>
                </ul>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                className="file-modal"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <section data-testid="file-wrapper" className="file-wrapper">
                    <button
                        type="button"
                        data-testid="close-file-wrapper"
                        className="close-icon"
                        onClick={handleClose}>
                        <X />
                    </button>

                    <embed
                        src={URL.createObjectURL(loadedFile)}
                        className="loaded-file"
                        type={`${loadedFile.type}`}
                    />
                </section>
            </Modal>
        </aside>
    );
};

export const FileViewer = memo(FileViewerInner);
