// Core
import React, { useEffect, memo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Utils
import { usePrevious } from 'hooks/usePrevious';

// Store
import { useDispatch } from 'react-redux';

// Actions
import { formCombineActions } from 'redux/form/formActions';

// Pages
import { Client, StatusInfo, AgentInfo, Operator, ErrorPage } from './pages';

// Address
import { addres } from './addres';

const RoutesInner = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const prevLocation = usePrevious(location.pathname);

    useEffect(() => {
        if (prevLocation === '/client' && location.pathname !== '/client') {
            dispatch(formCombineActions.setTicketNumberAction(null));
        }
    }, [prevLocation, location, dispatch]);

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={addres.client} />
            </Route>

            <Route exact path={addres.client} component={Client} />

            <Route exact path={addres.statusInfo} component={StatusInfo} />

            <Route exact path={addres.agentInfo} component={AgentInfo} />

            <Route exact path={addres.operator} component={Operator} />

            <Route exact path={addres.page404} component={ErrorPage} />

            <Redirect to={addres.page404} />
        </Switch>
    );
};

export const Routes = memo(RoutesInner);
