// Core
import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

// Asets
import { X } from 'assets/images/svgr/x';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';

// Types
import { MessageType } from './messageTypes';

// Style
import './Message.scss';

const MessageInner: FC<MessageType> = ({ data }) => {
    const dispatch = useDispatch();

    const removeMessage = () => {
        const uuid = data.id;

        if (uuid) {
            dispatch(uiCombineActions.removeToastMessageAction(uuid));
        }
    };

    const definedToastStyle = () => {
        let className = 'toast-messages__item';

        if (data.status === 0) {
            className += ' toast-messages__item--success';
        }

        if (data.status === 1) {
            className += ' toast-messages__item--error';
        }

        if (data.status === 2) {
            className += ' toast-messages__item--warning';
        }

        return className;
    };

    return (
        <div
            data-testid="toast-message"
            className={definedToastStyle()}
            onAnimationEnd={removeMessage}>
            <div className="toast-messages__left">
                <div className="toast-title">
                    <div className="h--6">
                        {data.status === 0 && 'Успешное действие'}
                        {data.status === 1 && 'Ошибка'}
                        {data.status === 2 && 'Предупреждение'}
                    </div>
                </div>

                <div className="toast-text">
                    <div className="p--xs">{data.text}</div>
                </div>
            </div>

            <div className="toast-messages__right">
                <div className="toast-close" onClick={removeMessage}>
                    <X />
                </div>
            </div>
        </div>
    );
};

export const Message = memo(MessageInner);
