// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libraries
import { Tooltip } from '@material-ui/core';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarExportProps,
} from '@material-ui/data-grid';
import { ThemeProvider } from '@material-ui/core/styles';

// Theme
import { uiTheme } from 'utils/theme/uiTheme';

// Types
import { StatusInfoTableType } from './statusInfoTableTypes';

// Style
import '../TablesStyles.scss';

const CustomToolbar = (
    props: GridToolbarExportProps,
    id: string,
    ticket: number,
) => (
    <GridToolbarContainer>
        {/* @ts-ignore */}
        <GridToolbarExport
            csvOptions={{
                fileName: `EDO_Roaming_statement_info_IdEDO-${id}_Ticket-${ticket}`,
                utf8WithBom: true,
            }}
            {...props}
        />
    </GridToolbarContainer>
);

const StatusInfoTableInner: FC<StatusInfoTableType> = ({
    agentData,
    contacts,
}) => {
    const initialState = {
        columns: [],
        rows: [],
        dataSet: 'Commodity',
        rowLength: 100,
        maxColumns: 7,
    };

    const [data, setData] = useState(initialState);
    const [pageSize, setPageSize] = useState<number>(25);

    useEffect(() => {
        if (contacts && contacts?.receiver?.length > 0) {
            const columns = [
                {
                    field: 'id',
                    headerName: '№',
                    width: 65,
                    headerAlign: 'center',
                    align: 'center',
                },
                {
                    field: 'inn_kpp',
                    headerName: 'ИНН / КПП',
                    width: 120,
                    renderCell: (params: any) => (
                        <div className="status-info-table__cell">
                            <span>{params.value.split('/')[0] || ''}</span>
                            <span>
                                {params.value.split('/')[1]
                                    ? `${params.value.split('/')[1]}`
                                    : ''}
                            </span>
                        </div>
                    ),
                },
                {
                    field: 'receiver_name',
                    headerName: 'Контрагент',
                    flex: 1,
                    renderCell: (params: any) => (
                        <Tooltip title={params.value || ''}>
                            <div className="status-info-table__cell">
                                <span>{params.value}</span>
                            </div>
                        </Tooltip>
                    ),
                },
            ];

            const rows = contacts.receiver.map((receiver: any, id: any) => ({
                id: id + 1,
                inn_kpp: receiver.kpp
                    ? `${receiver.inn}/${receiver.kpp}`
                    : `${receiver.inn}`,
                receiver_name: receiver.kpp
                    ? receiver?.name
                    : `${receiver?.lastname} ${receiver?.firstname} ${receiver?.patronymic}`,
            }));

            if (data && columns && rows) {
                // @ts-ignore
                setData({ ...data, columns, rows });
            }
        }
    }, [contacts]);

    return (
        <div data-testid="status-info-table" className="status-info-table">
            <ThemeProvider theme={uiTheme}>
                <DataGrid
                    {...data}
                    autoHeight
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    localeText={{
                        columnHeaderSortIconLabel: 'Сортировка',
                        toolbarExport: 'Скачать',
                        toolbarExportCSV: 'Скачать CSV таблицу',
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 55, 100]}
                    pagination
                    components={{
                        Toolbar: (dataInfo) =>
                            CustomToolbar(
                                dataInfo,
                                agentData.id,
                                agentData.ticket,
                            ),
                    }}
                />
            </ThemeProvider>
        </div>
    );
};

export const StatusInfoTable = memo(StatusInfoTableInner);
