// Types
import { MainObjectsArrType } from 'redux/form/formTypes';

export const simplifyArr = (
    arr: MainObjectsArrType,
    operatorPrefixes: string[],
) => {
    const operatorsPrefixesExpend = [...operatorPrefixes, '2AE'];

    return arr.map((elem: any) => {
        if (elem?.id) {
            const prefix = elem.id.slice(0, 3);
            const doesPrefixesIncludePrefix =
                operatorsPrefixesExpend.reduce(
                    (sum, curr) => (`${curr}` === prefix ? sum + 1 : sum),
                    0,
                ) > 0;

            if (doesPrefixesIncludePrefix) {
                return `${elem?.inn ? elem.inn : ''}${
                    elem?.kpp ? elem.kpp : ''
                }${elem?.id ? elem.id.slice(3, elem.id.length) : ''}`;
            }

            return `${elem?.inn ? elem.inn : ''}${elem?.kpp ? elem.kpp : ''}${
                elem?.id ? elem.id : ''
            }`;
        }

        return `${elem?.inn ? elem.inn : ''}${elem?.kpp ? elem.kpp : ''}`;
    });
};
