// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libreries
import { useForm } from 'react-final-form';

// Components
import { InputField } from 'components/InputField/InputField';

// Types
import { FindAgentInfoTypes } from './findAgentInfoTypes';

// Style
import './FindAgentInfo.scss';

const FindAgentInfoInner: FC<FindAgentInfoTypes> = ({
    formName,
    formPartName,
    formPartState,
}) => {
    const { mutators } = useForm();
    const [isEqual12, setIsEqual12] = useState(false);

    useEffect(() => {
        const inn = formPartState?.inn;

        if (inn && inn.length === 12) {
            setIsEqual12(true);

            if (formPartState?.kpp) {
                mutators.setValue(`${formName}.${formPartName}.kpp`, undefined);
            }
        } else {
            setIsEqual12(false);
        }
    }, [formPartState?.inn]);

    return (
        <div className="find-agent-info">
            <div className="form-field-row">
                <InputField
                    name={`${formName}.${formPartName}`}
                    value={formPartState?.inn}
                    fieldType="inn"
                    required
                />

                <InputField
                    name={`${formName}.${formPartName}`}
                    value={formPartState?.kpp}
                    fieldType="kpp"
                    disabled={isEqual12}
                />
            </div>
        </div>
    );
};

export const FindAgentInfo = memo(FindAgentInfoInner);
