// Core
import React, { FC, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { Message } from './Message/Message';

// Types
import { ToastType } from './toastTypes';

// Style
import './Toast.scss';

const ToastInner: FC<ToastType> = () => {
    const { messages } = useSelector((state: State) => state.ui.toast, isEqual);

    return (
        <div className="toast" data-testid="toast">
            <div className="toast-messages">
                {messages.map((item) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Message key={`${item.id}.${item.text}`} data={item} />
                ))}
            </div>
        </div>
    );
};

export const Toast = memo(ToastInner);
