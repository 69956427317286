// Types
import {
    MainObjectsArrType,
    SenderMainObjectTypeForRequest,
} from 'redux/form/formTypes';

import { ROOT_URL } from './config';

export const api = {
    auth: {
        fetch(login: string, password: string) {
            const formData = new FormData();

            formData.append('login', login);
            formData.append('password', password);

            return fetch(`${ROOT_URL}/v2/auth`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
        },
    },

    abonent: {
        fetch(
            operator: string,
            senders: SenderMainObjectTypeForRequest[],
            receivers: MainObjectsArrType,
            agreement?: Blob,
            ticket?: string,
        ) {
            const formData = new FormData();

            const data = {
                operator,
                sender: senders,
                receiver: receivers,
                ticket_number: ticket,
            };

            formData.append('data', JSON.stringify(data));

            if (agreement) {
                formData.append('agreement', agreement);
            }

            return fetch(`${ROOT_URL}/v2/abonent`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    parseAbonent: {
        fetch(file: Blob) {
            const formData = new FormData();

            formData.append('sender_list', file);

            return fetch(`${ROOT_URL}/v2/abonent/parse_list`, {
                method: 'POST',
                body: formData,
            });
        },
    },

    operators: {
        fetch() {
            return fetch(`${ROOT_URL}/v2/operator_list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    },

    suppliers: {
        fetch() {
            return fetch(`${ROOT_URL}/v2/suppliers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    },

    operator: {
        fetch(
            senders: MainObjectsArrType,
            receivers: MainObjectsArrType,
            request_id?: string,
        ) {
            const formData = new FormData();

            const data: {
                sender: MainObjectsArrType;
                receiver: MainObjectsArrType;
                request_id?: string;
            } = {
                sender: senders,
                receiver: receivers,
                request_id,
            };

            formData.append('data', JSON.stringify(data));

            return fetch(`${ROOT_URL}/v2/operator`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
        },
    },

    parseOperator: {
        fetch(file: Blob) {
            const formData = new FormData();

            formData.append('sender_list', file);

            return fetch(`${ROOT_URL}/v2/operator/parse_list`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
        },
    },

    autoCompleteByInnNavigator: {
        fetch(inn: string) {
            return fetch(`${ROOT_URL}/v2/navigator/${inn}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    },

    autoCompleteByInnOperator: {
        fetch(inn: string) {
            return fetch(`${ROOT_URL}/v2/pseudo_navigator/${inn}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    },

    autoCompleteById: {
        fetch(id: string) {
            return fetch(`${ROOT_URL}/v2/abonent/info/id/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    },

    status: {
        fetch(sender_id: string, request_id: number) {
            return fetch(
                `${ROOT_URL}/v2/request/status/${sender_id}/${request_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        },
    },

    contragents: {
        fetch(inn: string, kpp?: string) {
            if (kpp)
                return fetch(`${ROOT_URL}/v2/abonent/info/${inn}/${kpp}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

            return fetch(`${ROOT_URL}/v2/abonent/info/${inn}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        },
    },
};
