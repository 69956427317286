// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';
import { usePrevious } from 'hooks/usePrevious';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Third party libreries
import { useForm } from 'react-final-form';
import { differenceWith } from 'lodash';

// Types
import {
    SenderType,
    ReceiverType,
    MainObjectsArrType,
} from 'redux/form/formTypes';
import { FormPartTypes } from './formPartTypes';

// Components
import { FormBlockBody } from './FormBlockBody/FormBlockBody';
import { FormBlockHeader } from './FormBlockHeader/FormBlockHeader';
import { FormBlockFooter } from './FormBlockFooter/FormBlockFooter';

// Style
import './FormPart.scss';

const FormPartInner: FC<FormPartTypes> = ({
    formName,
    formPartName,
    ticketNumber,
    isBlockValid,
    applicationId,
    formPartState,
    formPartErrors,
    isFooterRendered,
    setBlockValidation,
    push,
}) => {
    const { mutators } = useForm();
    const { operators, wsErrors, agentsCounters, isFormInSubmiting } =
        useSelector((state: State) => state.form, isEqual);
    const [formPartError, setFormPartError] = useState(false);
    const [operatorPrefixIds, setOperatorPrefixIds] = useState<string[]>([]);
    const [formBlockState, setFormBlockState] = useState<MainObjectsArrType>(
        [],
    );
    const prevFormBlockState = usePrevious(formBlockState);
    const [contractorType, setContractorType] = useState<1 | 2 | 3 | 4>();
    const [formPartWsErrors, setFormPartWsErrors] =
        useState<MainObjectsArrType>([]);
    const prevFormPartWsErrors = usePrevious(formPartWsErrors);
    const [diferenceElem, setDiferenceElem] = useState<any[]>([]);

    const rollupToggler = useCallback(
        (rollupState: boolean) => {
            mutators.setValue(
                `${formName}.${formPartName}.isRolluped`,
                rollupState,
            );
        },
        [formName, formPartName, mutators],
    );

    useEffect(() => {
        if (isFormInSubmiting && formPartError) {
            rollupToggler(false);
        }
    }, [formPartError, isFormInSubmiting, formPartName, rollupToggler]);

    useEffect(() => {
        const prevState = prevFormPartWsErrors;

        if (prevState) {
            const difference = differenceWith(
                formPartWsErrors,
                prevState,
                isEqual,
            );

            setDiferenceElem(difference);
        }
    }, [formPartWsErrors]);

    useEffect(() => {
        if (diferenceElem && diferenceElem.length > 0) {
            const agentId = diferenceElem[0]?.fid;
            const agentName = `${formName}.${formPartName}.${
                formPartName === 'sender' ? 'senders' : 'receivers'
            }.[${agentId}]`;

            setTimeout(() => {
                mutators.setValue(agentName, {
                    ...formBlockState[agentId],
                    wsChecked: true,
                });
            }, 0);
        }
    }, [diferenceElem]);

    useEffect(() => {
        if (contractorType) {
            if (contractorType === 1)
                setFormPartWsErrors(wsErrors.contractor_type_1);
            if (contractorType === 2)
                setFormPartWsErrors(wsErrors.contractor_type_2);
            if (contractorType === 3)
                setFormPartWsErrors(wsErrors.contractor_type_3);
            if (contractorType === 4)
                setFormPartWsErrors(wsErrors.contractor_type_4);
        }
    }, [contractorType, wsErrors]);

    useEffect(() => {
        if (formName === 'operatorForm' && formPartName === 'sender')
            setContractorType(1);
        if (formName === 'operatorForm' && formPartName === 'receiver')
            setContractorType(2);
        if (formName === 'clientForm' && formPartName === 'sender')
            setContractorType(3);
        if (formName === 'clientForm' && formPartName === 'receiver')
            setContractorType(4);
    }, [formName, formPartName]);

    useEffect(() => {
        if (
            formName === 'clientForm' &&
            formPartName === 'sender' &&
            ticketNumber
        ) {
            setTimeout(() => {
                mutators.setValue(
                    `${formName}.${formPartName}.ticket`,
                    ticketNumber,
                );
            }, 0);
        }
    }, [formName, formPartName, mutators, ticketNumber]);

    useEffect(() => {
        if (operators.length > 0) {
            setOperatorPrefixIds([
                ...operators.map((item) => item.prefix),
                '2AE',
            ]);
        } else {
            setOperatorPrefixIds([]);
        }
    }, [operators]);

    const getAgenstStates = useCallback(
        (agentsState: SenderType | ReceiverType | undefined) => {
            if ((agentsState as SenderType)?.senders) {
                return (agentsState as SenderType).senders;
            }

            if ((agentsState as ReceiverType)?.receivers) {
                return (agentsState as ReceiverType).receivers;
            }

            return undefined;
        },
        [],
    );

    useEffect(() => {
        const answer = getAgenstStates(formPartState);

        if (!isEqual(prevFormBlockState, answer)) {
            if (answer) {
                setFormBlockState(answer);
            } else {
                setFormBlockState([]);
            }
        }
    }, [formPartState, getAgenstStates, prevFormBlockState]);

    const getCurrValue = (
        agentsState: SenderType | ReceiverType | undefined,
        key: keyof SenderType | keyof ReceiverType,
    ) => {
        if (
            (agentsState as SenderType)[key as keyof SenderType] !== undefined
        ) {
            return (agentsState as SenderType)[key as keyof SenderType];
        }

        if (
            (agentsState as ReceiverType)[key as keyof ReceiverType] !==
            undefined
        ) {
            return (agentsState as ReceiverType)[key as keyof ReceiverType];
        }

        return undefined;
    };

    const definedFormPartError = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                setFormPartError(
                    (node.querySelector('.block--with-error') ||
                        node.querySelector('.agent--connected')) !== null,
                );
            }
        },
        [formPartState?.isRolluped],
    );

    // Define elements styles 👇🏼

    const determFormPartClass = () => {
        let className = 'form-part';

        if (!formPartState?.isRolluped || false) {
            className += ' form-part--shown';
        } else {
            className += ' form-part--hidden';
        }

        if (formPartError) {
            className += ' form-part--with-error';
        }

        return className;
    };

    return (
        <section
            data-testid={`form-part-${
                formPartName === 'sender' ? 'sender' : 'receiver'
            }`}
            ref={definedFormPartError}
            className={determFormPartClass()}>
            {(formName === 'clientForm' || formName === 'operatorForm') && (
                <>
                    <FormBlockHeader
                        formName={formName}
                        formPartName={formPartName}
                        agentsCounter={
                            formPartName === 'sender'
                                ? agentsCounters.senders
                                : agentsCounters.receivers
                        }
                        isRollup={formPartState?.isRolluped || false}
                        rollupToggler={rollupToggler}
                    />

                    <FormBlockBody
                        formName={formName}
                        formPartName={formPartName}
                        contractorType={contractorType}
                        formBlockState={formBlockState}
                        formBlockErrors={getAgenstStates(formPartErrors) || []}
                        operatorPrefixState={
                            formPartState && 'operator' in formPartState
                                ? formPartState.operator
                                : undefined
                        }
                        // @ts-ignore
                        operator={getCurrValue(formPartState, 'operator')}
                        // @ts-ignore
                        agreement={getCurrValue(formPartState, 'agreement')}
                        operators={operators}
                        // @ts-ignore
                        ticketNumber={getCurrValue(formPartState, 'ticket')}
                        applicationId={applicationId}
                        operatorPrefixIds={operatorPrefixIds}
                        setBlockValidation={setBlockValidation}
                    />

                    {!(
                        formName === 'clientForm' && formPartName === 'sender'
                    ) &&
                        isFooterRendered && (
                            <FormBlockFooter
                                formName={formName}
                                formPartName={formPartName}
                                isBlockValid={isBlockValid}
                                agentsCounter={
                                    formPartName === 'sender'
                                        ? agentsCounters.senders
                                        : agentsCounters.receivers
                                }
                                push={push}
                            />
                        )}
                </>
            )}
        </section>
    );
};

export const FormPart = memo(FormPartInner);
