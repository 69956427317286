// Core
import React, { FC, useEffect, memo } from 'react';

// Store
import { State } from 'redux/store';
import { useSelector, useDispatch } from 'react-redux';

// Utils
import { isEqual } from 'utils/isEqual';

// Action
import { uiCombineActions } from 'redux/ui/uiActions';

// Conponents
import { Logo } from './Logo/Logo';
import { Menu } from './Menu/Menu';
import { HideThumb } from './HideThumb/HideThumb';

// Types
import { AsideNavTypes } from './asideNavTypes';

// Style
import './AsideNav.scss';

const AsideNavInner: FC<AsideNavTypes> = () => {
    const dispatch = useDispatch();
    const { isMenuShow, windowSize } = useSelector(
        (state: State) => state.ui,
        isEqual,
    );

    useEffect(() => {
        if (windowSize.width && windowSize.width <= 1210 && isMenuShow) {
            dispatch(uiCombineActions.menuThumbAction());
        }
    }, [dispatch, isMenuShow, windowSize]);

    return (
        <aside
            data-testid="aside-nav"
            className={`aside-nav ${
                isMenuShow ? 'aside-nav--shown' : 'aside-nav--hidden'
            }`}>
            <Logo />

            <Menu isMenuShow={isMenuShow} />

            {windowSize.width && windowSize.width > 1210 && (
                <HideThumb isMenuShow={isMenuShow} />
            )}
        </aside>
    );
};

export const AsideNav = memo(AsideNavInner);
