// Core
import { put } from 'redux-saga/effects';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';

// Types
import { SocketSendObj } from 'redux/ui/uiTypes';

export function* socketSend({
    payload: socketSendObj,
}: {
    type: string;
    payload: SocketSendObj;
}) {
    try {
        socketSendObj.socket.send(socketSendObj.message);
    } catch (error: any) {
        yield put(uiCombineActions.emitErrorAction(error, 'workerName worker'));
    }
}
