// Core
import { put, apply } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

// API
import { api } from 'api';

// Actions
import { formCombineActions } from 'redux/form/formActions';
import { uiCombineActions } from 'redux/ui/uiActions';

export function* fetchOperatorWorker(): SagaIterator {
    try {
        const response = yield apply(api, api.operators.fetch, []);
        const { operators, text } = yield apply(response, response.json, []);

        if (response.status !== 200) {
            throw new Error(text);
        }

        yield put(formCombineActions.fillOperatorsAction(operators));
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(error, 'fetchOperator worker'),
        );
    }
}
