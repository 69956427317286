// Core
import React, { FC, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { MainInfoFields } from 'components/MainInfoFields/MainInfoFields';

// Types
import { ClientReceiverTypes } from './clientReceiverTypes';

// Style
import './ClientReceiver.scss';

const ClientReceiverInner: FC<ClientReceiverTypes> = ({
    blockFormState,
    name,
    formBlockWsError,
    autoLoadInn,
    autoFillByInnNavigator,
    autoFillByInnOperator,
}) => {
    const { loadFromFile } = useSelector((state: State) => state.form, isEqual);

    return (
        <article className="client-receiver">
            <MainInfoFields
                mainInfoState={blockFormState}
                name={name}
                formBlockWsError={formBlockWsError}
                isLoadFromFile={loadFromFile}
                autoFillByInnNavigator={autoFillByInnNavigator}
                autoFillByInnOperator={autoFillByInnOperator}
                autoLoadInn={autoLoadInn}
            />
        </article>
    );
};

export const ClientReceiver = memo(ClientReceiverInner);
