// Types
import { MainObjectNameType, LoginType } from 'redux/form/formTypes';

export const fieldParser: (
    value: any,
    fieldType: MainObjectNameType | keyof LoginType,
) => any = (value, fieldType) => {
    if (fieldType === 'inn') return value.replace(/[^0-9]/g, '').slice(0, 12);

    if (fieldType === 'kpp')
        return value.replace(/[^0-9A-Z]/g, '').substr(0, 9);

    if (fieldType === 'name')
        return value.replace(/[a-zA-Z]/g, '').slice(0, 255);

    if (
        fieldType === 'lastname' ||
        fieldType === 'firstname' ||
        fieldType === 'patronymic'
    )
        return value.replace(/[^а-яёА-ЯЁ-\s]/g, '').slice(0, 64);

    if (fieldType === 'id') {
        const prefix = value.slice(0, 3);

        switch (prefix) {
            case '2AE':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 39);
            case '2BE':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
            case '2BM':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
            case '2BK':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 38);
            case '2AL':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
            case '2AK':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
            case '2LJ':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 36);
            case '2VO':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
            case '2LT':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
            case '2GS':
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 40);
            default:
                return value
                    .toUpperCase()
                    .replace(/[^0-9a-zA-Z@.,-]/g, '')
                    .slice(0, 46);
        }
    }

    if (fieldType === 'email') return value.replace(/ +?/g, '').slice(0, 255);

    if (fieldType === 'ticket' || fieldType === 'status_ticket')
        return value.replace(/[^0-9]/g, '').slice(0, 16);

    if (fieldType === 'application_identifier')
        return value.replace(/[^0-9a-zA-Z@.,-]/g, '').slice(0, 32);

    if (fieldType === 'operator') return value;

    if (fieldType === 'supplier') return value;

    if (fieldType === 'loginName')
        return value.replace(/[^0-9a-zA-Z!@#$&()-`.+,/]/g, '');

    if (fieldType === 'password')
        return value.replace(/[^0-9a-zA-Z!@#$&()\\-`.+,/]/g, '');

    return value;
};
