// Core
import React, { FC, memo } from 'react';

// Assets
import { Chekmark } from 'assets/images/svgr/chekmark';
import { FinishMark } from 'assets/images/svgr/finish-mark';

// Types
import { SteperTypes } from './steperTypes';

// Style
import './Steper.scss';

const SteperInner: FC<SteperTypes> = ({
    steps,
    finishName,
    definedSteperHeight,
}) => (
    <div ref={definedSteperHeight} className="steper">
        <div className="steper__wrapper">
            {steps.map((step, id) => (
                <React.Fragment key={`${step.stepName}`}>
                    <div
                        data-testid="step"
                        className={`step ${
                            step.stepStatus ? 'step--unready' : 'step--ready'
                        }`}>
                        <div className="step__icon">
                            {step.stepStatus ? id + 1 : <Chekmark />}
                        </div>

                        <div className="step__title">
                            <span className="h--6">{step.stepName}</span>
                        </div>
                    </div>

                    <div
                        className={`step-divider ${
                            steps.reduce(
                                (counter, item) => counter + item.stepStatus,
                                0,
                            )
                                ? 'step-divider--unready'
                                : 'step-divider--ready'
                        }`}
                    />
                </React.Fragment>
            ))}

            <div
                data-testid="step"
                className={`step ${
                    steps.reduce(
                        (counter, step) => counter + step.stepStatus,
                        0,
                    )
                        ? 'step--unready'
                        : 'step--finish'
                }`}>
                <div className="step__icon">
                    {steps.reduce(
                        (counter, step) => counter + step.stepStatus,
                        0,
                    ) ? (
                        steps.length + 1
                    ) : (
                        <FinishMark />
                    )}
                </div>

                <div className="step__title">
                    <span className="h--6">{finishName}</span>
                </div>
            </div>
        </div>
    </div>
);

export const Steper = memo(SteperInner);
