// Utils
import { doesOperatorNeedAgreement } from '../operatorsCalc';

// Types
import {
    LoginType,
    StatusInfoType,
    AgentInfoType,
    FormNameType,
    FormPartNameType,
    MainObjectsArrType,
    OperatorType,
} from '../../redux/form/formTypes';

const checkOnErrors: (
    formPartName: FormPartNameType,
    blockErrors: MainObjectsArrType,
) => 0 | 1 = (formPartName, blockErrors) => {
    if (
        (formPartName === 'sender' || formPartName === 'receiver') &&
        blockErrors &&
        blockErrors.length > 0
    ) {
        const isError = blockErrors.reduce((summ: number, curr: {}) => {
            if (summ > 0) return 1;

            if (!curr) return summ;

            const isCurrValuesHaveErrors = Object.values(curr).reduce(
                (counter: number, value: any) =>
                    typeof value === 'number' ? counter + value : counter + 1,
                0,
            );

            return summ + isCurrValuesHaveErrors;
        }, 0);

        return isError === 0 ? 0 : 1;
    }

    return 0;
};

export const formValidator: (
    formName: FormNameType,
    formPartName: FormPartNameType,
    blockErrors:
        | MainObjectsArrType
        | StatusInfoType
        | AgentInfoType
        | LoginType,
    blockState: MainObjectsArrType,
    ticketNumber?: null | string,
    operator?: string,
    agreement?: Blob,
    operators?: OperatorType[],
    localPrefix?: string | null,
    applicationId?: string,
) => 0 | 1 = (
    formName,
    formPartName,
    blockErrors,
    blockState,
    ticketNumber,
    operator,
    agreement,
    operators,
    localPrefix,
    applicationId,
) => {
    // OPERATOR FORM
    /* contractor_type_1 */

    if (
        formName === 'operatorForm' &&
        formPartName === 'sender' &&
        blockErrors &&
        Array.isArray(blockErrors)
    ) {
        if (localPrefix && localPrefix === '2BM' && !applicationId) {
            return 1;
        }

        return checkOnErrors(formPartName, blockErrors);
    }

    /* contractor_type_2 */
    if (
        formName === 'operatorForm' &&
        formPartName === 'receiver' &&
        blockErrors &&
        Array.isArray(blockErrors)
    ) {
        return checkOnErrors(formPartName, blockErrors);
    }

    // CLIENT FORM
    /* contractor_type_3 */
    if (
        formName === 'clientForm' &&
        formPartName === 'sender' &&
        blockErrors &&
        Array.isArray(blockErrors)
    ) {
        if (ticketNumber && ticketNumber.length > 0 && ticketNumber.length < 16)
            return 1;

        return checkOnErrors(formPartName, blockErrors);
    }

    /* contractor_type_4 */
    if (
        formName === 'clientForm' &&
        formPartName === 'receiver' &&
        blockErrors &&
        Array.isArray(blockErrors)
    ) {
        if (!operator) {
            return 1;
        }

        if (operators) {
            if (doesOperatorNeedAgreement(operators, operator) && !agreement) {
                return 1;
            }
        }

        return checkOnErrors(formPartName, blockErrors);
    }

    // STATUS FORM / AGENT INFO FORM / LOGIN FORM
    if (
        (formPartName === 'statusInfo' ||
            formPartName === 'agentInfo' ||
            formPartName === 'login') &&
        blockErrors
    ) {
        const isError = Object.values(blockErrors).reduce(
            (counter: number, value: any) =>
                typeof value === 'number' ? counter + value : counter + 1,
            0,
        );

        return isError === 0 ? 0 : 1;
    }

    return 1;
};
