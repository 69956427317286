// Core
import React, { FC, useState, useRef, useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { WarningDialog } from 'components/WarningDialog/WarningDialog';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

// Types
import { Send } from 'assets/images/svgr/send';
import { Loader } from 'assets/images/svgr/loader';
import { NewDoc } from 'assets/images/svgr/new-doc';
import { ApplyBtnTypes } from './applyBtnTypes';

// Style
import './ApplyBtn.scss';

const ApplyBtnInner: FC<ApplyBtnTypes> = ({
    isFetching,
    secondBtn,
    secondBtnFunc,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { acceptAutoCleringForm } = useSelector(
        (state: State) => state.ui,
        isEqual,
    );
    const [btnList, setBtnList] = useState<Element[]>([]);
    const [showWarning, setShowWarning] = useState(false);
    const btns = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (btns.current) {
            const btnsNodesArr: Element[] = [];
            const btnsNodes = btns.current.querySelectorAll('.btn');

            if (btnsNodes.length > 0) {
                btnsNodes.forEach((elem) => btnsNodesArr.push(elem));
            }

            setBtnList(btnsNodesArr);
        }
    }, [secondBtn]);

    const checkOnFocusedBtn = (nodeList: Element[]) =>
        nodeList.reduce(
            (acc, curr) =>
                curr.classList.contains('btn--focus') ? acc + 1 : acc,
            0,
        );

    useEffect(() => {
        if (btnList.length > 0) {
            btnList[0].classList.add('btn--focus');
        }
    }, [btnList]);

    const onBtnFocusEnter = (elem: Element) => {
        if (btnList.length > 0) {
            btnList.forEach((item) => item.classList.remove('btn--focus'));
        }

        elem.classList.add('btn--focus');
    };

    const onBtnFocusLeave = (elem: Element) => {
        elem.classList.remove('btn--focus');

        if (btnList.length > 0) {
            if (checkOnFocusedBtn(btnList) === 0) {
                btnList[0].classList.add('btn--focus');
            }
        }
    };

    const triggerApplyBtn = () => {
        dispatch(formCombineActions.setFormSubmitingAction(true));

        document.getElementById('formRoaming')?.dispatchEvent(
            new Event('submit', {
                cancelable: true,
                bubbles: true,
            }),
        );
    };

    const triggerClearBtn = () => {
        if (acceptAutoCleringForm) {
            if (secondBtnFunc) {
                secondBtnFunc();
            }
        } else {
            setShowWarning(!showWarning);
        }
    };

    const confirmFunc = () => {
        if (secondBtnFunc) {
            secondBtnFunc();
        }
    };

    const dontShowAcceptAutoClearingForm = () => {
        dispatch(uiCombineActions.acceptAutoClearingFormAction());
    };

    const getApplyBtnTitle = () => {
        if (
            location.pathname === '/client' ||
            location.pathname === '/operator'
        )
            return 'Послать заявление';

        return 'Проверить';
    };

    return (
        <div ref={btns} className="form-btns">
            <button
                type="button"
                data-testid="applyBtn"
                className="btn form-btns__chield btn--send"
                onClick={triggerApplyBtn}
                onPointerOver={(e) => onBtnFocusEnter(e.currentTarget)}
                onFocus={(e) => onBtnFocusEnter(e.currentTarget)}>
                <div className="btn-name">
                    <span className="p--m">{getApplyBtnTitle()}</span>
                </div>

                <div className="btn-icon">
                    {isFetching ? <Loader /> : <Send />}
                </div>
            </button>

            {secondBtn && (
                <>
                    <WarningDialog
                        title="Данные не сохранены!"
                        subtitle="Вы действительно хотите создать новое заявление? Все введенные данные будут удалены."
                        visible={showWarning}
                        cancelButtonText="Отмена"
                        confirmButtonText="Подтвердить"
                        acceptingTrigger={acceptAutoCleringForm}
                        acceptingCheckboxFunc={dontShowAcceptAutoClearingForm}
                        onConfirm={() => {
                            confirmFunc();
                            setShowWarning(false);
                        }}
                        onCancel={() => setShowWarning(false)}
                    />

                    <button
                        type="button"
                        data-testid="newDocBtn"
                        className="btn form-btns__chield btn--new"
                        onClick={triggerClearBtn}
                        onPointerOver={(e) => onBtnFocusEnter(e.currentTarget)}
                        onPointerLeave={(e) => onBtnFocusLeave(e.currentTarget)}
                        onFocus={(e) => onBtnFocusEnter(e.currentTarget)}
                        onBlur={(e) => onBtnFocusLeave(e.currentTarget)}>
                        <div className="btn-name">
                            <span className="p--m">Новое заявление</span>
                        </div>

                        <div className="btn-icon">
                            <NewDoc />
                        </div>
                    </button>
                </>
            )}
        </div>
    );
};

export const ApplyBtn = memo(ApplyBtnInner);
