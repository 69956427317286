// Types
import { MainObjectNameType, LoginType } from 'redux/form/formTypes';

// Constants
import { KPP_REGEXP, EMAIL_REGEXP } from 'utils/constants';

const validatorCore: (
    value: any,
    required: boolean,
    wsError?: string,
) => 0 | string | undefined = (value, required, wsError) => {
    if (!required) return 0;

    if (!value) return 'Обязательное поле!';

    if (wsError) return wsError;

    return undefined;
};

export const fieldValidator: (
    value: any,
    fieldType: MainObjectNameType | keyof LoginType,
    required: boolean,
    wsError?: string,
) => undefined | 0 | string = (value, fieldType, required, wsError) => {
    const basicCheck = validatorCore(value, required, wsError);

    if (fieldType === 'inn') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        if (value.length === 10) {
            const checkSum = (
                ((2 * value[0] +
                    4 * value[1] +
                    10 * value[2] +
                    3 * value[3] +
                    5 * value[4] +
                    9 * value[5] +
                    4 * value[6] +
                    6 * value[7] +
                    8 * value[8]) %
                    11) %
                10
            ).toString();

            if (value[9] === checkSum) {
                return 0;
            }

            return 'ИНН не корректен';
        }

        if (value.length === 12) {
            const checkSum1 = (
                ((7 * value[0] +
                    2 * value[1] +
                    4 * value[2] +
                    10 * value[3] +
                    3 * value[4] +
                    5 * value[5] +
                    9 * value[6] +
                    4 * value[7] +
                    6 * value[8] +
                    8 * value[9]) %
                    11) %
                10
            ).toString();

            const checkSum2 = (
                ((3 * value[0] +
                    7 * value[1] +
                    2 * value[2] +
                    4 * value[3] +
                    10 * value[4] +
                    3 * value[5] +
                    5 * value[6] +
                    9 * value[7] +
                    4 * value[8] +
                    6 * value[9] +
                    8 * value[10]) %
                    11) %
                10
            ).toString();

            if (value[10] === checkSum1 && value[11] === checkSum2) {
                return 0;
            }
        }

        return 'ИНН не корректен';
    }

    if (fieldType === 'kpp') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        if (KPP_REGEXP.test(value)) return 0;

        return 'КПП некорректен';
    }

    if (fieldType === 'name') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'lastname') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'firstname') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'patronymic') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'id') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'email') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        if (value === undefined || EMAIL_REGEXP.test(value)) return 0;

        return 'E-mail некорректен';
    }

    if (fieldType === 'ticket') {
        if (!value) return 0;

        if (value.length < 16) {
            return 'Номер приглашения должен состоять из 16-ти цифр';
        }
    }

    if (fieldType === 'application_identifier') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'operator') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'supplier') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'status_ticket') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'loginName') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    if (fieldType === 'password') {
        if (basicCheck || basicCheck === 0) return basicCheck;

        return 0;
    }

    return undefined;
};
