// Core
import React, { FC, memo } from 'react';

// Assets
import { WarningRound } from 'assets/images/svgr/warning-round';

// Third party libraries
import { Modal } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

// Types
import { WarningDialogType } from './warningDialogTypes';

// Style
import './WarningDialog.scss';

const WarningDialogInner: FC<WarningDialogType> = ({
    title,
    subtitle,
    confirmButtonText,
    cancelButtonText,
    visible,
    onCancel,
    onConfirm,
    acceptingTrigger,
    acceptingCheckboxFunc,
}) => {
    const confirm = () => {
        onCancel();
        onConfirm();
    };
    return (
        <Modal
            open={visible}
            onClose={onCancel}
            className="leaving-route-modal"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <section
                className="leaving-route-dialog"
                data-testid="warning-dialog">
                <div className="leaving-route-dialog__header">
                    <WarningRound />

                    <span className="h--6">{title}</span>
                </div>

                <div className="leaving-route-dialog__body">
                    <span className="text-m">{subtitle}</span>
                </div>

                <div className="leaving-route-dialog__footer">
                    <div className="check-box">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="checkedC"
                                    className="leaving-route-checkbox"
                                    checked={acceptingTrigger}
                                    onChange={acceptingCheckboxFunc}
                                    color="primary"
                                />
                            }
                            label="Больше не показывать"
                        />
                    </div>

                    <div className="buttons">
                        <Button
                            type="button"
                            variant="contained"
                            className="btn btn-leaving-route btn-leaving-route__denied"
                            onClick={() => onCancel()}>
                            {cancelButtonText}
                        </Button>

                        <Button
                            type="button"
                            variant="contained"
                            className="btn btn-leaving-route btn-leaving-route__accept"
                            onClick={confirm}>
                            {confirmButtonText}
                        </Button>
                    </div>
                </div>
            </section>
        </Modal>
    );
};

export const WarningDialog = memo(WarningDialogInner);
