// Core
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

// Third party libraries
import 'focus-visible/dist/focus-visible';
import { ThemeProvider } from '@material-ui/core/styles';

// Redux
import { store } from 'redux/store';
import history from 'redux/history';

// Theme
import { uiTheme } from 'utils/theme/uiTheme';

// Components
import { App } from 'App/App';

// Styles
import './index.scss';

ReactDOM.render(
    <ThemeProvider theme={uiTheme}>
        <Router history={history}>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </ThemeProvider>,

    document.getElementById('root'),
);
