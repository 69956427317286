// Core
import React, { FC, memo } from 'react';

// Assets
import { Logo as LogoSvg } from 'assets/images/svgr/logo';

// Types
import { LogoTypes } from './logoTypes';

// Style
import './Logo.scss';

const LogoInner: FC<LogoTypes> = () => (
    <div className="logo">
        <div className="product-logo">
            <LogoSvg />
        </div>

        <div className="product-name">
            <span className="h--5">Астрал.ЭДО</span>
            <span className="h--6">Роуминг</span>
        </div>
    </div>
);

export const Logo = memo(LogoInner);
