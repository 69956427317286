import React, { memo } from 'react';

const UserInner = () => (
    <svg viewBox="0 0 12 12">
        <path
            fillRule="evenodd"
            d="M8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3ZM9 3C9 4.65685 7.65685 6 6 6C4.34315 6 3 4.65685 3 3C3 1.34315 4.34315 0 6 0C7.65685 0 9 1.34315 9 3ZM1.60281 9.00765C1.97389 8.56233 3.14412 7.5 6 7.5C8.85588 7.5 10.0261 8.56233 10.3972 9.00765C10.4572 9.07968 10.5 9.19961 10.5 9.38197C10.5 9.99944 9.99944 10.5 9.38197 10.5H2.61803C2.00056 10.5 1.5 9.99944 1.5 9.38197C1.5 9.19961 1.54279 9.07968 1.60281 9.00765ZM6 6.5C2.85565 6.5 1.4009 7.68785 0.834573 8.36749C0.572987 8.68141 0.5 9.06193 0.5 9.38197C0.5 10.5517 1.44828 11.5 2.61803 11.5H9.38197C10.5517 11.5 11.5 10.5517 11.5 9.38197C11.5 9.06193 11.427 8.68141 11.1654 8.36749C10.5991 7.68785 9.14435 6.5 6 6.5Z"
        />
    </svg>
);

export const User = memo(UserInner);
