// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';
import { formValidator } from 'utils/validator';

// Store
import { State } from 'redux/store';
import { useSelector, useDispatch } from 'react-redux';

// Third party libreries
import { FieldArray } from 'react-final-form-arrays';

// Components
import { OperatorCounterpart } from './OperatorCounterpart/OperatorCounterpart';
import { Agent } from './Agent/Agent';
import { ClientTicket } from './ClientTicket/ClientTicket';

// Actions
// eslint-disable-next-line import/order
import { formCombineActions } from 'redux/form/formActions';

// Types
// eslint-disable-next-line import/order
import { MainObjectsArrType, MainObjectType } from 'redux/form/formTypes';
import { FormBlockBodyTypes } from './formBlockBodyTypes';

// Style
import './FormBlockBody.scss';

const FormBlockBodyInner: FC<FormBlockBodyTypes> = ({
    formName,
    operator,
    agreement,
    operators,
    ticketNumber,
    formPartName,
    applicationId,
    contractorType,
    formBlockState,
    formBlockErrors,
    operatorPrefixIds,
    operatorPrefixState,
    setBlockValidation,
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: State) => state.auth, isEqual);
    const [fieldArrName] = useState(
        `${formName}.${formPartName}.${
            formPartName === 'sender' ? 'senders' : 'receivers'
        }`,
    );
    const [simplifiedAgentArr, setSimplifiedAgentArr] = useState<string[]>([]);
    const [agentsArr, setAgentsArr] = useState<JSX.Element | undefined>();

    useEffect(() => {
        if (ticketNumber || ticketNumber === '') {
            dispatch(formCombineActions.setTicketNumberAction(ticketNumber));
        }
    }, [dispatch, ticketNumber]);

    useEffect(() => {
        if (formBlockErrors) {
            setBlockValidation(
                formValidator(
                    formName,
                    formPartName,
                    formBlockErrors,
                    formBlockState,
                    ticketNumber,
                    operator,
                    agreement,
                    operators,
                    user?.prefix,
                    applicationId,
                ),
            );
        }
    }, [
        formBlockErrors,
        formBlockState,
        ticketNumber,
        operator,
        agreement,
        operators,
        user?.prefix,
        applicationId,
    ]);

    const simplifyArr = (arr: MainObjectsArrType) =>
        arr.map((elem: any) => {
            if (elem?.id) {
                const prefix = elem.id.slice(0, 3);
                const doesPrefixesIncludePrefix =
                    operatorPrefixIds.reduce(
                        (sum, curr) => (`${curr}` === prefix ? sum + 1 : sum),
                        0,
                    ) > 0;

                if (doesPrefixesIncludePrefix) {
                    return `${elem?.inn ? elem.inn : ''}${
                        elem?.kpp ? elem.kpp : ''
                    }${elem?.id ? elem.id.slice(3, elem.id.length) : ''}`;
                }

                return `${elem?.inn ? elem.inn : ''}${
                    elem?.kpp ? elem.kpp : ''
                }${elem?.id ? elem.id : ''}`;
            }

            return `${elem?.inn ? elem.inn : ''}${elem?.kpp ? elem.kpp : ''}`;
        });

    useEffect(() => {
        if (formBlockState) {
            const simplAgentArr = simplifyArr(formBlockState);

            if (simplAgentArr) {
                setSimplifiedAgentArr(simplAgentArr);
            } else {
                setSimplifiedAgentArr([]);
            }
        }
    }, [formBlockState]);

    const isDublicateValue = (
        value: MainObjectType,
        index: number,
        compareArr: string[],
    ) => {
        const answer = compareArr.reduce((sum, curr, id) => {
            const prefix = value?.id?.slice(0, 3);
            const doesPrefixesIncludePrefix =
                operatorPrefixIds.reduce(
                    (multipiler, currVal) =>
                        `${currVal}` === prefix ? multipiler + 1 : multipiler,
                    0,
                ) > 0;

            let currСompared = '';

            if (doesPrefixesIncludePrefix) {
                currСompared = `${value?.inn ? value.inn : ''}${
                    value?.kpp ? value.kpp : ''
                }${value?.id ? value.id.slice(3, value.id.length) : ''}`;
            } else {
                currСompared = `${value?.inn ? value.inn : ''}${
                    value?.kpp ? value.kpp : ''
                }${value?.id ? value.id : ''}`;
            }

            if (id < index && curr === currСompared) return sum + 1;

            return sum;
        }, 0);

        return answer > 0;
    };

    const renderAgent = (name: string, index: number, fields: any) => (
        <Agent
            fid={index}
            key={`${name}`}
            formName={formName}
            ticket={ticketNumber}
            contractorType={contractorType}
            agentState={formBlockState[index]}
            name={`${fieldArrName}[${index}]`}
            agentErrorState={formBlockErrors[index]}
            operatorPrefixState={operatorPrefixState}
            formBlockName={formPartName === 'sender' ? 'senders' : 'receivers'}
            isDeletable={fields.length > 1}
            isDuplicate={isDublicateValue(
                formBlockState[index],
                index,
                simplifiedAgentArr,
            )}
            autoLoadInn={formBlockState[index]?.autoLoadInn || false}
            autoLoadId={formBlockState[index]?.autoLoadId || false}
            remove={() => {
                fields.remove(index);
            }}
        />
    );

    const renderAgentsArr = () =>
        (formPartName === 'sender' || formPartName === 'receiver') && (
            <FieldArray name={fieldArrName} subscription={{}}>
                {({ fields }: any) =>
                    fields.map((name: string, index: number) =>
                        renderAgent(name, index, fields),
                    )
                }
            </FieldArray>
        );

    useEffect(() => {
        const element = renderAgentsArr();

        if (element) {
            setAgentsArr(element);
        }
    }, [formBlockState, formBlockErrors, ticketNumber]);

    return (
        <div className="form-part__body">
            {formName === 'clientForm' && formPartName === 'receiver' && (
                <OperatorCounterpart
                    formName={formName}
                    formPartName={formPartName}
                    blockFormState={operatorPrefixState}
                />
            )}

            {agentsArr}

            {ticketNumber !== null &&
                formName === 'clientForm' &&
                formPartName === 'sender' && (
                    <ClientTicket
                        formName={formName}
                        formPartName={formPartName}
                        blockFormState={ticketNumber}
                    />
                )}
        </div>
    );
};

export const FormBlockBody = memo(FormBlockBodyInner);
