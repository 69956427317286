// Core
import React, {
    FC,
    useRef,
    useState,
    useEffect,
    useCallback,
    memo,
} from 'react';

// Third party libraries
import { useFormState, useForm } from 'react-final-form';

// Utils
import { isEqual } from 'utils/isEqual';
import { doesOperatorNeedAgreement } from 'utils/operatorsCalc';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { SelectField } from 'components/SelectField/SelectField';
import { UploadZone } from 'components/UploadZone/UploadZone';
import { OperatorType } from 'redux/form/formTypes';
import { SupplierInfo } from '../SupplierInfo/SupplierInfo';

// Types
import { OperatorCounterpartTypes } from './operatorCounterpartTypes';

// Style
import './OperatorCounterpart.scss';

const OperatorCounterpartInner: FC<OperatorCounterpartTypes> = ({
    blockFormState,
    formName,
    formPartName,
}) => {
    const formState = useFormState();
    const { mutators } = useForm();
    const determRequiring = true;
    const { operators } = useSelector((state: State) => state.form, isEqual);
    const [currOperator, setCurrOperator] = useState('');
    const [isShowAgreement, setIsShowAgreement] = useState(false);
    const [selectedField, setSelectedField] = useState<string[]>([]);
    const opertorBlock = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (blockFormState) {
            setCurrOperator(blockFormState);
        } else {
            setCurrOperator('');
        }

        mutators.setValue(`${formName}.${formPartName}.supplier`, 'Не указано');
    }, [blockFormState, formName, formPartName, mutators]);

    useEffect(() => {
        if (currOperator) {
            setIsShowAgreement(
                doesOperatorNeedAgreement(operators, currOperator),
            );
        } else {
            setIsShowAgreement(false);
        }
    }, [currOperator, operators]);

    const normalizeOperatorsArrToString = useCallback(
        (field: string): string[] | undefined => {
            if (
                operators &&
                operators.length > 0 &&
                operators.reduce(
                    (prev, curr) => (typeof curr !== 'string' ? 1 : 0),
                    0,
                )
            ) {
                if (field === 'operator')
                    return operators.map(
                        (elem: OperatorType) => `${elem.prefix}`,
                    );
            }

            return undefined;
        },
        [operators],
    );

    useEffect(() => {
        const normalizedOperators = normalizeOperatorsArrToString('operator');

        if (normalizedOperators) {
            setSelectedField(normalizedOperators);
        }
    }, [normalizeOperatorsArrToString]);

    // Define elements styles 👇🏼

    const determOperatorCounterpartClass = () => {
        let className = 'operator-counterpart';

        if (
            (determRequiring && !blockFormState) ||
            (isShowAgreement &&
                document.querySelectorAll('.upload-zone--rejected'))
        ) {
            className += ' block--with-error';
        }

        return className;
    };

    return (
        <div
            ref={opertorBlock}
            className={determOperatorCounterpartClass()}
            data-testid="opertor-counterpart">
            {selectedField.length > 0 && (
                <SelectField
                    name={`${formName}.${formPartName}`}
                    fieldType="operator"
                    selectedArr={selectedField}
                    required={determRequiring}
                />
            )}

            {isShowAgreement && (
                <UploadZone
                    formName={formName}
                    formPartName={formPartName}
                    operatorSelectTouch={
                        formState.touched?.[
                            `${formName}.${formPartName}.operator`
                        ]
                    }
                />
            )}

            {(blockFormState === '2BM' || blockFormState === '2BK') && (
                <SupplierInfo
                    formName={formName}
                    formPartName={formPartName}
                    blockFormState={blockFormState}
                />
            )}
        </div>
    );
};

export const OperatorCounterpart = memo(OperatorCounterpartInner);
