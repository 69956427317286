// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Utils
import { fieldValidator } from 'utils/validator';
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Third party libraries
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';

// Action
import { formCombineActions } from 'redux/form/formActions';

// Types
import {
    FormNameType,
    FormPartNameType,
    FormBlockNameType,
} from 'redux/form/formTypes';
import { SelectFieldAdapterTypes } from './selectFieldAdapterTypes';

// Style
import './SelectFieldAdapter.scss';

const SelectFieldAdapterInner: FC<SelectFieldAdapterTypes> = ({
    label,
    name,
    fieldType,
    input,
    meta,
    selectedArr,
    wsError,
    required,
    disabled,
    isLoadFromFile,
}) => {
    const dispatch = useDispatch();
    const { operators } = useSelector((state: State) => state.form, isEqual);
    const [currValue, setCurrValue] = useState();
    const [validationError, setValidationError] = useState<
        undefined | 0 | string
    >();

    useEffect(() => {
        setCurrValue(input.value);
    }, [input]);

    useEffect(() => {
        setValidationError(
            fieldValidator(currValue, fieldType, required, wsError),
        );
    }, [currValue, wsError]);

    useEffect(() => {
        // @ts-ignore
        const [formName, formPart, formBlock, blockId]:
            | FormNameType
            | FormPartNameType
            | FormBlockNameType
            | number = name.replace(/\[/g, '.').replace(/\]/g, '').split('.');

        dispatch(
            formCombineActions.addFieldErrorAction({
                formName,
                formPart,
                formBlock,
                blockId,
                fieldType,
                error: validationError,
            }),
        );
    }, [validationError]);

    const checkFieldValidation = useCallback(() => {
        if (required && (meta.touched || isLoadFromFile)) {
            return typeof validationError === 'string'
                ? validationError.replace(/\/n/g, '. ').replace(/\\n/g, '. ')
                : undefined;
        }

        return undefined;
    }, [required, meta.touched, validationError, isLoadFromFile]);

    return (
        <div className="select-field-adapter">
            <FormControl
                error={Boolean(checkFieldValidation())}
                required={required}
                disabled={disabled}
                variant="standard"
                className="select-field"
                fullWidth>
                <InputLabel aria-label={label}>{label}</InputLabel>

                {selectedArr.length > 0 ? (
                    <Select
                        {...input}
                        id={label}
                        value={input.value}
                        onChange={(value) => input.onChange(value)}>
                        {selectedArr.map((elem, id) => (
                            <MenuItem key={elem} value={elem}>
                                {fieldType === 'supplier' && elem}
                                {fieldType === 'operator' &&
                                    `${operators[id].name} - ${operators[id].prefix}`}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Select
                        {...input}
                        id={label}
                        value={input.value}
                        onChange={(value) => input.onChange(value)}>
                        {fieldType === 'supplier' && (
                            <MenuItem value="Не указано">Не указано</MenuItem>
                        )}

                        {fieldType === 'operator' && (
                            <MenuItem value="Нет данных">Нет данных</MenuItem>
                        )}
                    </Select>
                )}

                <FormHelperText>{checkFieldValidation()}</FormHelperText>
            </FormControl>
        </div>
    );
};

export const SelectFieldAdapter = memo(SelectFieldAdapterInner);
