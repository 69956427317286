// Core
import { put, apply, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';

// Utils
import { deepTrim } from 'utils/deepTrim';

// API
import { api } from 'api';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

// Types
import {
    FetchAbonentFormType,
    AbonentResponseType,
    AbonentResponseSenderType,
    AbonentResponseReceiverType,
    SenderMainObjectTypeForRequest,
} from 'redux/form/formTypes';

export function* fetchAbonentFormWorker({
    payload: abonentData,
}: {
    type: string;
    payload: FetchAbonentFormType;
}): SagaIterator {
    try {
        yield put(uiCombineActions.startFetchingAction());

        deepTrim(abonentData);

        const { operator, sender, receiver, supplier, agreement, ticket } =
            abonentData;

        /* Due to task EDO-782, the "is_supplier" field on the frontend has been moved
         from the "senders" section to "recipients". To comply with the API request,
         the data is additionally mutated before sending, transferring the "is_supplier"
         field from the "recipients" section to the first element of the "senders" section */
        const senderForRequest: SenderMainObjectTypeForRequest[] = [...sender];
        senderForRequest[0] = { ...sender[0], is_supplier: supplier };

        const response = yield apply(api, api.abonent.fetch, [
            operator,
            senderForRequest,
            receiver,
            agreement,
            ticket,
        ]);

        const {
            status,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            processing_result,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            request_id,
            text,
        }: AbonentResponseType = yield apply(response, response.json, []);

        if (status === 0) {
            yield put(
                uiCombineActions.addToastMessageAction({
                    status,
                    text: 'Заявка отправлена',
                }),
            );

            yield delay(0);

            yield put(formCombineActions.setFormCleanerStateAction(true));
        } else {
            const errorMessage = processing_result?.text || text;

            const definedResponseErrors = (
                abonentResponse:
                    | AbonentResponseSenderType[]
                    | AbonentResponseReceiverType[],
            ) =>
                abonentResponse.map(
                    (
                        elem:
                            | AbonentResponseSenderType
                            | AbonentResponseReceiverType,
                        index: number,
                    ) => {
                        const clean = (obj: any) => {
                            Object.keys(obj).forEach((key) => {
                                if (!obj[key]) delete obj[key];
                                if (key === 'files') delete obj[key];
                            });

                            return obj;
                        };

                        const agentErrors = {
                            ...clean(elem.errors),
                            fid: index,
                        };

                        return agentErrors;
                    },
                );

            if (processing_result?.sender) {
                yield put(
                    formCombineActions.updateFieldsErrorAction({
                        contractor: 'contractor_type_3',
                        errorsArr: definedResponseErrors(
                            processing_result.sender,
                        ),
                    }),
                );
            }

            if (processing_result?.receiver) {
                yield put(
                    formCombineActions.updateFieldsErrorAction({
                        contractor: 'contractor_type_4',
                        errorsArr: definedResponseErrors(
                            processing_result.receiver,
                        ),
                    }),
                );
            }

            yield put(
                uiCombineActions.addToastMessageAction({
                    status: 1,
                    text: errorMessage,
                }),
            );
        }

        if (response.status !== 200) {
            throw new Error(text);
        }

        yield put(formCombineActions.clearInitialFormStateAction());

        yield put(formCombineActions.loadFromFileOffAction());

        yield delay(1000);

        if (sender[0].id && request_id) {
            yield put(
                push(`/status-info?uid=${sender[0].id}&status=${request_id}`),
            );
        }
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(error, 'fetchAbonentForm worker'),
        );
    } finally {
        yield put(formCombineActions.setFormCleanerStateAction(false));

        yield put(uiCombineActions.stopFetchingAction());
    }
}
