// Core
import { takeEvery, all, call } from 'redux-saga/effects';

// Types
import { authTypes } from '../authTypes';

// Workers
import { workerLogin } from './workers';

function* watchLogin() {
    yield takeEvery(authTypes.LOGIN_ASYNC, workerLogin);
}

export function* watcheAuth() {
    yield all([call(watchLogin)]);
}
