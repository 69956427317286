export interface UiState {
    isMenuShow: boolean;
    socket?: WebSocket;
    isFetching: boolean;
    toast: ToastObjType;
    progressBar: ProgressBarObjType;
    acceptRouteLeaving: boolean;
    acceptAutoCleringForm: boolean;
    windowSize: WindowSizeObj;
}

export interface SocketSendObj {
    socket: WebSocket;
    message: string;
}

export interface ToastObjType {
    messages: ToastMessageArrType;
}

export type ToastMessageArrType = ToastMessageType[];

export interface ToastMessageType {
    id?: number;
    status: number;
    text: string;
}

export interface ProgressBarObjType {
    progressBarState: boolean;
    progressBarName: string;
    progressBarCounters: ProgressBarCountersType[];
}

export interface ProgressBarCountersType {
    counterName: string;
    counterState: number;
    loaderState: number;
}

export interface WindowSizeObj {
    width: null | number;
    height: null | number;
}

export const uiTypes = {
    // Sync
    MENU_THUMB: 'MENU_THUMB',
    STORE_SOCKET: 'STORE_SOCKET',
    START_FETCHING: 'START_FETCHING',
    STOP_FETCHING: 'STOP_FETCHING',
    SOCKET_CONNECT: 'SOCKET_CONNECT',
    SOCKET_DISCONNECT: 'SOCKET_DISCONNECT',
    SOCKET_SEND: 'SOCKET_SEND',
    EMIT_ERROR: 'EMIT_ERROR',
    ADD_TOAST_MESSAGE: 'ADD_TOAST_MESSAGE',
    REMOVE_TOAST_MESSAGE: 'REMOVE_TOAST_MESSAGE',
    CLEAR_TOAST_MESSAGE: 'CLEAR_TOAST_MESSAGE',
    DETERM_WINDOW_SIZE: 'DETERM_WINDOW_SIZE',
    SET_PROGRESS_BAR: 'SET_PROGRESS_BAR',
    SET_PROGRESS_BAR_STATE: 'SET_PROGRESS_BAR_STATE',
    SET_PROGRESS_BAR_NAME: 'SET_PROGRESS_BAR_NAME',
    SET_PROGRESS_BAR_COUNTERS: 'SET_PROGRESS_BAR_COUNTERS',
    ACCEPT_ROUTE_LEAVING: 'ACCEPT_ROUTE_LEAVING',
    ACCEPT_AUTO_CLEARING_FORM: 'ACCEPT_CLEARING_FORM',

    // Async
    SOCKET_MESSAGE_ACYNC: 'SOCKET_MESSAGE_ACYNC',
};

interface MenuThumbActionType {
    type: typeof uiTypes.MENU_THUMB;
    payload?: any;
}

interface StoreSocketActionType {
    type: typeof uiTypes.STORE_SOCKET;
    payload: WebSocket;
}

interface StartFetchingActionType {
    type: typeof uiTypes.START_FETCHING;
    payload?: any;
}

interface StopFetchingActionType {
    type: typeof uiTypes.STOP_FETCHING;
    payload?: any;
}

interface SocketSendActionType {
    type: typeof uiTypes.SOCKET_SEND;
    payload: SocketSendObj;
}

interface ImitErrorActionType {
    type: typeof uiTypes.EMIT_ERROR;
    payload: string;
    error: true;
    meta: null | string;
}

interface AddToastMessageActionType {
    type: typeof uiTypes.ADD_TOAST_MESSAGE;
    payload: ToastMessageType;
}

interface RemoveToastMessageActionType {
    type: typeof uiTypes.REMOVE_TOAST_MESSAGE;
    payload: number;
}

interface ClearToastMessageActionType {
    type: typeof uiTypes.CLEAR_TOAST_MESSAGE;
    payload?: any;
}

interface DetermWindowSizeActionType {
    type: typeof uiTypes.DETERM_WINDOW_SIZE;
    payload: WindowSizeObj;
}

interface SetProgressBarActionType {
    type: typeof uiTypes.SET_PROGRESS_BAR;
    payload: ProgressBarObjType;
}

interface SetProgressBarStateActionType {
    type: typeof uiTypes.SET_PROGRESS_BAR_STATE;
    payload: boolean;
}

interface SetProgressBarNameActionType {
    type: typeof uiTypes.SET_PROGRESS_BAR_NAME;
    payload: string;
}

interface AcceptRouteLeavingActionType {
    type: typeof uiTypes.ACCEPT_ROUTE_LEAVING;
    payload?: any;
}
interface AcceptAutoCleringFormActionType {
    type: typeof uiTypes.ACCEPT_AUTO_CLEARING_FORM;
    payload?: any;
}

interface SetProgressBarCountersActionType {
    type: typeof uiTypes.SET_PROGRESS_BAR_COUNTERS;
    payload: ProgressBarCountersType[];
}

export type UiActionTypes =
    | MenuThumbActionType
    | StoreSocketActionType
    | StartFetchingActionType
    | StopFetchingActionType
    | SocketSendActionType
    | ImitErrorActionType
    | AddToastMessageActionType
    | RemoveToastMessageActionType
    | ClearToastMessageActionType
    | DetermWindowSizeActionType
    | SetProgressBarActionType
    | SetProgressBarStateActionType
    | SetProgressBarNameActionType
    | AcceptRouteLeavingActionType
    | AcceptAutoCleringFormActionType
    | SetProgressBarCountersActionType;
