// Types
import { AuthActionsType, authTypes, LoginType, UserType } from './authTypes';

export const authCombineActions = {
    // Sync
    authenticateAction: (user: UserType): AuthActionsType => ({
        type: authTypes.AUTHENTICATE,
        payload: user,
    }),
    logOutAction: (): AuthActionsType => ({
        type: authTypes.LOGOUT,
    }),

    // Async
    loginAction: (userData: LoginType): AuthActionsType => ({
        type: authTypes.LOGIN_ASYNC,
        payload: userData,
    }),
};
