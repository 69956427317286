export const convertDate = (timestamp?: string) => {
    if (timestamp) {
        const newDate = new Date(timestamp);

        const timeStampCon = `${
            (newDate.getDate() < 10 ? '0' : '') + newDate.getDate()
        }.${
            (newDate.getMonth() < 10 ? '0' : '') + (newDate.getMonth() + 1)
        }.${newDate.getFullYear()} - ${
            (newDate.getHours() < 10 ? '0' : '') + newDate.getHours()
        }:${(newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes()}:${
            (newDate.getSeconds() < 10 ? '0' : '') + newDate.getSeconds()
        }`;

        return timeStampCon;
    }

    return '';
};
