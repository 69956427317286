// Core
import { all, put, apply, delay } from 'redux-saga/effects';

// Third party libraries
import { omit } from 'lodash';

// API
import { api } from 'api';

// Types
import { FormActionTypes } from 'redux/form/formTypes';
import { SagaIterator } from '@redux-saga/types';

// Actions
import { formCombineActions } from 'redux/form/formActions';
import { uiCombineActions } from 'redux/ui/uiActions';
import { authCombineActions } from 'redux/auth/authActions';

export function* parseOperatorsFromFileWorker({
    payload: file,
}: FormActionTypes): SagaIterator {
    try {
        yield delay(0);

        yield put(formCombineActions.loadFromFileOnAction());

        const response = yield apply(api, api.parseOperator.fetch, [file]);

        const { status, senders, receivers, text } = yield apply(
            response,
            response.json,
            [],
        );

        if (status === 0) {
            yield put(
                uiCombineActions.addToastMessageAction({
                    status,
                    text: 'Загрузка файла',
                }),
            );
        } else if (status === 401) {
            yield put(
                uiCombineActions.addToastMessageAction({ status: 1, text }),
            );

            yield put(authCombineActions.logOutAction());

            yield put(formCombineActions.loadFromFileOffAction());
        } else {
            yield put(
                uiCombineActions.addToastMessageAction({ status: 1, text }),
            );
        }

        if (response.status === 200 && status === 0) {
            yield put(formCombineActions.clearInitialFormStateAction());

            yield put(formCombineActions.clearWsErrorAction());

            yield put(formCombineActions.clearFieldsErrorAction());

            yield all([
                put(uiCombineActions.setProgressBarState(true)),
                put(
                    uiCombineActions.setProgressBarName(
                        'Загрузка контрагентов',
                    ),
                ),
                put(
                    uiCombineActions.setProgressBarCounters([
                        {
                            counterName: 'Отправителей',
                            counterState: 0,
                            loaderState: 0,
                        },
                        {
                            counterName: 'Получателей',
                            counterState: 0,
                            loaderState: 0,
                        },
                    ]),
                ),
            ]);

            const optimizeSenders = senders.map((item: any) => ({
                ...omit(item, ['f', 'i', 'o']),
                ...(item.f && { lastname: item.f }),
                ...(item.i && { firstname: item.i }),
                ...(item.o && { patronymic: item.o }),
                kpp: item.inn.length === 12 ? '' : item.kpp,
            }));

            const optimizeReceivers = receivers.map((item: any) => ({
                ...omit(item, ['f', 'i', 'o']),
                ...(item.f && { lastname: item.f }),
                ...(item.i && { firstname: item.i }),
                ...(item.o && { patronymic: item.o }),
                kpp: item.inn.length === 12 ? '' : item.kpp,
            }));

            yield delay(1000);

            for (let i = 0; i < optimizeSenders.length; ++i) {
                const updatedProgressBarCounters = [
                    {
                        counterName: 'Отправителей',
                        counterState: optimizeSenders.length,
                        loaderState: i + 1,
                    },
                    {
                        counterName: 'Получателей',
                        counterState: optimizeReceivers.length,
                        loaderState: 0,
                    },
                ];

                yield put(
                    formCombineActions.fillOperatorFormSenderAction({
                        agent: optimizeSenders[i],
                        id: i,
                    }),
                );

                yield put(
                    uiCombineActions.setProgressBarCounters(
                        updatedProgressBarCounters,
                    ),
                );

                yield delay(0);
            }

            for (let i = 0; i < optimizeReceivers.length; ++i) {
                const updatedProgressBarCounters = [
                    {
                        counterName: 'Отправителей',
                        counterState: optimizeSenders.length,
                        loaderState: optimizeSenders.length,
                    },
                    {
                        counterName: 'Получателей',
                        counterState: optimizeReceivers.length,
                        loaderState: i + 1,
                    },
                ];

                yield put(
                    formCombineActions.fillOperatorFormReceiverAction({
                        agent: optimizeReceivers[i],
                        id: i,
                    }),
                );

                yield put(
                    uiCombineActions.setProgressBarCounters(
                        updatedProgressBarCounters,
                    ),
                );

                yield delay(0);
            }
        } else {
            throw new Error(text);
        }
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(
                error,
                'parseOperatorsFromFile worker',
            ),
        );
    } finally {
        yield put(uiCombineActions.setProgressBarState(false));

        yield delay(2000);

        yield all([
            put(uiCombineActions.setProgressBarName('')),
            put(uiCombineActions.setProgressBarCounters([])),
        ]);
    }
}
