// Third party libraries
import { produce } from 'immer';
import { v4 as uuid } from 'uuid';

// Types
import { UiState, UiActionTypes, uiTypes } from './uiTypes';

const initialState: UiState = {
    isMenuShow: true,
    socket: undefined,
    isFetching: false,
    toast: {
        messages: [],
    },
    progressBar: {
        progressBarState: false,
        progressBarName: '',
        progressBarCounters: [],
    },
    acceptRouteLeaving: false,
    acceptAutoCleringForm: false,
    windowSize: {
        width: null,
        height: null,
    },
};

export const uiReducer = (
    state = initialState,
    action: UiActionTypes,
): UiState => {
    switch (action.type) {
        case uiTypes.MENU_THUMB:
            return produce(state, (draftState) => {
                draftState.isMenuShow = !draftState.isMenuShow;
            });

        case uiTypes.STORE_SOCKET:
            return produce(state, (draftState) => {
                draftState.socket = action.payload;
            });

        case uiTypes.START_FETCHING:
            return produce(state, (draftState) => {
                draftState.isFetching = true;
            });

        case uiTypes.STOP_FETCHING:
            return produce(state, (draftState) => {
                draftState.isFetching = false;
            });

        case uiTypes.ADD_TOAST_MESSAGE:
            return produce(state, (draftState) => {
                draftState.toast.messages.push({
                    ...action.payload,
                    id: uuid(),
                });
            });

        case uiTypes.REMOVE_TOAST_MESSAGE:
            return produce(state, (draftState) => {
                draftState.toast.messages.splice(action.payload, 1);
            });

        case uiTypes.CLEAR_TOAST_MESSAGE:
            return produce(state, (draftState) => {
                draftState.toast.messages = [];
            });

        case uiTypes.DETERM_WINDOW_SIZE:
            return produce(state, (draftState) => {
                draftState.windowSize = action.payload;
            });

        case uiTypes.SET_PROGRESS_BAR:
            return produce(state, (draftState) => {
                draftState.progressBar = action.payload;
            });

        case uiTypes.SET_PROGRESS_BAR_STATE:
            return produce(state, (draftState) => {
                draftState.progressBar.progressBarState = action.payload;
            });

        case uiTypes.SET_PROGRESS_BAR_NAME:
            return produce(state, (draftState) => {
                draftState.progressBar.progressBarName = action.payload;
            });

        case uiTypes.SET_PROGRESS_BAR_COUNTERS:
            return produce(state, (draftState) => {
                draftState.progressBar.progressBarCounters = action.payload;
            });

        case uiTypes.ACCEPT_ROUTE_LEAVING:
            return produce(state, (draftState) => {
                draftState.acceptRouteLeaving = !draftState.acceptRouteLeaving;
            });

        case uiTypes.ACCEPT_AUTO_CLEARING_FORM:
            return produce(state, (draftState) => {
                draftState.acceptAutoCleringForm =
                    !draftState.acceptAutoCleringForm;
            });

        default:
            return state;
    }
};
