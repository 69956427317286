import React, { memo } from 'react';

const FinishMarkInner = () => (
    <svg viewBox="0 0 14 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 2.57149C14 2.57149 9.34646 0.971615 7 0C4.64404 0.969785 0 2.57149 0 2.57149V5.04488C0 8.76138 0.886306 11.3807 2.55349 13.0486C3.62909 14.1247 5.07253 14.992 6.44603 15.7102L7 16L7.55397 15.7102C8.92142 14.9951 10.3656 14.142 11.4457 13.0641C13.1145 11.3989 14 8.77267 14 5.04488V2.57149ZM10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289L6 8.58579L4.70711 7.29289C4.31658 6.90237 3.68342 6.90237 3.29289 7.29289C2.90237 7.68342 2.90237 8.31658 3.29289 8.70711L5.29289 10.7071L6 11.4142L6.70711 10.7071L10.7071 6.70711Z"
        />
    </svg>
);

export const FinishMark = memo(FinishMarkInner);
