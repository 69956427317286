export const link = [
    {
        name: 'client',
        title: 'Клиентам',
        link: '/client',
    },
    {
        name: 'statusInfo',
        title: 'Информация о заявлении',
        link: '/status-info',
    },
    {
        name: 'agentInfo',
        title: 'Проверка контрагентов',
        link: '/agent-info',
    },
    {
        name: 'operator',
        title: 'Операторам',
        link: '/operator',
    },
];
