/* eslint-disable import/no-mutable-exports */
// Core
import { Dispatch } from 'react';

// Config
import { ROOT_WS } from 'api';

// Types
import { UiActionTypes } from 'redux/ui/uiTypes';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

export const setupSocket = (
    dispatch: Dispatch<UiActionTypes>,
    uiAction: typeof uiCombineActions,
    formAction: typeof formCombineActions,
) => {
    let socket: WebSocket | undefined;

    const connectToSocket = () => {
        const ROOT_WS_PROD_OR_TEST =
            process.env.NODE_ENV === 'test' ? 'ws://localhost' : ROOT_WS;
        socket = new WebSocket(
            `${ROOT_WS_PROD_OR_TEST}/v2/ws/validate/contractors`,
        );

        socket.onopen = () => {
            if (process.env.NODE_ENV !== 'production') {
                console.log('%cWebSocket Client Connected', 'color:green');
            }

            if (socket) {
                dispatch(uiAction.storeSocketAction(socket));
            }
        };

        socket.onmessage = (event: MessageEvent) => {
            const message = JSON.parse(event.data);

            if (process.env.NODE_ENV !== 'production') {
                console.log('%cWebSocket receive message', 'color:green');
            }

            dispatch(formAction.updateWsErrorAction(message));
        };

        socket.onclose = () => {
            if (process.env.NODE_ENV !== 'production') {
                console.log('%cWebSocket Client Disconnect', 'color: red');
            }

            let timeoutForReconnect: null | number = null;

            if (timeoutForReconnect) {
                clearTimeout(timeoutForReconnect);
            }

            if (socket?.readyState !== 1) {
                timeoutForReconnect = window.setTimeout(() => {
                    if (process.env.NODE_ENV !== 'production') {
                        console.log(
                            '%cTry reconnect to WebSocket',
                            'color:yellow',
                        );
                    }

                    setupSocket(dispatch, uiAction, formAction);
                }, 10000);
            }
        };

        socket.onerror = () => {
            if (process.env.NODE_ENV !== 'production') {
                console.log(
                    '%cSocket encountered error: Closing socket',
                    'color: red',
                );
            }

            socket?.close();
        };
    };

    connectToSocket();

    return socket;
};
