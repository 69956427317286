// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';

// Third parti libraries
import Button from '@material-ui/core/Button';

// Assets
import { User } from 'assets/images/svgr/user';
import { LogOut } from 'assets/images/svgr/log-out';

// Types
import { authCombineActions } from 'redux/auth/authActions';
import { formCombineActions } from 'redux/form/formActions';
import { UserProfileType } from './userProfileTypes';

// Action

// Style
import './UserProfile.scss';

const UserProfileInner: FC<UserProfileType> = ({
    isRendering,
    isAuthenticate,
    user,
}) => {
    const dispatch = useDispatch();
    const [isShown, setIsShown] = useState(false);
    const [isHover, setIsHover] = useState(false);

    const handleDocumentPoinetDown = useCallback(() => {
        if (!isHover) {
            setIsShown(false);
        }
    }, [isHover]);

    useEffect(() => {
        document.addEventListener('pointerdown', handleDocumentPoinetDown);

        return () => {
            document.removeEventListener(
                'pointerdown',
                handleDocumentPoinetDown,
            );
        };
    }, [isShown, handleDocumentPoinetDown]);

    const logout = () => {
        setIsShown(false);
        setIsHover(false);
        dispatch(authCombineActions.logOutAction());
        dispatch(formCombineActions.clearInitialFormStateAction());
        dispatch(formCombineActions.loadFromFileOffAction());
    };

    return (
        <div
            data-testid="user-profile"
            className="user-profile"
            onPointerOver={() => setIsHover(true)}
            onPointerLeave={() => setIsHover(false)}>
            {isRendering && isAuthenticate && (
                <div
                    data-testid="user-poppup"
                    className={`user-poppup ${
                        isShown ? 'user-poppup--shown' : 'user-poppup--hidden'
                    }`}>
                    <div className="poppup__body">
                        <div className="user__name">
                            <span className="p--m">{user?.login}</span>
                            <span className="p--sm">{user?.prefix}</span>
                        </div>

                        <button
                            type="button"
                            data-testid="user-avatar"
                            className="user__avatar"
                            onClick={() => setIsShown(!isShown)}>
                            <div className="client-image">
                                <User />
                            </div>

                            <div className="arrow" />
                        </button>
                    </div>

                    <div className="poppup__footer">
                        <div className="poppup-menu__item">
                            <Button
                                type="button"
                                variant="contained"
                                className="btn"
                                startIcon={<LogOut />}
                                onClick={logout}
                                fullWidth
                                tabIndex={isShown ? 0 : -1}>
                                Выход
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const UserProfile = memo(UserProfileInner);
