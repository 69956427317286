// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libraries
import { Tooltip } from '@material-ui/core';

// Store
import { useDispatch } from 'react-redux';

// Assets
import { Shown } from 'assets/images/svgr/shown';
import { Hide } from 'assets/images/svgr/hide';
import { Trash } from 'assets/images/svgr/trash';

// Actions
import { formCombineActions } from 'redux/form/formActions';

// Types
import { AgentHeaderTypes } from './agentHeaderTypes';

// Style
import './AgentHeader.scss';

const AgentHeaderInner: FC<AgentHeaderTypes> = ({
    id,
    formName,
    formBlockName,
    agentState,
    contractor,
    isDeletable,
    isConnected,
    rollupToggler,
    remove,
}) => {
    const dispatch = useDispatch();
    const [currAgentHeader, setCurrAgentHeader] = useState('');

    useEffect(() => {
        if (agentState) {
            if (agentState.inn?.length === 12) {
                setCurrAgentHeader(
                    `${agentState.lastname ? agentState.lastname : ''} ${
                        agentState.firstname ? agentState.firstname : ''
                    } ${agentState.patronymic ? agentState.patronymic : ''}`,
                );
            } else {
                setCurrAgentHeader(agentState.name ? agentState.name : '');
            }
        } else setCurrAgentHeader('');
    }, [agentState]);

    const agentDeleting = (
        agentId: number,
        agentContractorType: number | undefined,
    ) => {
        if (isDeletable) {
            remove();

            if (
                formName &&
                (formBlockName === 'senders' || formBlockName === 'receivers')
            ) {
                const formPartName =
                    formBlockName === 'senders' ? 'sender' : 'receiver';

                dispatch(
                    formCombineActions.deleteFieldErrorAction({
                        formName,
                        formPartName,
                        formBlockName,
                        blockId: id,
                    }),
                );
            }

            if (agentContractorType) {
                dispatch(
                    formCombineActions.deleteWsErrorAction({
                        agentId,
                        contractor: agentContractorType,
                    }),
                );
            }
        }
    };

    const triggerRollup = () => {
        rollupToggler(!agentState?.isRolluped);
    };

    return (
        <div className="agent-header">
            <Tooltip
                title={`${
                    agentState?.isRolluped
                        ? 'Показать контрагента'
                        : 'Скрыть контрагента'
                }`}
                placement="right">
                <button
                    type="button"
                    className="agent-header__hidder"
                    onClick={triggerRollup}>
                    {agentState?.isRolluped ? <Hide /> : <Shown />}
                </button>
            </Tooltip>

            <div className="agent-header__title">
                <div className="agent-header__title-wrapper">
                    {isConnected && (
                        <div className="connected-label">
                            <span className="p--xs">Связь уже установлена</span>
                        </div>
                    )}

                    <div className="agent-name">
                        <span className="p--xs">{currAgentHeader}</span>
                    </div>
                </div>
            </div>

            {isDeletable && (
                <Tooltip title="Удалить контрагента" placement="left">
                    <button
                        type="button"
                        className={`agent-header__deleter ${
                            !isDeletable && 'agent-header__deleter--disabled'
                        }`}
                        onClick={() => agentDeleting(id, contractor)}>
                        <Trash />
                    </button>
                </Tooltip>
            )}
        </div>
    );
};

export const AgentHeader = memo(AgentHeaderInner);
