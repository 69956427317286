// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libraries
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-final-form';

// Utils
import { isEqual } from 'utils/isEqual';
import { MAX_FILE_SIZE } from 'utils/constants';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { formCombineActions } from 'redux/form/formActions';
import { UploadContentImage } from './UploadContent/UploadContentImage/UploadContentImage';
import { UploadContentXlsx } from './UploadContent/UploadContentXlsx/UploadContentXlsx';
import { FileViewer } from './FileViewer/FileViewer';

// Types
import { UploadZoneTypes } from './uploadZoneTypes';

// Style
import './UploadZone.scss';

const UploadZoneInner: FC<UploadZoneTypes> = ({
    formName,
    operatorSelectTouch = false,
    formPartName,
}) => {
    const {
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps,
        open,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        multiple: false,
        accept: `${
            formName === 'clientForm' && formPartName === 'receiver'
                ? 'application/pdf, image/png, 	image/jpeg'
                : 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }`,
    });
    const dispatch = useDispatch();
    const { mutators } = useForm();
    const { loadFromFile } = useSelector((state: State) => state.form, isEqual);
    const [loadedFile, setLoadedFile] = useState<File | null>(null);
    const [isDragFileHavy, setIsDragFileHavy] = useState(false);

    useEffect(() => {
        if (loadedFile) {
            mutators.setValue(
                `${formName}.${formPartName}.agreement`,
                loadedFile,
            );
        }
    }, [formName, formPartName, loadedFile, mutators]);

    useEffect(() => {
        if (acceptedFiles.length > 0 && !isDragFileHavy) {
            setLoadedFile(acceptedFiles[0]);
        } else {
            setLoadedFile(null);
        }
    }, [acceptedFiles, isDragFileHavy]);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            if (acceptedFiles[0].size > MAX_FILE_SIZE) {
                setIsDragFileHavy(true);
            } else {
                setIsDragFileHavy(false);
            }
        }
    }, [acceptedFiles]);

    useEffect(() => {
        const xlsxTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        const imageTypes = ['application/pdf', 'image/png', 'image/jpeg'];

        if (
            formName === 'clientForm' &&
            loadedFile &&
            imageTypes.includes(loadedFile.type) &&
            !isDragFileHavy
        ) {
            mutators.setValue(
                `${formName}.${formPartName}.agreement`,
                loadedFile,
            );
        }

        if (
            formName === 'clientForm' &&
            loadedFile &&
            xlsxTypes.includes(loadedFile.type) &&
            !isDragFileHavy
        ) {
            dispatch(formCombineActions.loadFromFileOffAction());
            dispatch(formCombineActions.parseClientsFromFileAction(loadedFile));
        }

        if (
            formName === 'operatorForm' &&
            loadedFile &&
            xlsxTypes.includes(loadedFile.type) &&
            !isDragFileHavy
        ) {
            dispatch(formCombineActions.loadFromFileOffAction());
            dispatch(
                formCombineActions.parseOperatorsFromFileAction(loadedFile),
            );
        }
    }, [
        dispatch,
        loadedFile,
        isDragFileHavy,
        formName,
        mutators,
        formPartName,
    ]);

    const clearLoadedFile = () => {
        setLoadedFile(null);
    };

    useEffect(() => {
        if (!loadFromFile) {
            clearLoadedFile();
        }
    }, [loadFromFile]);

    // Define elements styles 👇🏼

    const determUploadZoneClass = () => {
        let className = 'upload-zone';

        if (
            isDragReject ||
            (operatorSelectTouch && !loadedFile) ||
            (!isDragActive && isDragFileHavy)
        ) {
            className += ' upload-zone--rejected';
        }

        if (isDragAccept || loadedFile || (!formPartName && loadFromFile)) {
            className += ' upload-zone--accepted';
        }

        if (
            className.includes('upload-zone--rejected') &&
            className.includes('upload-zone--accepted')
        ) {
            return className.replace(' upload-zone--rejected', '');
        }

        return className;
    };

    return (
        <div className={determUploadZoneClass()}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />

                <>
                    {formName === 'clientForm' &&
                    formPartName === 'receiver' ? (
                        <UploadContentImage
                            isDragAccept={isDragAccept}
                            isDragReject={isDragReject}
                            isDragActive={isDragActive}
                            isDragFileHavy={isDragFileHavy}
                            open={open}
                        />
                    ) : (
                        <UploadContentXlsx
                            formName={formName}
                            isDragAccept={isDragAccept}
                            isDragReject={isDragReject}
                            isDragActive={isDragActive}
                            isDragFileHavy={isDragFileHavy}
                            open={open}
                        />
                    )}
                </>
            </div>

            {formName === 'clientForm' &&
                formPartName === 'receiver' &&
                loadedFile &&
                !isDragFileHavy && (
                    <FileViewer
                        loadedFile={loadedFile}
                        clearLoadedFile={clearLoadedFile}
                    />
                )}
        </div>
    );
};

export const UploadZone = memo(UploadZoneInner);
