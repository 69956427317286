// Core
import { SagaIterator } from '@redux-saga/types';
import { all, call } from 'redux-saga/effects';

// Watchers
import { watcheForm } from 'redux/form/saga/watchers';
import { watcheUi } from 'redux/ui/saga/watchers';
import { watcheAuth } from 'redux/auth/saga/watchers';

export function* rootSaga(): SagaIterator {
    yield all([call(watcheForm), call(watcheUi), call(watcheAuth)]);
}
