export const deepTrim = (obj: any) => {
    let trimObj: any;

    Object.keys(obj).map((key) => {
        if (obj[key] && typeof obj[key] === 'string') {
            trimObj = { ...trimObj, [key]: obj[key].trim() };
            return trimObj;
        }

        if (obj[key] && typeof obj[key] === 'object') {
            trimObj = { ...trimObj, [key]: deepTrim(obj[key]) };
            return trimObj;
        }

        trimObj = { ...trimObj, [key]: obj[key] };

        return trimObj;
    });

    return trimObj;
};
