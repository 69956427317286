// Core
import React, { FC, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';

// Store
import { State } from 'redux/store';

// Utils
import { formValidator } from 'utils/validator';
import { isEqual } from 'utils/isEqual';

// Third party libreries
import { useFormState } from 'react-final-form';

// Assets
import { Chekmark } from 'assets/images/svgr/chekmark';
import { Warning } from 'assets/images/svgr/warning';

// Components
import { AsideMain } from 'components/Aside/AsideMain/AsideMain';
import { AgentInfoType } from 'redux/form/formTypes';
import { Loader } from 'components/Loader/Loader';
import { AgentInfoTable } from 'components/Tables';
import { FindAgentInfo } from './FindAgentInfo/FindAgentInfo';

// Types
import { AgentInfoFormTypes } from './agentInfoFormTypes';

// Style
import './AgentInfoForm.scss';

const AgentInfoFormInner: FC<AgentInfoFormTypes> = ({
    formName,
    setIsBlockValid,
}) => {
    const formState = useFormState();
    const { formErrors } = useSelector((state: State) => state.form, isEqual);
    const { isFetching } = useSelector((state: State) => state.ui, isEqual);
    const { inn, kpp, abonents } = useSelector(
        (state: State) => state.form.agentInfo,
        isEqual,
    );

    useEffect(() => {
        if (formName === 'agentInfoForm' && formErrors[formName]?.agentInfo) {
            const errors: AgentInfoType | undefined =
                formErrors[formName]?.agentInfo;

            if (errors) {
                setIsBlockValid(
                    formValidator(
                        formName,
                        'agentInfo',
                        errors,
                        formState.values[formName]?.agentInfo,
                    ),
                );
            }
        }
    }, [formErrors[formName]]);

    return (
        <section data-testid="agent-info-form" className="agent-info-form">
            <div className="agent-info-form__header">
                <div className="header__inputs-block">
                    <FindAgentInfo
                        formName={formName}
                        formPartName="agentInfo"
                        formPartState={formState.values[formName]?.agentInfo}
                    />
                </div>

                <AsideMain />
            </div>

            <div className="agent-info-form__body">
                <Loader isFetching={isFetching} />

                {abonents && abonents.length > 0 && (
                    <>
                        <div
                            data-testid="abonent-status"
                            className="abonent-status abonent-status--valid">
                            <Chekmark />

                            <span className="h--6">
                                Контрагент с{' '}
                                {`ИНН-${inn}${kpp ? ` и КПП-${kpp}` : ''}`}{' '}
                                является абонентом
                            </span>
                        </div>

                        <AgentInfoTable
                            agentData={{ inn, kpp }}
                            contacts={abonents}
                        />
                    </>
                )}

                {abonents === null && (
                    <div
                        data-testid="abonent-status"
                        className="abonent-status abonent-status--unvalid">
                        <Warning />

                        <span className="h--6">
                            Контрагент с{' '}
                            {`ИНН-${inn}${kpp ? ` и КПП-${kpp}` : ''}`} не
                            является абонентом
                        </span>
                    </div>
                )}
            </div>
        </section>
    );
};

export const AgentInfoForm = memo(AgentInfoFormInner);
