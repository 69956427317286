// Core
import React, { memo } from 'react';

// Components
import { FinalForm } from 'components/FinalForm/FinalForm';

// Components

// Style
import './AgentInfo.scss';

const AgentInfoInner = () => (
    <div className="agent-info-page">
        <FinalForm formName="agentInfoForm" />
    </div>
);

export const AgentInfo = memo(AgentInfoInner);
