// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Third party libraries
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
} from '@material-ui/core';

// Assets
import { ArrowSimple } from 'assets/images/svgr/arrowSimple';
import { Copy } from 'assets/images/svgr/copy';

// Types
import { ConnectionsIdEdoType } from './connectionsIdEdoTypes';

// Style
import './ConnectionsIdEdo.scss';

const ConnectionsIdEdoInner: FC<ConnectionsIdEdoType> = ({
    formName,
    formBlockName,
    agentsCounters,
    connectedId,
    notConnectedId,
    isConnected,
    fid,
    setIsConnected,
}) => {
    const [connectedIdArr, setConnectedIdArr] = useState<string[]>([]);
    const [notConnectedIdArr, setNotConnectedIdArr] = useState<string[]>([]);

    useEffect(() => {
        if (connectedId && connectedId.length > 0 && connectedId[fid]) {
            setConnectedIdArr(connectedId[fid]);
        } else {
            setConnectedIdArr([]);
        }
    }, [connectedId, fid]);

    useEffect(() => {
        if (
            notConnectedId &&
            notConnectedId.length > 0 &&
            notConnectedId[fid]
        ) {
            setNotConnectedIdArr(notConnectedId[fid]);
        } else {
            setNotConnectedIdArr([]);
        }
    }, [notConnectedId, fid]);

    const checkOnConnection = useCallback(() => {
        if (connectedIdArr.length > 0 && formName === 'operatorForm') {
            if (
                agentsCounters.senders >= agentsCounters.receivers &&
                formBlockName === 'senders'
            ) {
                return true;
            }

            if (
                agentsCounters.senders < agentsCounters.receivers &&
                formBlockName === 'receivers'
            ) {
                return true;
            }
        }

        return false;
    }, [
        agentsCounters.receivers,
        agentsCounters.senders,
        connectedIdArr.length,
        formBlockName,
        formName,
    ]);

    useEffect(() => {
        setIsConnected(checkOnConnection());
    }, [checkOnConnection, connectedIdArr, setIsConnected]);

    const copyToClipBoard = (idEdo: string) => {
        const el = document.createElement('textarea');
        el.className = 'copyTextArea';
        el.value = idEdo;

        document.body.appendChild(el).select();
        document.execCommand('copy');
        el.remove();
    };

    const copyToClipBoardBtn = (copiedId: string) => (
        <button
            type="button"
            data-testid="coppy-to-clipboard-btn"
            className="copy-icon"
            onClick={() => copyToClipBoard(copiedId)}>
            <Copy />
        </button>
    );

    return (
        <>
            {connectedIdArr?.length > 0 && isConnected && (
                <Accordion
                    data-testid="connectedId"
                    className="connections-idedo">
                    <AccordionSummary
                        expandIcon={<ArrowSimple />}
                        aria-controls="connections-idedo--content"
                        className="connections-idedo__header">
                        <span className="h--6">
                            Найдены ИдЭДО с типами связей
                        </span>
                    </AccordionSummary>

                    <AccordionDetails>
                        <div className="connections-idedo__body">
                            <div className="idedo-type idedo--connected">
                                <div className="idedo-type__title">
                                    <span className="h--7">
                                        С установленной связью:
                                    </span>
                                </div>

                                <div className="idedo-type__list">
                                    {connectedIdArr.map((elem) => (
                                        <div
                                            className="idedo-elem"
                                            key={`connected_${fid}_${elem}`}>
                                            <span className="p--sm">
                                                {elem}
                                            </span>

                                            <Tooltip
                                                title={`Скопировать: ${elem}`}
                                                placement="right"
                                                arrow>
                                                {copyToClipBoardBtn(elem)}
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {notConnectedIdArr?.length > 0 && (
                                <div className="idedo-type idedo--not-connected">
                                    <div className="idedo-type__title">
                                        <span className="h--7">
                                            С неустановленной связью:
                                        </span>
                                    </div>

                                    <div className="idedo-type__list">
                                        {notConnectedIdArr.map((elem) => (
                                            <div
                                                className="idedo-elem"
                                                key={`notconnected_${fid}_${elem}`}>
                                                <span
                                                    id={`${fid}${elem}`}
                                                    className="p--sm">
                                                    {elem}
                                                </span>

                                                <Tooltip
                                                    title={`Скопировать: ${elem}`}
                                                    placement="right"
                                                    arrow>
                                                    {copyToClipBoardBtn(elem)}
                                                </Tooltip>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </>
    );
};

export const ConnectionsIdEdo = memo(ConnectionsIdEdoInner);
