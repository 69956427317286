// Core
import React, { FC, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { MainInfoFields } from 'components/MainInfoFields/MainInfoFields';
import { InputField } from 'components/InputField/InputField';

// Types
import { OperatorReceiverTypes } from './operatorReceiverTypes';

// Style
import './OperatorReceiver.scss';

const OperatorReceiverInner: FC<OperatorReceiverTypes> = ({
    blockFormState,
    name,
    idAdornment,
    formBlockWsError,
    autoLoadInn,
    autoLoadId,
    autoFillByInnNavigator,
    autoFillByInnOperator,
    autoFillByIdOperator,
}) => {
    const { loadFromFile } = useSelector((state: State) => state.form, isEqual);

    return (
        <div className="operator-receiver">
            <MainInfoFields
                mainInfoState={blockFormState}
                name={name}
                formBlockWsError={formBlockWsError}
                isLoadFromFile={loadFromFile}
                autoLoadInn={autoLoadInn}
                autoFillByInnNavigator={autoFillByInnNavigator}
                autoFillByInnOperator={autoFillByInnOperator}
            />

            <InputField
                name={`${name}`}
                value={blockFormState?.id}
                fieldType="id"
                idAdornment={idAdornment}
                wsError={formBlockWsError?.id}
                required={
                    !!(blockFormState?.id && blockFormState?.id.length > 0)
                }
                isLoadFromFile={loadFromFile}
                autoLoadId={autoLoadId}
                autoFillByIdOperator={autoFillByIdOperator}
            />
        </div>
    );
};

export const OperatorReceiver = memo(OperatorReceiverInner);
