import { FormPartStateTypes, FormNameType } from 'redux/form/formTypes';

export const defineShouldHeaderRender = (
    form: FormNameType,
    part: 'sender' | 'receiver',
    state?: FormPartStateTypes,
): boolean => {
    if (form === 'clientForm' && part === 'sender') return false;

    if (form === 'operatorForm' && state) {
        const agentCount =
            part === 'sender'
                ? state.receiver?.receivers?.length
                : state.sender?.senders?.length;

        if (part === 'sender' && agentCount && agentCount > 1) {
            return false;
        }

        if (part === 'receiver' && agentCount && agentCount > 1) {
            return false;
        }
    }

    return true;
};
