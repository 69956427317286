// Core
import { put, apply, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

// API
import { api } from 'api';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { authCombineActions } from 'redux/auth/authActions';
import { formCombineActions } from 'redux/form/formActions';

// Types
import {
    FetchOperatorFormType,
    OperatorResponseType,
    OperatorResponseAbonentType,
    AbonentResponseSenderType,
    AbonentResponseReceiverType,
} from 'redux/form/formTypes';

// Utils
import { deepTrim } from 'utils/deepTrim';

export function* fetchOperatorFormWorker({
    payload: operatorData,
}: {
    type: string;
    payload: FetchOperatorFormType;
}): SagaIterator {
    try {
        yield put(uiCombineActions.startFetchingAction());

        deepTrim(operatorData);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { request_id, senders, receivers } = operatorData;

        const response = yield apply(api, api.operator.fetch, [
            senders,
            receivers,
            request_id,
        ]);

        const {
            status,
            text,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            processing_result,
        }: OperatorResponseType = yield apply(response, response.json, []);

        if (status === 0) {
            yield put(
                uiCombineActions.addToastMessageAction({
                    status,
                    text: 'Заявка отправлена',
                }),
            );

            yield delay(0);

            yield put(formCombineActions.setFormCleanerStateAction(true));
        } else if (status === 1) {
            const definedResponseErrors = (
                abonentResponse:
                    | AbonentResponseSenderType[]
                    | AbonentResponseReceiverType[],
            ) =>
                abonentResponse.map(
                    (
                        elem:
                            | AbonentResponseSenderType
                            | AbonentResponseReceiverType,
                        index: number,
                    ) => {
                        const clean = (obj: any) => {
                            Object.keys(obj).forEach((key) => {
                                if (!obj[key]) delete obj[key];
                                if (key === 'files') delete obj[key];
                            });

                            return obj;
                        };

                        const agentErrors = {
                            ...clean(elem.errors),
                            fid: index,
                        };

                        return agentErrors;
                    },
                );

            if (processing_result?.sender) {
                yield put(
                    formCombineActions.updateFieldsErrorAction({
                        contractor: 'contractor_type_1',
                        errorsArr: definedResponseErrors(
                            processing_result.sender,
                        ),
                    }),
                );
            }

            if (processing_result?.receiver) {
                yield put(
                    formCombineActions.updateFieldsErrorAction({
                        contractor: 'contractor_type_2',
                        errorsArr: definedResponseErrors(
                            processing_result.receiver,
                        ),
                    }),
                );
            }

            yield put(uiCombineActions.addToastMessageAction({ status, text }));
        } else if (status === 2) {
            yield put(uiCombineActions.addToastMessageAction({ status, text }));
        } else if (status === 401) {
            yield put(
                uiCombineActions.addToastMessageAction({ status: 1, text }),
            );

            yield put(authCombineActions.logOutAction());
        }

        const checkOnError = (arr: OperatorResponseAbonentType[]) => {
            const errorCounter = arr.reduce((summ, curr) => {
                if (summ < 1 && curr.text) {
                    return summ + 1;
                }

                return summ;
            }, 0);

            return errorCounter;
        };

        if (
            processing_result &&
            (processing_result.status === 1 || processing_result.status === 2)
        ) {
            const senderError = checkOnError(processing_result.sender);
            const receiverError = checkOnError(processing_result.receiver);
            const defineIdArr = (
                connectedArr: OperatorResponseAbonentType[],
                idArrType: 'connected' | 'not_connected',
            ) =>
                connectedArr.map((elem) => {
                    if (elem[idArrType]) {
                        return elem[idArrType];
                    }

                    return [];
                });

            if (senderError) {
                yield put(
                    formCombineActions.setConnectedIdAction(
                        defineIdArr(processing_result.sender, 'connected'),
                    ),
                );

                yield put(
                    formCombineActions.setNotConnectedIdAction(
                        defineIdArr(processing_result.sender, 'not_connected'),
                    ),
                );
            }

            if (receiverError) {
                yield put(
                    formCombineActions.setConnectedIdAction(
                        defineIdArr(processing_result.receiver, 'connected'),
                    ),
                );

                yield put(
                    formCombineActions.setNotConnectedIdAction(
                        defineIdArr(
                            processing_result.receiver,
                            'not_connected',
                        ),
                    ),
                );
            }
        }

        if (status === 0) {
            yield put(formCombineActions.loadFromFileOffAction());
        }

        if (response.status !== 200) {
            throw new Error(text);
        }
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(error, 'fetchOperatorForm worker'),
        );
    } finally {
        yield put(formCombineActions.setFormCleanerStateAction(false));

        yield put(uiCombineActions.stopFetchingAction());
    }
}
