// Core
import { put, apply, delay } from 'redux-saga/effects';

// API
import { api } from 'api';

// Types
import { SagaIterator } from '@redux-saga/types';
import {
    AgentInfoResponseType,
    FetchConteragentFormType,
} from 'redux/form/formTypes';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

export function* fetchConteragentFormWorker({
    payload: conteragentData,
}: {
    type: string;
    payload: FetchConteragentFormType;
}): SagaIterator {
    try {
        yield put(uiCombineActions.startFetchingAction());
        yield delay(0);

        const { inn, kpp } = conteragentData;
        const response = yield apply(api, api.contragents.fetch, [inn, kpp]);
        const { status, abonents, text }: AgentInfoResponseType = yield apply(
            response,
            response.json,
            [],
        );

        if (status !== 0) {
            yield put(
                uiCombineActions.addToastMessageAction({
                    status: 1,
                    text,
                }),
            );
        }

        if (response.status !== 200) {
            throw new Error(text);
        }

        yield put(
            formCombineActions.fillAgentInfoTableDataAction({
                ...abonents,
                inn,
                kpp,
            }),
        );
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(
                error,
                'fetchConteragentForm worker',
            ),
        );
    } finally {
        yield put(uiCombineActions.stopFetchingAction());
    }
}
