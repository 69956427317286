// Core
import React, { FC, memo } from 'react';

// Assets
import { Loader as LoaderSvg } from 'assets/images/svgr/loader';

// Types
import { LoaderType } from './loaderTypes';

// Style
import './Loader.scss';

const LoaderInner: FC<LoaderType> = ({ isFetching }) => (
    <div className="loader">{isFetching && <LoaderSvg />}</div>
);

export const Loader = memo(LoaderInner);
