import { createTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';

export const uiTheme = createTheme(
    {
        palette: {
            primary: {
                main: '#6746EB',
            },
        },
        typography: {
            fontFamily: [
                'Manrope',
                '-apple-system',
                'BlinkMacSystemFont',
                'Segoe UI',
                'Helvetica Neue',
                'Arial',
                'Noto Sans',
                'sans-serif',
            ].join(','),
        },
    },
    ruRU,
);
