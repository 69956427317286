// Core
import React, { FC, memo } from 'react';

// Third party libraries
import { Field } from 'react-final-form';

// Utils
import { getFieldLabel } from 'utils/constants';

// Types
import { SelectFieldTypes } from './selectFieldTypes';

// Components
import { SelectFieldAdapter } from './SelectFieldAdapter/SelectFieldAdapter';

// Style
import './SelectField.scss';

const SelectFieldInner: FC<SelectFieldTypes> = ({
    name,
    fieldType,
    selectedArr,
    disabled = false,
    readOnly = false,
    wsError,
    required = false,
    isLoadFromFile = false,
}) => (
    <div className="select-field">
        <Field
            name={`${name}.${fieldType}`}
            subscription={{
                touched: true,
                error: true,
                value: true,
            }}>
            {({ input, meta }) => (
                <SelectFieldAdapter
                    name={name}
                    fieldType={fieldType}
                    input={input}
                    label={getFieldLabel(fieldType)}
                    selectedArr={selectedArr}
                    meta={meta}
                    wsError={wsError}
                    required={required}
                    disabled={disabled}
                    readOnly={readOnly}
                    isLoadFromFile={isLoadFromFile}
                />
            )}
        </Field>
    </div>
);

export const SelectField = memo(SelectFieldInner);
