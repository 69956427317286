// Core
import React, { FC, useEffect, memo } from 'react';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Utils
import { formValidator } from 'utils/validator';
import { isEqual } from 'utils/isEqual';
import { convertDate } from 'utils/convertDate';

// Third party libreries
import { useForm, useFormState } from 'react-final-form';

// Components
import { AsideMain } from 'components/Aside/AsideMain/AsideMain';
import { StatusInfoType } from 'redux/form/formTypes';
import { StatusInfoTable } from 'components/Tables';
import { Loader } from 'components/Loader/Loader';
import { FindStatusInfo } from './FindStatusInfo/FindStatusInfo';

// Types
import { StatusInfoFormTypes } from './statusInfoFormTypes';

// Style
import './StatusInfoForm.scss';

const StatusInfoFormInner: FC<StatusInfoFormTypes> = ({
    formName,
    setIsBlockValid,
    statusState,
}) => {
    const formState = useFormState();
    const { mutators } = useForm();
    const { isFetching } = useSelector((state: State) => state.ui, isEqual);
    const { formErrors } = useSelector((state: State) => state.form, isEqual);
    const { id, ticket, tableData } = useSelector(
        (state: State) => state.form.statusInfo,
        isEqual,
    );

    useEffect(() => {
        if (formName === 'statusInfoForm' && formErrors[formName]?.statusInfo) {
            const errors: StatusInfoType | undefined =
                formErrors[formName]?.statusInfo;

            if (errors) {
                setIsBlockValid(
                    formValidator(
                        formName,
                        'statusInfo',
                        errors,
                        formState.values[formName]?.statusInfo,
                    ),
                );
            }
        }
    }, [formErrors[formName]]);

    useEffect(() => {
        if (statusState) {
            setTimeout(() => {
                mutators.setValue(`${formName}`, statusState);
            }, 0);

            setTimeout(() => {
                document.getElementById('formRoaming')?.dispatchEvent(
                    new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                    }),
                );
            }, 100);
        }
    }, [statusState]);

    return (
        <section data-testid="status-info-form" className="status-info-form">
            <div className="status-info-form__header">
                <div className="header__inputs-block">
                    <FindStatusInfo
                        formName={formName}
                        formPartName="statusInfo"
                        formPartState={formState.values[formName]?.statusInfo}
                    />
                </div>

                <AsideMain />
            </div>

            <div className="status-info-form__body">
                {tableData && ticket && (
                    <>
                        <div
                            data-testid="statement-info"
                            className="statement-info">
                            <div className="statement-info__idedo">
                                <div className="title">
                                    <span className="h--6">
                                        ИдЭДО отправителя:
                                    </span>
                                </div>

                                <div className="description">
                                    <span className="text-m">{id}</span>
                                </div>
                            </div>

                            <div className="statement-info__ticket">
                                <div className="title">
                                    <span className="h--6">
                                        Номер заявления:
                                    </span>
                                </div>

                                <div className="description">
                                    <span className="text-m">{ticket}</span>
                                </div>
                            </div>

                            <div className="statement-info__date">
                                <div className="title">
                                    <span className="h--6">Дата создания:</span>
                                </div>

                                <div className="description">
                                    <span className="text-m">
                                        {convertDate(tableData.created)}
                                    </span>
                                </div>
                            </div>

                            <div className="statement-info__operator">
                                <div className="title">
                                    <span className="h--6">
                                        Оператор получателя:
                                    </span>
                                </div>

                                <div className="description">
                                    <span className="text-m">
                                        {tableData.operator}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <StatusInfoTable
                            agentData={{ id, ticket }}
                            contacts={tableData}
                        />
                    </>
                )}

                <Loader isFetching={isFetching} />
            </div>
        </section>
    );
};

export const StatusInfoForm = memo(StatusInfoFormInner);
