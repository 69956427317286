// Third party libraries
import { produce } from 'immer';

// Action
import { formCombineActions } from 'redux/form/formActions';
import { authCombineActions } from 'redux/auth/authActions';
import { uiCombineActions } from 'redux/ui/uiActions';

// Types
import {
    FetchAbonentFormType,
    FetchOperatorFormType,
    MainObjectsArrType,
} from 'redux/form/formTypes';

// Utils
import { ASTRAL_ID } from 'utils/constants';
import React, { Dispatch } from 'react';

const definedAdornment = (
    form: string,
    part: string,
    userPrefix: string | undefined,
) => {
    if (
        (form === 'clientForm' && part === 'sender') ||
        (form === 'operatorForm' && part === 'receiver')
    )
        return ASTRAL_ID;

    if (form === 'operatorForm' && part === 'sender' && userPrefix)
        return userPrefix;

    return '';
};

const сomplianceСheck = (
    form: string,
    part: string,
    arr: MainObjectsArrType,
    userPrefix?: string,
) => {
    const newArr = produce(arr, (draftState) => {
        draftState.map((item) => {
            if (item.id) {
                const currPrefix = item.id.slice(0, 3);
                const prefix = definedAdornment(form, part, userPrefix);

                if (prefix !== currPrefix) {
                    item.id = `${prefix}${item.id}`;
                }
            }

            return item.id;
        });
    });

    return newArr;
};

const counteAgents = (arr: any, formName: string, formPartName: string) =>
    arr?.[formName]?.[formPartName]?.[`${formPartName}s`]?.length;

export const submiting = (
    values: any,
    formName: string,
    isSendersValid: 0 | 1,
    isReceiversValid: 0 | 1,
    isStatusValid: 0 | 1,
    isAgentInfoValid: 0 | 1,
    isLoginValid: 0 | 1,
    dispatch: Dispatch<any>,
    userPrefix?: string,
) => {
    if (formName === 'clientForm' || formName === 'operatorForm') {
        if (!isSendersValid && !isReceiversValid) {
            if (formName === 'clientForm') {
                const abonentData: FetchAbonentFormType = {
                    operator: values[formName]?.receiver?.operator,
                    sender: сomplianceСheck(
                        formName,
                        'sender',
                        values[formName]?.sender?.senders,
                        userPrefix,
                    ),
                    receiver: values[formName]?.receiver?.receivers,
                    supplier:
                        values[formName]?.receiver.supplier === 'Не указано'
                            ? undefined
                            : values[formName]?.receiver.supplier,
                    agreement: values[formName]?.receiver?.agreement,
                    ticket: values[formName]?.sender?.ticket
                        ? values[formName]?.sender?.ticket
                        : undefined,
                };

                dispatch(
                    formCombineActions.fetchAbonentFormAction(abonentData),
                );
            }

            if (formName === 'operatorForm') {
                const operatorData: FetchOperatorFormType = {
                    request_id: values[formName]?.application_identifier,
                    senders: сomplianceСheck(
                        formName,
                        'sender',
                        values[formName]?.sender?.senders,
                        userPrefix,
                    ),
                    receivers: сomplianceСheck(
                        formName,
                        'receiver',
                        values[formName]?.receiver?.receivers,
                    ),
                };

                dispatch(
                    formCombineActions.fetchOperatorFormAction(operatorData),
                );
            }
        }
    }

    if (formName === 'statusInfoForm') {
        if (!isStatusValid) {
            const statusInfoData = { ...values[formName].statusInfo };
            const prefix = values[formName].statusInfo?.id.slice(0, 3);

            if (prefix && prefix !== ASTRAL_ID) {
                statusInfoData.id = `${ASTRAL_ID}${statusInfoData.id}`;
            }

            statusInfoData.prefix = ASTRAL_ID;

            dispatch(formCombineActions.fetchStatusFormAction(statusInfoData));
        }
    }

    if (formName === 'agentInfoForm') {
        if (!isAgentInfoValid) {
            dispatch(
                formCombineActions.fetchConteragentFormAction(
                    values[formName].agentInfo,
                ),
            );
        }
    }

    if (formName === 'loginForm') {
        if (!isLoginValid) {
            dispatch(authCombineActions.loginAction(values[formName].login));
        }
    }
};

export const validating = (
    formName: string,
    isSendersValid: 0 | 1,
    isReceiversValid: 0 | 1,
    isStatusValid: 0 | 1,
    isAgentInfoValid: 0 | 1,
    isLoginValid: 0 | 1,
) => {
    if (formName === 'clientForm' || formName === 'operatorForm') {
        return !isSendersValid && !isReceiversValid
            ? undefined
            : { key: 'error' };
    }

    if (formName === 'statusInfoForm') {
        return !isStatusValid ? undefined : { key: 'error' };
    }

    if (formName === 'agentInfoForm') {
        return !isAgentInfoValid ? undefined : { key: 'error' };
    }

    if (formName === 'loginForm') {
        return !isLoginValid ? undefined : { key: 'error' };
    }

    return undefined;
};

export const onSubmitedEvent = (
    formName: string,
    event: React.SyntheticEvent,
    handleSubmit: (event: React.SyntheticEvent) => void,
    getState: () => any,
    dispatch: Dispatch<any>,
) => {
    event.preventDefault();

    if (!getState().valid) {
        dispatch(
            uiCombineActions.addToastMessageAction({
                status: 1,
                text: 'Все поля, выделенные красным - обязательные и должны быть заполнены валидными данными',
            }),
        );
    }

    const sendersAgentsCounter = counteAgents(
        getState().values,
        formName,
        'sender',
    );

    const receiversAgentsCounter = counteAgents(
        getState().values,
        formName,
        'receiver',
    );

    if (sendersAgentsCounter > 100 || receiversAgentsCounter > 100) {
        dispatch(
            uiCombineActions.addToastMessageAction({
                status: 1,
                text: 'Количество контрагентов не должно быть больше 100 шт.',
            }),
        );
    } else if (sendersAgentsCounter > 1 && receiversAgentsCounter > 1) {
        dispatch(
            uiCombineActions.addToastMessageAction({
                status: 1,
                text: 'В заявке может быть указан 1 отправитель и не более 100 контрагентов или не более 100 отправителей и 1 контрагент!',
            }),
        );
    } else {
        handleSubmit(event);
    }
};
