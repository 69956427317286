export const findAgentWithError = (
    arr: any[] | undefined,
    val: string | number,
) => {
    const indexes = [];
    if (arr) {
        let i;

        for (i = 0; i < arr.length; i++) {
            const even = (element: any) => element !== val;

            if (Object.values(arr[i]).some(even)) {
                indexes.push(i);
            }
        }
    }

    return indexes;
};
