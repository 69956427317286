// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';
import { submiting, validating, onSubmitedEvent } from 'utils/onSubmit';

// Third party libraries
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { initialFormState } from 'redux/form/formReducer';

// Action
import { formCombineActions } from 'redux/form/formActions';

// Types
import { FinalFormTypes } from './finalFormTypes';

// Components
import { ClientForm } from './ClientForm/ClientForm';
import { OperatorForm } from './OperatorForm/OperatorForm';
import { StatusInfoForm } from './StatusInfoForm/StatusInfoForm';
import { AgentInfoForm } from './AgentInfoForm/AgentInfoForm';
import { LoginForm } from './LoginForm/LoginForm';

// Style
import './FinalForm.scss';

const FinalFormInner: FC<FinalFormTypes> = ({
    formName,
    statusState,
    mainBlockHeight,
    hasTheFormChange,
    setHasTheFormChange,
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: State) => state.auth, isEqual);
    const [isSendersValid, setIsSendersValid] = useState<0 | 1>(1);
    const [isReceiversValid, setIsReceiversValid] = useState<0 | 1>(1);
    const [isStatusValid, setIsStatusValid] = useState<0 | 1>(1);
    const [isAgentInfoValid, setIsAgentInfoValid] = useState<0 | 1>(1);
    const [isLoginValid, setIsLoginValid] = useState<0 | 1>(1);

    useEffect(() => {
        dispatch(formCombineActions.clearWsErrorAction());
        dispatch(formCombineActions.clearFieldsErrorAction());
        dispatch(formCombineActions.clearInitialFormStateAction());
        dispatch(formCombineActions.clearStatusInfoTableDataAction());
        dispatch(formCombineActions.clearAgentInfoTableDataAction());
        dispatch(formCombineActions.fetchOperatorsAction());
        dispatch(formCombineActions.loadFromFileOffAction());
    }, [dispatch]);

    const onSubmit = (values: any) => {
        submiting(
            values,
            formName,
            isSendersValid,
            isReceiversValid,
            isStatusValid,
            isAgentInfoValid,
            isLoginValid,
            dispatch,
            user?.prefix,
        );
    };

    const validate = () =>
        validating(
            formName,
            isSendersValid,
            isReceiversValid,
            isStatusValid,
            isAgentInfoValid,
            isLoginValid,
        );

    return (
        <div className="final-form">
            <Form
                initialValues={initialFormState}
                onSubmit={onSubmit}
                validate={validate}
                subscription={{ submitting: true }}
                mutators={{
                    ...arrayMutators,
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    setFieldTouched,
                }}
                render={({
                    handleSubmit,
                    form: {
                        mutators: { push },
                        getState,
                        restart,
                    },
                }) => (
                    <form
                        id="formRoaming"
                        className="form-roaming"
                        onSubmit={(event) =>
                            onSubmitedEvent(
                                formName,
                                event,
                                handleSubmit,
                                getState,
                                dispatch,
                            )
                        }>
                        {formName === 'clientForm' && (
                            <ClientForm
                                formName={formName}
                                isSendersValid={isSendersValid}
                                isReceiversValid={isReceiversValid}
                                hasTheFormChange={hasTheFormChange}
                                setIsReceiversValid={setIsReceiversValid}
                                setHasTheFormChange={setHasTheFormChange}
                                setIsSendersValid={setIsSendersValid}
                                restart={restart}
                                push={push}
                            />
                        )}

                        {formName === 'operatorForm' && (
                            <OperatorForm
                                formName={formName}
                                isSendersValid={isSendersValid}
                                isReceiversValid={isReceiversValid}
                                hasTheFormChange={hasTheFormChange}
                                setIsReceiversValid={setIsReceiversValid}
                                setHasTheFormChange={setHasTheFormChange}
                                setIsSendersValid={setIsSendersValid}
                                restart={restart}
                                push={push}
                            />
                        )}

                        {formName === 'statusInfoForm' && (
                            <StatusInfoForm
                                formName={formName}
                                statusState={statusState}
                                setIsBlockValid={setIsStatusValid}
                            />
                        )}

                        {formName === 'agentInfoForm' && (
                            <AgentInfoForm
                                formName={formName}
                                setIsBlockValid={setIsAgentInfoValid}
                            />
                        )}

                        {formName === 'loginForm' && (
                            <LoginForm
                                formName={formName}
                                mainBlockHeight={mainBlockHeight}
                                setIsBlockValid={setIsLoginValid}
                            />
                        )}
                    </form>
                )}
            />
        </div>
    );
};

export const FinalForm = memo(FinalFormInner);
