// Core
import React, { FC, memo } from 'react';
// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { ApplyBtn } from './ApplyBtn/ApplyBtn';

// Types
import { AsideMainTypes } from './asideMainTypes';

// Style
import './AsideMain.scss';

const AsideMainInner: FC<AsideMainTypes> = ({
    children,
    secondBtn,
    steperHeight,
    secondBtnFunc,
}) => {
    const { isFetching } = useSelector((state: State) => state.ui, isEqual);

    return (
        <aside className="aside-main">
            <div
                className="aside-main__wrapper"
                style={{ top: `${steperHeight}px` }}>
                <ApplyBtn
                    isFetching={isFetching}
                    secondBtn={secondBtn}
                    secondBtnFunc={secondBtnFunc}
                />

                {children}
            </div>
        </aside>
    );
};

export const AsideMain = memo(AsideMainInner);
