// Core
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

// Third parti libraries
import Button from '@material-ui/core/Button';

// Assets
import { ArrowSimple } from 'assets/images/svgr/arrowSimple';
import { Error404 } from 'assets/images/svgr/error-baner-404';
import { ErrorRightTop } from 'assets/images/svgr/error-right-top';
import { ErrorRightBottom } from 'assets/images/svgr/error-right-bottom';

// Style
import './ErrorPage.scss';

const ErrorPageInner = () => {
    const history = useHistory();

    const routeChange = () => {
        const path = `/`;
        history.push(path);
    };

    return (
        <div className="error-page">
            <div className="error-page-info">
                <div className="error-page-info__header">
                    <span className="h--2">Cтраница не найдена</span>
                </div>

                <div className="error-page-info__body">
                    <span className="text-m">
                        Может, ее похитили инопланетные контрагенты
                    </span>

                    <span className="text-m">
                        Лучше вернитесь на главную или обратитесь в
                        <a href="https://astral.ru/support/"> тех. поддержку</a>
                    </span>
                </div>

                <div className="error-page-info__footer">
                    <Button
                        type="button"
                        variant="contained"
                        className="btn-error"
                        endIcon={<ArrowSimple />}
                        onClick={routeChange}>
                        Вернуться на главную
                    </Button>
                </div>
            </div>

            <div className="error-page-baner">
                <div className="error-page-baner__left">
                    <Error404 />
                </div>

                <div className="error-page-baner__right">
                    <ErrorRightTop />

                    <ErrorRightBottom />
                </div>
            </div>
        </div>
    );
};

export const ErrorPage = memo(ErrorPageInner);
