import React, { memo } from 'react';

const LogoInner = () => (
    <svg viewBox="0 0 29 32">
        <path
            d="M1.78426 8.0549H16.799C17.2703 8.0549 17.708 8.25689 18.0446 8.55988C18.3813 8.89653 18.5496 9.33418 18.5496 9.8055V30.1393C18.5496 30.375 18.5159 30.6107 18.4149 30.8126C18.3139 31.0146 18.1793 31.2166 18.0446 31.385C17.8763 31.5533 17.6743 31.6879 17.4723 31.7553C17.2703 31.8563 17.0346 31.8899 16.799 31.8899H10.7729C9.45995 31.7553 0 25.1569 0 23.8439V9.83916C0 9.36785 0.201992 8.9302 0.50498 8.59355C0.841633 8.25689 1.31295 8.0549 1.78426 8.0549Z"
            fill="white"
        />
        <path
            d="M8.41638 24.8876C8.82037 24.8203 9.99865 25.0896 9.99865 25.0896C9.99865 25.0896 11.4463 25.359 11.4463 26.3016C11.4463 27.2779 11.4463 31.9237 11.4463 31.9237C11.1096 31.9237 10.571 31.89 10.268 31.7554C9.96499 31.6544 9.69566 31.486 9.49367 31.284C8.71937 30.5771 8.41638 24.8876 8.41638 24.8876Z"
            fill="#7155E4"
        />
        <path
            d="M0 23.945C0 23.945 0.471314 24.854 1.31295 24.9213C2.15458 24.955 7.70936 24.9213 8.38266 24.9213C9.05597 24.9213 9.69561 25.2243 9.69561 25.8976C9.72927 26.5373 9.69561 30.9138 9.69561 31.1831C9.69561 31.4524 10.4699 31.9574 11.0086 31.991C11.1432 31.991 10.0996 32.1257 8.78665 31.3514C7.54103 30.5098 1.04362 25.5273 0.673306 25.2243C0.269322 24.8877 0.100996 24.3827 0 23.945Z"
            fill="#AB99F4"
        />
        <path
            opacity="0.5"
            d="M26.7303 23.9453H11.6819C11.2106 23.9453 10.7729 23.7433 10.4363 23.4404C10.0996 23.1037 9.93127 22.6661 9.93127 22.1947V1.82723C9.93127 1.59157 9.96494 1.35592 10.0659 1.15392C10.1669 0.951932 10.3016 0.74994 10.4363 0.581614C10.6046 0.413287 10.8066 0.278626 11.0086 0.211295C11.2442 0.110299 11.4462 0.0429688 11.6819 0.0429688H17.708C19.0209 0.17763 28.4809 6.77603 28.4809 8.08898V22.1274C28.4809 22.5987 28.2789 23.0364 27.9759 23.373C27.6392 23.7433 27.2016 23.9453 26.7303 23.9453Z"
            fill="#F0EDFD"
        />
        <path
            opacity="0.5"
            d="M20.0983 7.07887C19.6943 7.1462 18.516 6.87687 18.516 6.87687C18.516 6.87687 17.0684 6.60755 17.0684 5.66492C17.0684 4.68863 17.0684 0.0428162 17.0684 0.0428162C17.4051 0.0428162 17.9437 0.0764815 18.2467 0.211143C18.5497 0.312139 18.819 0.480465 19.021 0.682457C19.7616 1.42309 20.0983 7.07887 20.0983 7.07887Z"
            fill="#4E30C7"
        />
        <path
            d="M28.4809 8.05498C28.4809 8.05498 28.0096 7.14601 27.168 7.07868C26.3263 7.04502 20.7716 7.07868 20.0982 7.07868C19.4249 7.07868 18.7853 6.77569 18.7853 6.10239C18.7516 5.46275 18.7853 1.08626 18.7853 0.816935C18.7853 0.547613 18.011 0.0426334 17.4724 0.00896807C17.3377 0.00896807 18.3813 -0.125693 19.6943 0.648609C20.9399 1.49024 27.4373 6.47271 27.8413 6.77569C28.2116 7.11235 28.3799 7.61733 28.4809 8.05498Z"
            fill="#F0EDFD"
        />
    </svg>
);

export const Logo = memo(LogoInner);
