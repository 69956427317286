// Core
import React, { FC, useEffect, useState, useCallback, memo } from 'react';

// Store
import { State } from 'redux/store';
import { useSelector, useDispatch } from 'react-redux';

// Third party libreries
import { useForm, useFormState } from 'react-final-form';
import { merge } from 'lodash';
import { produce } from 'immer';

// Utils
import { isEqual } from 'utils/isEqual';
import { getBlockNameTitle } from 'utils/constants';
import { usePrevious } from 'hooks/usePrevious';

// Actions
import { formCombineActions } from 'redux/form/formActions';

// Components
import { Steper } from 'components/Steper/Steper';
import { COFormType, FormStateType } from 'redux/form/formTypes';
import { initialFormState as startState } from 'redux/form/formReducer';
import { BlockPartContent } from '../BlockPartContent/BlockPartContent';

// Types
import { ClientFormTypes } from './clientFormTypes';

// Style
import './ClientForm.scss';

const ClientFormInner: FC<ClientFormTypes> = ({
    formName,
    isSendersValid,
    isReceiversValid,
    hasTheFormChange,
    setIsSendersValid,
    setIsReceiversValid,
    setHasTheFormChange,
    restart,
    push,
}) => {
    const dispatch = useDispatch();
    const formState = useFormState();
    const { mutators } = useForm();
    const { formErrors, initialFormState, loadFromFile, ticket, formCleaner } =
        useSelector((state: State) => state.form, isEqual);
    const { windowSize } = useSelector((state: State) => state.ui, isEqual);
    const [steperHeight, setSteperHeight] = useState(0);
    const prevLoadFromFile = usePrevious(loadFromFile);
    const prevFormCleaner = usePrevious(formCleaner);

    const restartForm = (state?: any) => {
        restart(state);
        dispatch(formCombineActions.loadFromFileOffAction());
    };

    useEffect(() => {
        if (
            (!prevLoadFromFile && loadFromFile) ||
            (!prevFormCleaner && formCleaner)
        ) {
            if (ticket) {
                const state = produce(
                    startState,
                    (draftState: FormStateType) => {
                        const sender = draftState.clientForm?.sender;
                        const receiver = draftState.clientForm?.receiver;

                        draftState.clientForm = {
                            sender: {
                                ...sender,
                                ticket,
                                isRolluped:
                                    draftState.clientForm?.sender?.isRolluped ||
                                    false,
                            },
                            receiver,
                        };

                        return draftState;
                    },
                );

                restart(state);
            } else {
                restart();
            }
        }
    }, [loadFromFile, formCleaner]);

    useEffect(() => {
        const state = produce(initialFormState, (draftState) => {
            const sender = draftState.clientForm?.sender;
            const receiver = draftState.clientForm?.receiver;

            if (sender?.senders && sender.senders.length > 1) {
                sender.senders = [sender.senders[1]];
                draftState.clientForm = { sender, receiver };
            }

            if (draftState.clientForm?.sender && ticket) {
                draftState.clientForm.sender.ticket = ticket;
            }

            if (draftState.clientForm?.receiver?.supplier === 'Не указано') {
                draftState.clientForm.receiver.supplier = undefined;
            }

            return draftState;
        });

        const newState = produce(
            formState.values[formName],
            (draftState: COFormType) => merge(draftState, state[formName]),
        );

        mutators.setValue(`${formName}`, newState);
    }, [initialFormState]);

    useEffect(() => {
        const fieldsState = formState.visited;

        if (
            setHasTheFormChange &&
            fieldsState &&
            Object.keys(fieldsState).length
        ) {
            const hasStateChange =
                Object.values(fieldsState).reduce(
                    (sum, curr) => (curr ? sum + 1 : sum),
                    0,
                ) > 0;

            setHasTheFormChange(hasStateChange);
        }
    }, [formState.visited, setHasTheFormChange]);

    const definedSteperHeight = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                setSteperHeight(node.offsetHeight);
            }
        },
        [windowSize],
    );

    return (
        <section data-testid="client-form" className="client-form">
            <Steper
                steps={[
                    {
                        stepName: getBlockNameTitle(formName, 'sender'),
                        stepStatus: isSendersValid,
                    },
                    {
                        stepName: getBlockNameTitle(formName, 'receiver'),
                        stepStatus: isReceiversValid,
                    },
                ]}
                finishName="Готово к отправке"
                definedSteperHeight={definedSteperHeight}
            />

            <BlockPartContent
                formName={formName}
                ticketNumber={ticket}
                steperHeight={steperHeight}
                isSendersValid={isSendersValid}
                hasTheFormChange={hasTheFormChange}
                isReceiversValid={isReceiversValid}
                formErrors={formErrors?.[formName]}
                formState={formState.values?.[formName]}
                setIsReceiversValid={setIsReceiversValid}
                setIsSendersValid={setIsSendersValid}
                restart={restartForm}
                push={push}
            />
        </section>
    );
};

export const ClientForm = memo(ClientFormInner);
