// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libraries
import { Tooltip } from '@material-ui/core';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarExportProps,
} from '@material-ui/data-grid';
import { ThemeProvider } from '@material-ui/core/styles';

// Theme
import { uiTheme } from 'utils/theme/uiTheme';

// Types
import { InfoResponseAgentType } from 'redux/form/formTypes';
import { AgentInfoTableType } from './agentInfoTableTypes';

// Style
import '../TablesStyles.scss';

const CustomToolbar = (
    props: GridToolbarExportProps,
    inn: string,
    kpp?: string,
) => {
    const name = kpp
        ? `EDO_Roaming_Agent_info_KPP-${kpp}_INN-${inn}`
        : `EDO_Roaming_Agent_info_INN-${inn}`;

    return (
        <GridToolbarContainer>
            {/* @ts-ignore */}
            <GridToolbarExport
                csvOptions={{
                    fileName: name,
                    utf8WithBom: true,
                }}
                {...props}
            />
        </GridToolbarContainer>
    );
};

const AgentInfoTableInner: FC<AgentInfoTableType> = ({
    agentData,
    contacts,
}) => {
    const initialState = {
        columns: [],
        rows: [],
        dataSet: 'Commodity',
        rowLength: 100,
        maxColumns: 7,
    };

    const [data, setData] = useState(initialState);
    const [pageSize, setPageSize] = useState<number>(25);

    useEffect(() => {
        if (contacts && contacts.length > 0) {
            const columns = [
                {
                    field: 'id',
                    headerName: '№',
                    width: 65,
                    headerAlign: 'center',
                    align: 'center',
                },
                {
                    field: 'agent_name',
                    headerName: 'Контрагент',
                    flex: 1,
                    renderCell: (params: any) => (
                        <Tooltip title={params.value || ''}>
                            <div className="status-info-table__cell">
                                <span>{params.value}</span>
                            </div>
                        </Tooltip>
                    ),
                },
                {
                    field: 'inn_kpp',
                    headerName: 'ИНН / КПП',
                    width: 120,
                    renderCell: (params: any) => (
                        <div className="status-info-table__cell">
                            <span>{params.value.split('/')[0] || ''}</span>
                            <span>
                                {params.value.split('/')[1]
                                    ? `${params.value.split('/')[1]}`
                                    : ''}
                            </span>
                        </div>
                    ),
                },
                {
                    field: 'idEdo',
                    headerName: 'ИдЭДО',
                    flex: 1,
                    renderCell: (params: any) => (
                        <Tooltip title={params.value || ''}>
                            <div className="status-info-table__cell">
                                <span>{params.value}</span>
                            </div>
                        </Tooltip>
                    ),
                },
            ];

            const rows = contacts.map(
                (currAgent: InfoResponseAgentType, id: number) => ({
                    id: id + 1,
                    agent_name:
                        currAgent.name ||
                        `${currAgent.lastname} ${currAgent.firstname} ${currAgent.patronymic}`,
                    inn_kpp: currAgent.kpp
                        ? `${currAgent.inn}/${currAgent.kpp}`
                        : `${currAgent.inn}`,
                    idEdo: currAgent.id,
                }),
            );

            if (data && columns && rows) {
                // @ts-ignore
                setData({ ...data, columns, rows });
            }
        }
    }, [contacts]);

    return (
        <div data-testid="agent-info-table" className="agent-info-table">
            <ThemeProvider theme={uiTheme}>
                <DataGrid
                    {...data}
                    autoHeight
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    localeText={{
                        columnHeaderSortIconLabel: 'Сортировка',
                        toolbarExport: 'Скачать',
                        toolbarExportCSV: 'Скачать CSV таблицу',
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 55, 100]}
                    pagination
                    components={{
                        Toolbar: (dataInfo) =>
                            CustomToolbar(
                                dataInfo,
                                agentData.inn,
                                agentData.kpp,
                            ),
                    }}
                />
            </ThemeProvider>
        </div>
    );
};

export const AgentInfoTable = memo(AgentInfoTableInner);
