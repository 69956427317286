// Core
import React, { FC, memo } from 'react';

// Assets
import { InfoCircle } from 'assets/images/svgr/info-circle';

// Types
import { MessageInfoType } from './messageInfoTypes';

// Style
import './MessageInfo.scss';

const MessageInfoInner: FC<MessageInfoType> = ({
    isRender,
    title,
    message,
}) => (
    <>
        {isRender && (
            <div className="message-info">
                {title && <span className="h--5">{title}</span>}

                <span className="p--sm">{message}</span>

                <div className="bg-img">
                    <InfoCircle />
                </div>
            </div>
        )}
    </>
);

export const MessageInfo = memo(MessageInfoInner);
