// Third party libraries
import { produce } from 'immer';
import { cloneDeep, merge } from 'lodash';

// Types
import {
    FormState,
    FormActionTypes,
    formTypes,
    FormNameType,
    FormPartNameType,
    FormBlockNameType,
    UpdateFromInnType,
    MainObjectsArrType,
    MainObjectType,
    UpdateFieldsErrorType,
} from './formTypes';

export const initialFormState = {
    clientForm: {
        sender: {
            senders: [{}],
            ticket: null,
        },
        receiver: {
            receivers: [{}],
            supplier: 'Не указано',
        },
    },
    operatorForm: {
        sender: {
            senders: [{}],
        },
        receiver: {
            receivers: [{}],
        },
    },
};

export const initialAgentInfo = {
    is_abonent: false,
    abonents: [],
    inn: '',
    kpp: '',
};

export const initialStatusInfo = {
    id: '',
    ticket: null,
    tableData: null,
};

const initialState: FormState = {
    formCleaner: false,
    ticket: null,
    loadFromFile: false,
    initialFormState: { ...initialFormState },
    formErrors: {},
    wsErrors: {
        contractor_type_1: [],
        contractor_type_2: [],
        contractor_type_3: [],
        contractor_type_4: [],
    },
    operators: [],
    suppliers: [],
    statusInfo: { ...initialStatusInfo },
    agentInfo: { ...initialAgentInfo },
    agentsCounters: {
        senders: 0,
        receivers: 0,
        dublicates: 0,
    },
    connectedId: [],
    notConnectedId: [],
    isFormInSubmiting: false,
};

export const formReducer = (
    state = initialState,
    action: FormActionTypes,
): FormState => {
    switch (action.type) {
        case formTypes.SET_FORM_CLEANER_STATE:
            return produce(state, (draftState) => {
                draftState.formCleaner = action.payload;
            });

        case formTypes.SET_TICKET_NUMBER:
            return produce(state, (draftState) => {
                draftState.ticket = action.payload;
            });

        case formTypes.LOAD_FROM_FILE_ON:
            return produce(state, (draftState) => {
                draftState.loadFromFile = true;
            });

        case formTypes.LOAD_FROM_FILE_OFF:
            return produce(state, (draftState) => {
                draftState.loadFromFile = false;
            });

        case formTypes.WS_ERRORS:
            return produce(state, (draftState) => {
                const contractorType = action.payload.contractor_type;
                const wsErrorId = action.payload.fid;
                const wsError = action.payload;
                let contractorArr = null;

                if (contractorType === 1)
                    contractorArr = draftState.wsErrors.contractor_type_1;
                if (contractorType === 2)
                    contractorArr = draftState.wsErrors.contractor_type_2;
                if (contractorType === 3)
                    contractorArr = draftState.wsErrors.contractor_type_3;
                if (contractorType === 4)
                    contractorArr = draftState.wsErrors.contractor_type_4;

                if (contractorArr) {
                    const currId = contractorArr.findIndex(
                        (elem) => elem.fid === wsErrorId,
                    );

                    if (currId >= 0) {
                        contractorArr[currId] = wsError;
                    } else {
                        contractorArr.splice(wsErrorId, 0, wsError);
                    }
                }
            });

        case formTypes.CLEAR_WS_ERRORS:
            return produce(state, (draftState) => {
                draftState.wsErrors = {
                    contractor_type_1: [],
                    contractor_type_2: [],
                    contractor_type_3: [],
                    contractor_type_4: [],
                };
            });

        case formTypes.DELETE_WS_ERRORS:
            return produce(state, (draftState) => {
                const {
                    agentId,
                    contractor,
                }: { agentId: number; contractor: number } = action.payload;

                const deleteErrorMoveIndex = (
                    contractorTypeArr: MainObjectsArrType,
                ) => {
                    const oldArrError = [...contractorTypeArr];

                    oldArrError.splice(agentId, 1);

                    const newArrError = oldArrError.map((elem, index) => ({
                        ...elem,
                        fid: index,
                    }));

                    return newArrError;
                };

                if (contractor === 1) {
                    draftState.wsErrors.contractor_type_1 =
                        deleteErrorMoveIndex(
                            draftState.wsErrors.contractor_type_1,
                        );
                }
                if (contractor === 2) {
                    draftState.wsErrors.contractor_type_2 =
                        deleteErrorMoveIndex(
                            draftState.wsErrors.contractor_type_2,
                        );
                }
                if (contractor === 3) {
                    draftState.wsErrors.contractor_type_3 =
                        deleteErrorMoveIndex(
                            draftState.wsErrors.contractor_type_3,
                        );
                }
                if (contractor === 4) {
                    draftState.wsErrors.contractor_type_4 =
                        deleteErrorMoveIndex(
                            draftState.wsErrors.contractor_type_4,
                        );
                }
            });

        case formTypes.ADD_FIELD_ERROR:
            return produce(state, (draftState) => {
                const oldError = cloneDeep(state.formErrors);

                const {
                    formName,
                    formPart,
                    formBlock,
                    blockId,
                    fieldType,
                    error,
                } = action.payload;

                let newErrorArr: any = {};

                if (formBlock === 'senders' || formBlock === 'receivers') {
                    newErrorArr = {
                        [formName]: { [formPart]: { [formBlock]: [] } },
                    };
                    newErrorArr[formName][formPart][formBlock][blockId] = {
                        [fieldType]: {},
                    };
                    newErrorArr[formName][formPart][formBlock][blockId][
                        fieldType
                    ] = error;

                    draftState.formErrors = { ...merge(oldError, newErrorArr) };
                }

                if (
                    ((formName === 'statusInfoForm' ||
                        formName === 'agentInfoForm' ||
                        formName === 'loginForm') &&
                        (formPart === 'statusInfo' ||
                            formPart === 'agentInfo' ||
                            formPart === 'login') &&
                        fieldType) ||
                    fieldType === 'ticket'
                ) {
                    newErrorArr = {
                        [formName]: {
                            [formPart]: { [fieldType]: error },
                        },
                    };

                    draftState.formErrors = { ...merge(oldError, newErrorArr) };
                }
            });

        case formTypes.UPDATE_FIELDS_ERROR:
            return produce(state, (draftState) => {
                const { contractor, errorsArr }: UpdateFieldsErrorType =
                    action.payload;

                merge(draftState.wsErrors[contractor], errorsArr);
            });

        case formTypes.DELETE_FIELDS_ERROR:
            return produce(state, (draftState) => {
                const {
                    formName,
                    formPartName,
                    formBlockName,
                    blockId,
                }: {
                    formName: FormNameType;
                    formPartName: FormPartNameType;
                    formBlockName: FormBlockNameType;
                    blockId: number;
                } = action.payload;

                if (formName === 'clientForm' || formName === 'operatorForm') {
                    if (
                        formPartName === 'sender' &&
                        formBlockName === 'senders'
                    ) {
                        draftState.formErrors?.[formName]?.[formPartName]?.[
                            formBlockName
                        ]?.splice(blockId, 1);
                    }

                    if (
                        formPartName === 'receiver' &&
                        formBlockName === 'receivers'
                    ) {
                        draftState.formErrors?.[formName]?.[formPartName]?.[
                            formBlockName
                        ]?.splice(blockId, 1);
                    }
                }
            });

        case formTypes.CLEAR_FIELDS_ERROR:
            return produce(state, (draftState) => {
                draftState.formErrors = {};
            });

        case formTypes.FILL_OPERATORS:
            return produce(state, (draftState) => {
                draftState.operators = action.payload;
            });

        case formTypes.FILL_SUPPLIERS:
            return produce(state, (draftState) => {
                draftState.suppliers = action.payload
                    .map((item: string) => (item === '' ? 'Не указано' : item))
                    .sort();
            });

        case formTypes.FILL_CLIENT_FORM_SENDER:
            return produce(state, (draftState) => {
                const newSenders = [];
                const { agent, id }: { agent: MainObjectType; id: number } =
                    action.payload;

                newSenders[id] = {
                    ...agent,
                    autoLoadInn: true,
                    autoLoadId: true,
                };

                draftState.initialFormState.clientForm = {
                    sender: {
                        senders: [...newSenders],
                        isRolluped:
                            draftState.initialFormState.clientForm?.sender
                                ?.isRolluped || false,
                    },
                };
            });

        case formTypes.FILL_CLIENT_FORM_RECEIVER:
            return produce(state, (draftState) => {
                const newReceivers = [];
                const { agent, id }: { agent: MainObjectType; id: number } =
                    action.payload;

                newReceivers[id] = {
                    ...agent,
                    autoLoadInn: true,
                    autoLoadId: true,
                };

                draftState.initialFormState.clientForm = {
                    receiver: {
                        receivers: [...newReceivers],
                        isRolluped:
                            draftState.initialFormState.clientForm?.sender
                                ?.isRolluped || false,
                    },
                };
            });

        case formTypes.FILL_CLIENT_FORM_OPERATOR:
            return produce(state, (draftState) => {
                draftState.initialFormState.clientForm = {
                    ...draftState.initialFormState.clientForm,
                    receiver: {
                        operator: action.payload,
                        isRolluped:
                            draftState.initialFormState.clientForm?.sender
                                ?.isRolluped || false,
                    },
                };
            });

        case formTypes.FILL_CLIENT_FORM_SUPPLIER:
            return produce(state, (draftState) => {
                draftState.initialFormState.clientForm = {
                    ...draftState.initialFormState.clientForm,
                    receiver: {
                        supplier: action.payload,
                        isRolluped:
                            draftState.initialFormState.clientForm?.sender
                                ?.isRolluped || false,
                    },
                };
            });

        case formTypes.FILL_OPERATOR_FORM_SENDER:
            return produce(state, (draftState) => {
                const newSenders = [];
                const { agent, id }: { agent: MainObjectType; id: number } =
                    action.payload;

                newSenders[id] = {
                    ...agent,
                    autoLoadInn: true,
                    autoLoadId: true,
                    isRolluped: false,
                };

                draftState.initialFormState.operatorForm = {
                    sender: {
                        senders: [...newSenders],
                        isRolluped:
                            draftState.initialFormState.clientForm?.sender
                                ?.isRolluped || false,
                    },
                };
            });

        case formTypes.FILL_OPERATOR_FORM_RECEIVER:
            return produce(state, (draftState) => {
                const newReceivers = [];
                const { agent, id }: { agent: MainObjectType; id: number } =
                    action.payload;

                newReceivers[id] = {
                    ...agent,
                    autoLoadInn: true,
                    autoLoadId: true,
                    isRolluped: false,
                };

                draftState.initialFormState.operatorForm = {
                    receiver: {
                        receivers: [...newReceivers],
                        isRolluped:
                            draftState.initialFormState.clientForm?.sender
                                ?.isRolluped || false,
                    },
                };
            });

        case formTypes.CLEAR_INITIAL_FORM_STATE:
            return produce(state, (draftState) => {
                draftState.initialFormState = initialState.initialFormState;
            });

        case formTypes.UPDATE_AGENT_DATA_FROM_INN:
            return produce(state, (draftState) => {
                const { name, value }: UpdateFromInnType = action.payload;

                const [formName, formPartName, formBlockName, blockId] = name
                    .replace(/\[/g, '.')
                    .replace(/\]/g, '')
                    .split('.');

                let newStateArr: any = {};

                if (
                    (formName === 'clientForm' ||
                        formName === 'operatorForm') &&
                    (formPartName === 'sender' ||
                        formPartName === 'receiver') &&
                    (formBlockName === 'senders' ||
                        formBlockName === 'receivers')
                ) {
                    newStateArr = {
                        [formName]: { [formPartName]: { [formBlockName]: [] } },
                    };
                    newStateArr[formName][formPartName][formBlockName][
                        blockId
                    ] = {
                        ...newStateArr[formName][formPartName][formBlockName][
                            blockId
                        ],
                        ...value,
                        autoLoadInn: true,
                        wsChecked: false,
                    };

                    draftState.initialFormState = {
                        ...newStateArr,
                    };
                }
            });

        case formTypes.UPDATE_AGENT_DATA_FROM_ID:
            return produce(state, (draftState) => {
                const { name, value }: UpdateFromInnType = action.payload;

                const [formName, formPartName, formBlockName, blockId] = name
                    .replace(/\[/g, '.')
                    .replace(/\]/g, '')
                    .split('.');

                let newStateArr: any = {};

                if (
                    (formName === 'clientForm' ||
                        formName === 'operatorForm') &&
                    (formPartName === 'sender' ||
                        formPartName === 'receiver') &&
                    (formBlockName === 'senders' ||
                        formBlockName === 'receivers')
                ) {
                    newStateArr = {
                        [formName]: { [formPartName]: { [formBlockName]: [] } },
                    };
                    newStateArr[formName][formPartName][formBlockName][
                        blockId
                    ] = {
                        ...newStateArr[formName][formPartName][formBlockName][
                            blockId
                        ],
                        ...value,
                        autoLoadInn: true,
                        autoLoadId: true,
                        wsChecked: false,
                    };

                    draftState.initialFormState = {
                        ...newStateArr,
                    };
                }
            });

        case formTypes.FILL_STATUS_TABLE_DATA:
            return produce(state, (draftState) => {
                draftState.statusInfo = action.payload;
            });

        case formTypes.CLEAR_STATUS_TABLE_DATA:
            return produce(state, (draftState) => {
                draftState.statusInfo = { ...initialStatusInfo };
            });

        case formTypes.FILL_AGENT_TABLE_DATA:
            return produce(state, (draftState) => {
                draftState.agentInfo = action.payload;
            });

        case formTypes.CLEAR_AGENT_TABLE_DATA:
            return produce(state, (draftState) => {
                draftState.agentInfo = { ...initialAgentInfo };
            });

        case formTypes.SET_AGENTS_COUNTERS_SENDERS:
            return produce(state, (draftState) => {
                draftState.agentsCounters.senders = action.payload;
            });

        case formTypes.SET_AGENTS_COUNTERS_RECEIVERS:
            return produce(state, (draftState) => {
                draftState.agentsCounters.receivers = action.payload;
            });

        case formTypes.SET_AGENTS_COUNTERS_DUBLICATES:
            return produce(state, (draftState) => {
                draftState.agentsCounters.dublicates = action.payload;
            });

        case formTypes.SET_CONNECTED_ID:
            return produce(state, (draftState) => {
                draftState.connectedId = action.payload;
            });

        case formTypes.SET_NOT_CONNECTED_ID:
            return produce(state, (draftState) => {
                draftState.notConnectedId = action.payload;
            });

        case formTypes.SET_FORM_SUBMITING:
            return produce(state, (draftState) => {
                draftState.isFormInSubmiting = action.payload;
            });

        default:
            return state;
    }
};
