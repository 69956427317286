// Core
import React, {
    useState,
    useLayoutEffect,
    useCallback,
    useEffect,
    memo,
} from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Third party libraries
import debounce from 'lodash/debounce';

// Routes
import { Routes } from 'Routes/Routes';

// Redux
import { store } from 'redux/store';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

// Socket
import { setupSocket } from 'api';

// Components
import { Header } from 'components/Header/Header';
import { AsideNav } from 'components/Aside/AsideNav/AsideNav';
import { Toast } from 'components/Toast/Toast';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';

// Address
import { addres } from 'Routes/addres';

// Style
import './App.scss';

export const AppInner = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [statusId, setStatusId] = useState('');
    const [statusTicket, setStatusTicket] = useState('');

    const debounceWindowSize = debounce(() => {
        dispatch(
            uiCombineActions.determWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            }),
        );
    }, 250);

    const updateSize = useCallback(debounceWindowSize, [debounceWindowSize]);

    useEffect(() => {
        setupSocket(store.dispatch, uiCombineActions, formCombineActions);

        dispatch(uiCombineActions.clearToastMessageAction());
        dispatch(uiCombineActions.setProgressBarState(false));
        dispatch(uiCombineActions.setProgressBarName(''));
        dispatch(uiCombineActions.setProgressBarCounters([]));
        dispatch(
            uiCombineActions.determWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            }),
        );
    }, [dispatch]);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, [updateSize]);

    useEffect(() => {
        if (location.search) {
            if (
                location.search.includes('uid') &&
                location.search.includes('status')
            ) {
                const [idEdo, ticketStatus] = location.search
                    .replace('?uid=', '')
                    .replace('status=', '')
                    .split('&');

                setStatusId(idEdo);
                setStatusTicket(ticketStatus);
            } else if (location.search.includes('ticketnumber')) {
                const ticketValue = location.search
                    .replace('?ticketnumber', '')
                    .replace('=', '');

                dispatch(formCombineActions.setTicketNumberAction(ticketValue));
            }
        }
    }, [dispatch, location.search]);

    return (
        <div className="app">
            <AsideNav />

            <Header />

            <main className="main" data-testid="main">
                {statusId && statusTicket && (
                    <Redirect
                        to={{
                            pathname: addres.statusInfo,
                            state: {
                                statusInfo: {
                                    id: statusId,
                                    status_ticket: statusTicket,
                                },
                            },
                        }}
                    />
                )}

                <Routes />
            </main>

            <ProgressBar />

            <Toast />
        </div>
    );
};

export const App = memo(AppInner);
