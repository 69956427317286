// Core
import React, { FC, memo } from 'react';

// Utils
import { ASTRAL_ID } from 'utils/constants';

// Components
import { InputField } from 'components/InputField/InputField';

// Types
import { FindStatusInfoTypes } from './findStatusInfoTypes';

// Style
import './FindStatusInfo.scss';

const FindStatementInner: FC<FindStatusInfoTypes> = ({
    formName,
    formPartName,
    formPartState,
}) => (
    <div className="find-status-info">
        <div className="form-field-row">
            <InputField
                name={`${formName}.${formPartName}`}
                value={formPartState?.id}
                fieldType="id"
                idAdornment={ASTRAL_ID}
                required
            />

            <InputField
                name={`${formName}.${formPartName}`}
                value={formPartState?.status_ticket}
                fieldType="status_ticket"
                required
            />
        </div>
    </div>
);

export const FindStatusInfo = memo(FindStatementInner);
