// Core
import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

// Third party libraryes
import { Location } from 'history';

// Components
import { WarningDialog } from 'components/WarningDialog/WarningDialog';

// Types
import { RouteLeavingGuardType } from './routeLeavingGuardTypes';

export const RouteLeavingGuard: FC<RouteLeavingGuardType> = ({
    when,
    navigate,
    shouldBlockNavigation,
    acceptRouteLeaving,
    dontShowAcceptRouteLeaving,
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />

            <WarningDialog
                title="Данные не сохранены!"
                subtitle="Вы действительно хотите сменить вкладку? Все введенные данные будут удалены."
                visible={modalVisible}
                cancelButtonText="Отмена"
                confirmButtonText="Подтвердить"
                acceptingTrigger={acceptRouteLeaving}
                acceptingCheckboxFunc={dontShowAcceptRouteLeaving}
                onConfirm={handleConfirmNavigationClick}
                onCancel={closeModal}
            />
        </>
    );
};
