// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';
import { countDuplicates } from 'utils/countDuplicates';
import { simplifyArr } from 'utils/simplifyArr';
import { defineShouldHeaderRender } from 'utils/defineShouldHeaderRender';
import { isAgentWithErrorRolluped } from 'utils/isAgentWithErrorRolluped';
import { findAgentWithError } from 'utils/findAgentWithError';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { formCombineActions } from 'redux/form/formActions';

// Components
import { FormPart } from 'components/FormPart/FormPart';
import { UploadZone } from 'components/UploadZone/UploadZone';
import { AsideMain } from 'components/Aside/AsideMain/AsideMain';
import { FormInfo } from 'components/FormInfo/FormInfo';
import { MessageInfo } from 'components/MessageInfo/MessageInfo';
import { ApplicationIdentifier } from './ApplicationIdentifier/ApplicationIdentifier';

// Types
import { BlockPartContentTypes } from './blockPartContentTypes';

// Style
import './BlockPartContent.scss';

const BlockPartContentInner: FC<BlockPartContentTypes> = ({
    formName,
    formState,
    formErrors,
    steperHeight,
    ticketNumber,
    isSendersValid,
    isReceiversValid,
    hasTheFormChange,
    setIsReceiversValid,
    setIsSendersValid,
    restart,
    push,
}) => {
    const dispatch = useDispatch();
    const { operators, agentsCounters, isFormInSubmiting } = useSelector(
        (state: State) => state.form,
        isEqual,
    );
    const { user } = useSelector((state: State) => state.auth, isEqual);
    const [isSenderFooterRender, setIsSenderFooterRender] = useState(false);
    const [isReceiverFooterRender, setIsReceiverFooterRender] = useState(false);
    const [applicationId, setApplicationId] = useState<string | undefined>();

    const getAplicationId = useCallback(() => {
        if (formState && 'application_identifier' in formState) {
            setApplicationId(formState.application_identifier);
        }
    }, [formState]);

    useEffect(() => {
        if (isFormInSubmiting) {
            const sendersWithError = findAgentWithError(
                formErrors?.sender?.senders,
                0,
            );
            const receiversWithError = findAgentWithError(
                formErrors?.receiver?.receivers,
                0,
            );
            const isSendersRollupsOff = isAgentWithErrorRolluped(
                formState?.sender?.senders,
                sendersWithError,
            );
            const isReceiversRollupOff = isAgentWithErrorRolluped(
                formState?.receiver?.receivers,
                receiversWithError,
            );

            if (!isSendersRollupsOff && !isReceiversRollupOff) {
                setTimeout(() => {
                    const blockWithError =
                        document.querySelectorAll('.block--with-error');

                    if (blockWithError.length > 0) {
                        blockWithError[0].scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                        });
                    }
                    dispatch(formCombineActions.setFormSubmitingAction(false));
                }, 250);
            }
        }
    }, [dispatch, formState, formErrors, isFormInSubmiting]);

    useEffect(() => {
        setIsSenderFooterRender(
            defineShouldHeaderRender(formName, 'sender', formState),
        );

        setIsReceiverFooterRender(
            defineShouldHeaderRender(formName, 'receiver', formState),
        );

        getAplicationId();
    }, [formName, formState, getAplicationId]);

    const secondBtnFunc = () => {
        dispatch(formCombineActions.setFormCleanerStateAction(true));

        restart();

        setTimeout(() => {
            dispatch(formCombineActions.setFormCleanerStateAction(false));
        }, 250);
    };

    useEffect(() => {
        if (formState) {
            const senders = formState.sender?.senders;
            const receivers = formState.receiver?.receivers;
            let warnings = 0;

            if (senders) {
                const simplSenders = simplifyArr(
                    senders,
                    operators.map((item) => item.prefix),
                );

                warnings += countDuplicates(simplSenders);

                dispatch(
                    formCombineActions.setAgentsCountersSendersAction(
                        simplSenders.length,
                    ),
                );
            } else {
                dispatch(formCombineActions.setAgentsCountersSendersAction(0));
            }

            if (receivers) {
                const simplReceivers = simplifyArr(
                    receivers,
                    operators.map((item) => item.prefix),
                );

                warnings += countDuplicates(simplReceivers);

                dispatch(
                    formCombineActions.setAgentsCountersReceiversAction(
                        simplReceivers.length,
                    ),
                );
            } else {
                dispatch(
                    formCombineActions.setAgentsCountersReceiversAction(0),
                );
            }

            dispatch(
                formCombineActions.setAgentsCountersDublicatesAction(warnings),
            );
        }
    }, [
        dispatch,
        formState?.sender?.senders,
        formState?.receiver?.receivers,
        operators,
    ]);

    return (
        <div className="block-part-content">
            <div className="main-content">
                <UploadZone formName={formName} />

                {formName === 'operatorForm' && (
                    <ApplicationIdentifier
                        formName={formName}
                        formPartState={formState?.application_identifier}
                        operatorPrefix={user?.prefix}
                    />
                )}

                <FormPart
                    formName={formName}
                    formPartName="sender"
                    ticketNumber={ticketNumber}
                    applicationId={applicationId}
                    isBlockValid={isSendersValid}
                    isFooterRendered={isSenderFooterRender}
                    formPartState={formState?.sender}
                    formPartErrors={formErrors?.sender}
                    setBlockValidation={setIsSendersValid}
                    push={push}
                />

                <FormPart
                    formName={formName}
                    formPartName="receiver"
                    applicationId={applicationId}
                    isBlockValid={isReceiversValid}
                    isFooterRendered={isReceiverFooterRender}
                    formPartState={formState?.receiver}
                    formPartErrors={formErrors?.receiver}
                    setBlockValidation={setIsReceiversValid}
                    push={push}
                />
            </div>

            <AsideMain
                secondBtn={hasTheFormChange}
                steperHeight={steperHeight}
                secondBtnFunc={secondBtnFunc}>
                <FormInfo agentsCounters={agentsCounters} />

                <MessageInfo
                    isRender={agentsCounters.dublicates > 0}
                    title="Будьте внимательны !"
                    message="Продублированные контрагенты будут исключены при обработке заявления."
                />
            </AsideMain>
        </div>
    );
};

export const BlockPartContent = memo(BlockPartContentInner);
