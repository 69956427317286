// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';

// Components
import { ProgressPie } from 'components/ProgressPie/ProgressPie';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Types
import { ProgressBarType } from './progressBarTypes';

// Style
import './ProgressBar.scss';

const ProgressBarInner: FC<ProgressBarType> = () => {
    const { progressBar } = useSelector((state: State) => state.ui, isEqual);
    const [operationsAmount, setOperationsAmount] = useState(0);
    const [currOperation, setCurrOperation] = useState(0);
    const [operationPercent, setOperationPercent] = useState(0);

    useEffect(() => {
        if (progressBar.progressBarState) {
            const counter = progressBar.progressBarCounters.reduce(
                (summ, curr) => curr.counterState + summ,
                0,
            );

            setOperationsAmount(counter);
        }
    }, [progressBar]);

    useEffect(() => {
        if (progressBar.progressBarCounters.length > 0) {
            const counter = progressBar.progressBarCounters.reduce(
                (summ, curr) => curr.loaderState + summ,
                0,
            );

            setCurrOperation(counter);
        } else {
            setCurrOperation(0);
        }
    }, [progressBar.progressBarCounters]);

    useEffect(() => {
        if (operationsAmount > 0 && currOperation > 0) {
            const percent = Math.floor(
                (currOperation / operationsAmount) * 100,
            );

            setOperationPercent(percent);
        } else {
            setOperationPercent(0);
        }
    }, [operationsAmount, currOperation]);

    const determProgressBarStyle = () => {
        let style = 'progress-bar';

        if (progressBar.progressBarState) {
            style += ' progress-bar--visiable';
        }

        if (!progressBar.progressBarState) {
            style += ' progress-bar--hidden';
        }

        return style;
    };

    return (
        <div className={determProgressBarStyle()} data-testid="progress-bar">
            <div className="progress-bar__wrapper">
                <div className="progress-bar__header">
                    <span className="h--5">{progressBar.progressBarName}</span>
                </div>

                <div className="progress-bar__body">
                    <div className="progress-bar__body-decription">
                        {progressBar.progressBarCounters.map((item) => (
                            <div
                                className="progress-item"
                                key={`${item.counterName}`}>
                                <div className="progress-item__name">
                                    <span className="p--sm">
                                        {item.counterName}
                                    </span>
                                </div>

                                <div className="progress-item__counter">
                                    <span className="p--sm">
                                        {item.loaderState} / {item.counterState}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="progress-bar__body-vizualization">
                        <ProgressPie percent={operationPercent} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ProgressBar = memo(ProgressBarInner);
