// Core
import React, { FC, useState, useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';

// Utils
import { getPageHeaders } from 'utils/constants';
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Components
import { UserProfile } from './UserProfile/UserProfile';

// Types
import { HeaderTypes } from './headerTypes';

// Style
import './Header.scss';

const HeaderInner: FC<HeaderTypes> = () => {
    const location = useLocation();
    const { isAuthenticate, user } = useSelector(
        (state: State) => state.auth,
        isEqual,
    );
    const [currHeaderTitle, setCurrHeaderTitle] = useState('');

    useEffect(() => {
        const title = getPageHeaders(location.pathname);

        if (
            (location.pathname === '/operator' && isAuthenticate) ||
            location.pathname !== '/operator'
        ) {
            setCurrHeaderTitle(title);
        } else {
            setCurrHeaderTitle('');
        }
    }, [location.pathname, isAuthenticate]);

    return (
        <header className="header" data-testid="header">
            <div data-testid="page-name" className="page-name">
                <span className="h--5">{currHeaderTitle}</span>
            </div>

            <UserProfile
                isRendering={location.pathname === '/operator'}
                isAuthenticate={isAuthenticate}
                user={user}
            />
        </header>
    );
};

export const Header = memo(HeaderInner);
