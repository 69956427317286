// Core
import React, { FC, memo } from 'react';

// Third party libraries
import { Field } from 'react-final-form';

// Utils
import { getFieldLabel } from 'utils/constants';
import { fieldParser } from 'utils/parse';

// Types
import { InputFieldTypes } from './inputFieldTypes';

// Components
import { FieldAdapter } from './FieldAdapter/FieldAdapter';

// Style
import './InputField.scss';

const InputFieldInner: FC<InputFieldTypes> = ({
    name,
    value,
    idAdornment,
    fieldType,
    variant = 'standard',
    visiable = true,
    disabled = false,
    readOnly = false,
    wsError,
    required = false,
    isLoadFromFile,
    autoLoadInn,
    autoLoadId,
    autoFillByInnNavigator,
    autoFillByInnOperator,
    autoFillByIdOperator,
}) => (
    <div
        className={`input-field ${
            visiable ? 'input-field--visiable' : 'input-field--unvisiable'
        }`}>
        <Field
            name={`${name}.${fieldType}`}
            parse={(currValue) => fieldParser(currValue, fieldType)}
            subscription={{
                active: true,
                touched: true,
                error: true,
                value: true,
            }}>
            {({ input, meta }) => (
                <FieldAdapter
                    name={name}
                    value={value}
                    idAdornment={idAdornment}
                    fieldType={fieldType}
                    variant={variant}
                    input={input}
                    label={getFieldLabel(fieldType)}
                    meta={meta}
                    wsError={wsError}
                    required={required}
                    disabled={disabled}
                    readOnly={readOnly}
                    isLoadFromFile={isLoadFromFile}
                    autoLoadInn={autoLoadInn}
                    autoLoadId={autoLoadId}
                    autoFillByInnNavigator={autoFillByInnNavigator}
                    autoFillByInnOperator={autoFillByInnOperator}
                    autoFillByIdOperator={autoFillByIdOperator}
                />
            )}
        </Field>
    </div>
);

export const InputField = memo(InputFieldInner);
