// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Third party libraries
import { useForm } from 'react-final-form';
import debounce from 'lodash/debounce';

// Utils
import { isEqual } from 'utils/isEqual';
import { ASTRAL_ID } from 'utils/constants';
import { usePrevious } from 'hooks/usePrevious';
import { isEmpty } from 'lodash';
import { deepTrim } from 'utils/deepTrim';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';
import { formCombineActions } from 'redux/form/formActions';

// Types
import { ContractorsType, MainObjectType } from 'redux/form/formTypes';
import { AgentTypes } from './agentTypes';

// Components
import { ClientReceiver } from './ClientReceiver/ClientReceiver';
import { ClientSender } from './ClientSender/ClientSender';
import { OperatorReceiver } from './OperatorReceiver/OperatorReceiver';
import { OperatorSender } from './OperatorSender/OperatorSender';
import { AgentHeader } from './AgentHeader/AgentHeader';
import { ConnectionsIdEdo } from './ConnectionsIdEdo/ConnectionsIdEdo';

// Style
import './Agent.scss';

const AgentInner: FC<AgentTypes> = ({
    fid,
    name,
    ticket,
    formName,
    agentState,
    autoLoadId,
    autoLoadInn,
    isDeletable,
    isDuplicate,
    formBlockName,
    contractorType,
    agentErrorState,
    operatorPrefixState,
    remove,
}) => {
    const dispatch = useDispatch();
    const { mutators } = useForm();
    const { socket } = useSelector((state: State) => state.ui, isEqual);
    const { user } = useSelector((state: State) => state.auth, isEqual);
    const {
        wsErrors,
        agentsCounters,
        connectedId,
        notConnectedId,
        isFormInSubmiting,
    } = useSelector((state: State) => state.form, isEqual);
    const [formBlockWsError, setFormBlockWsError] = useState<
        MainObjectType | undefined
    >(undefined);
    const [isConnected, setIsConnected] = useState(false);
    const [isAgentHaveError, setIsAgentHaveError] = useState(false);
    const prevAgentState = usePrevious(agentState);

    const rollupToggler = useCallback(
        (rollupState: boolean) => {
            mutators.setValue(`${name}.isRolluped`, rollupState);
        },
        [name, mutators],
    );

    useEffect(() => {
        if (isFormInSubmiting && isAgentHaveError) {
            rollupToggler(false);
        }
    }, [isAgentHaveError, isFormInSubmiting, rollupToggler]);

    useEffect(() => {
        if (agentErrorState) {
            const haveAgentError = Object.values(agentErrorState).reduce(
                (acc: number, curr: string | 0) => {
                    if (acc === 0) {
                        if (curr !== 0) {
                            return acc + 1;
                        }

                        return acc;
                    }

                    return acc;
                },
                0,
            );

            setIsAgentHaveError(!!haveAgentError);
        }
    }, [agentErrorState]);

    const sendMessageBySocketDebounced = useCallback(
        debounce(
            (socketSendObj: { socket: WebSocket; message: string }) =>
                dispatch(uiCombineActions.socketSendAction(socketSendObj)),
            500,
        ),
        [],
    );

    const definedAdornment = (contractor: number | undefined) => {
        if (contractor) {
            if (contractor === 2 || contractor === 3) return ASTRAL_ID;
        }

        if (user?.prefix) {
            if (contractor === 1) return user.prefix;
        }

        return '';
    };

    const defineContractor = useCallback(
        (contractorTypeNum: 1 | 2 | 3 | 4) => {
            if (contractorTypeNum === 1) return 'contractor_type_1';
            if (contractorTypeNum === 2) return 'contractor_type_2';
            if (contractorTypeNum === 3) return 'contractor_type_3';
            if (contractorTypeNum === 4) return 'contractor_type_4';

            return null;
        },
        [user?.prefix],
    );

    useEffect(() => {
        if (
            agentState &&
            !agentState.wsChecked &&
            !isEmpty(agentState) &&
            (Object.prototype.hasOwnProperty.call(agentState, 'is_supplier')
                ? Object.keys(agentState).length > 1
                : Object.keys(agentState).length > 0) &&
            agentState !== prevAgentState
        ) {
            const message = {
                contractor_type: contractorType,
                fid,
                ...agentState,
            };

            const prefix = message.id?.slice(0, 3);

            message.prefix =
                operatorPrefixState ||
                definedAdornment(contractorType) ||
                prefix;

            if (prefix !== definedAdornment(contractorType) && message.id) {
                message.id = `${definedAdornment(contractorType)}${message.id}`;
            }

            if (socket && socket.readyState) {
                const socketSendObj = {
                    socket,
                    message: JSON.stringify(deepTrim(message)),
                };

                sendMessageBySocketDebounced(socketSendObj);
            }
        }
    }, [agentState, operatorPrefixState]);

    useEffect(() => {
        if (contractorType) {
            const contractor: ContractorsType | null =
                defineContractor(contractorType);

            if (contractor) {
                const currWsErrorArr = wsErrors[contractor];

                if (currWsErrorArr.length > 0) {
                    const currErrorObj = currWsErrorArr.find(
                        (obj) => obj.fid === fid,
                    );

                    const def: MainObjectType = {
                        contractor_type: contractorType,
                        fid,
                        with_ticket: false,
                        isRolluped: false,
                    };

                    setFormBlockWsError(currErrorObj || def);
                }
            }
        }
    }, [wsErrors]);

    const autoFillByInnNavigator = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                formCombineActions.autoFillByInnNavigatorAction({
                    name: fieldName,
                    value,
                }),
            );
        },
        [dispatch, fid],
    );

    const autoFillByIdOperator = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                formCombineActions.autoFillByIdOperatorAction({
                    name: fieldName,
                    value,
                }),
            );
        },
        [dispatch, fid],
    );

    const autoFillByInnOperator = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                formCombineActions.autoFillByInnOperatorAction({
                    name: fieldName,
                    value,
                }),
            );
        },
        [dispatch, fid],
    );

    // Define elements styles 👇🏼

    const determAgentClass = () => {
        let className = 'agent';

        if (!agentState?.isRolluped) {
            className += ' agent--shown';
        } else {
            className += ' agent--hidden';
        }

        if (isAgentHaveError) {
            className += ' block--with-error';
        }

        if (isDuplicate) {
            className += ' agent--duplicate';
        }

        if (isConnected) {
            className += ' agent--connected';
        }

        return className;
    };

    return (
        <div data-testid="agent" className={determAgentClass()}>
            <AgentHeader
                id={fid}
                formName={formName}
                agentState={agentState}
                isDeletable={isDeletable}
                contractor={contractorType}
                formBlockName={formBlockName}
                isConnected={isConnected || false}
                rollupToggler={rollupToggler}
                remove={remove}
            />

            {formName === 'clientForm' && formBlockName === 'senders' && (
                <ClientSender
                    name={name}
                    ticket={ticket}
                    autoLoadId={autoLoadId}
                    autoLoadInn={autoLoadInn}
                    blockFormState={agentState}
                    formBlockWsError={formBlockWsError}
                    idAdornment={definedAdornment(contractorType)}
                    autoFillByInnNavigator={autoFillByInnNavigator}
                    autoFillByInnOperator={autoFillByInnOperator}
                    autoFillByIdOperator={autoFillByIdOperator}
                />
            )}

            {formName === 'clientForm' && formBlockName === 'receivers' && (
                <ClientReceiver
                    name={name}
                    autoLoadInn={autoLoadInn}
                    blockFormState={agentState}
                    formBlockWsError={formBlockWsError}
                    autoFillByInnNavigator={autoFillByInnNavigator}
                    autoFillByInnOperator={autoFillByInnOperator}
                />
            )}

            {formName === 'operatorForm' && formBlockName === 'senders' && (
                <OperatorSender
                    name={name}
                    autoLoadId={autoLoadId}
                    autoLoadInn={autoLoadInn}
                    blockFormState={agentState}
                    formBlockWsError={formBlockWsError}
                    idAdornment={user?.prefix && user.prefix}
                    autoFillByInnNavigator={autoFillByInnNavigator}
                    autoFillByInnOperator={autoFillByInnOperator}
                    autoFillByIdOperator={autoFillByIdOperator}
                />
            )}

            {formName === 'operatorForm' && formBlockName === 'receivers' && (
                <OperatorReceiver
                    name={name}
                    autoLoadId={autoLoadId}
                    autoLoadInn={autoLoadInn}
                    blockFormState={agentState}
                    formBlockWsError={formBlockWsError}
                    idAdornment={definedAdornment(contractorType)}
                    autoFillByInnNavigator={autoFillByInnNavigator}
                    autoFillByInnOperator={autoFillByInnOperator}
                    autoFillByIdOperator={autoFillByIdOperator}
                />
            )}

            <ConnectionsIdEdo
                fid={fid}
                formName={formName}
                connectedId={connectedId}
                isConnected={isConnected}
                formBlockName={formBlockName}
                agentsCounters={agentsCounters}
                notConnectedId={notConnectedId}
                setIsConnected={setIsConnected}
            />
        </div>
    );
};

export const Agent = memo(AgentInner);
