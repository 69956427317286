// Core
import React, { FC, useRef, memo } from 'react';

// Third party libraries
import { Button } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';

// Store
import { useDispatch } from 'react-redux';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';

// Assets
import { AddOrg } from 'assets/images/svgr/add-org';

// Types
import { FormBlockFooterTypes } from './formBlockFooterTypes';

// Style
import './FormBlockFooter.scss';

const FormBlockFooterInner: FC<FormBlockFooterTypes> = ({
    formName,
    isBlockValid,
    formPartName,
    agentsCounter,
    push,
}) => {
    const formState = useFormState();
    const { mutators } = useForm();
    const dispatch = useDispatch();
    const addBtn = useRef<HTMLDivElement>(null);

    const addAgent = () => {
        if (isBlockValid === 0 && agentsCounter < 100 && push) {
            push(
                `${formName}.${formPartName}.${
                    formPartName === 'sender' ? 'senders' : 'receivers'
                }`,
                { isRolluped: false },
            );

            setTimeout(() => {
                if (addBtn.current) {
                    addBtn.current.scrollIntoView();
                }
            }, 0);
        }

        if (isBlockValid === 1 && agentsCounter < 100 && formState.touched) {
            Object.entries(formState.touched).map((elem) => {
                if (elem[0].includes(`${formName}.${formPartName}`)) {
                    mutators.setFieldTouched(elem[0], true);
                }

                if (elem[0].includes(`${formName}.application_identifier`)) {
                    mutators.setFieldTouched(elem[0], true);
                }

                return elem;
            });

            dispatch(
                uiCombineActions.addToastMessageAction({
                    status: 1,
                    text: 'Для добавления контрагента все поля, выделенные красным в данном блоке, обязательные и должны быть заполнены валидными данными',
                }),
            );
        }

        if (agentsCounter >= 100) {
            dispatch(
                uiCombineActions.addToastMessageAction({
                    status: 1,
                    text: 'Максимально возможное количество контрагентов достигнуто!',
                }),
            );
        }
    };

    return (
        <div className="form-part__footer">
            <div ref={addBtn} className="add-btn__wrapper">
                <Button
                    variant="contained"
                    className="btn"
                    startIcon={<AddOrg />}
                    onClick={addAgent}>
                    Добавить контрагента
                </Button>
            </div>
        </div>
    );
};

export const FormBlockFooter = memo(FormBlockFooterInner);
