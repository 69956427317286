// Core
import React, { FC, memo } from 'react';

// Types
import { ProgressPieType } from './progressPieTypes';

// Style
import './ProgressPie.scss';

const ProgressPieInner: FC<ProgressPieType> = ({ percent }) => {
    const determProgressPieStyle = () => {
        let style = 'progress-pie';

        if (percent === 100) {
            style += ' progress-pie--loaded';
        }

        return style;
    };

    return (
        <div className={determProgressPieStyle()}>
            <div className="progress-pie__percent">{percent}%</div>

            <svg className="pie" viewBox="0 0 33.83098862 33.83098862">
                <circle
                    className="pie__background"
                    stroke="#efefef"
                    strokeWidth="2"
                    fill="none"
                    cx="16.91549431"
                    cy="16.91549431"
                    r="15.91549431"
                />

                <circle
                    className="pie__circle"
                    stroke="#00acc1"
                    strokeWidth="2"
                    strokeDasharray={`${percent},100`}
                    strokeLinecap="round"
                    fill="none"
                    cx="16.91549431"
                    cy="16.91549431"
                    r="15.91549431"
                />
            </svg>
        </div>
    );
};

export const ProgressPie = memo(ProgressPieInner);
