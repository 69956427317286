// Core
import { put, apply } from 'redux-saga/effects';

// API
import { api } from 'api';

// Types
import { LoginType } from 'redux/auth/authTypes';
import { SagaIterator } from '@redux-saga/types';

// Actions
import { authCombineActions } from 'redux/auth/authActions';
import { uiCombineActions } from 'redux/ui/uiActions';

export function* workerLogin({
    payload: loginData,
}: {
    type: string;
    payload: LoginType;
}): SagaIterator {
    try {
        yield put(uiCombineActions.startFetchingAction());

        const response = yield apply(api, api.auth.fetch, [
            loginData.loginName,
            loginData.password,
        ]);
        const { status, prefix, login, text } = yield apply(
            response,
            response.json,
            [],
        );

        if (status === 0) {
            yield put(
                uiCombineActions.addToastMessageAction({
                    status,
                    text: 'Авторизация выполнена',
                }),
            );
        } else {
            yield put(
                uiCombineActions.addToastMessageAction({ status: 1, text }),
            );
        }

        if (response.status !== 200) {
            throw new Error(text);
        }

        if (status === 0 && login && prefix) {
            yield put(authCombineActions.authenticateAction({ login, prefix }));
        }
    } catch (error: any) {
        yield put(uiCombineActions.emitErrorAction(error, 'login worker'));
    } finally {
        yield put(uiCombineActions.stopFetchingAction());
    }
}
