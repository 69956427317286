// Types
import {
    uiTypes,
    UiActionTypes,
    SocketSendObj,
    WindowSizeObj,
    ToastMessageType,
    ProgressBarObjType,
    ProgressBarCountersType,
} from './uiTypes';

export const uiCombineActions = {
    // Sync
    menuThumbAction: () => ({
        type: uiTypes.MENU_THUMB,
    }),
    storeSocketAction: (socket: WebSocket) => ({
        type: uiTypes.STORE_SOCKET,
        payload: socket,
    }),
    startFetchingAction: (): UiActionTypes => ({
        type: uiTypes.START_FETCHING,
    }),
    stopFetchingAction: (): UiActionTypes => ({
        type: uiTypes.STOP_FETCHING,
    }),
    socketSendAction: (socketSendObj: SocketSendObj): UiActionTypes => ({
        type: uiTypes.SOCKET_SEND,
        payload: socketSendObj,
    }),
    emitErrorAction: (
        error: string,
        meta: null | string = null,
    ): UiActionTypes => ({
        type: uiTypes.EMIT_ERROR,
        payload: error,
        error: true,
        meta,
    }),
    addToastMessageAction: (
        toastMessageObj: ToastMessageType,
    ): UiActionTypes => ({
        type: uiTypes.ADD_TOAST_MESSAGE,
        payload: toastMessageObj,
    }),
    removeToastMessageAction: (id: number): UiActionTypes => ({
        type: uiTypes.REMOVE_TOAST_MESSAGE,
        payload: id,
    }),
    clearToastMessageAction: (): UiActionTypes => ({
        type: uiTypes.CLEAR_TOAST_MESSAGE,
    }),
    determWindowSize: (windowSizeObj: WindowSizeObj): UiActionTypes => ({
        type: uiTypes.DETERM_WINDOW_SIZE,
        payload: windowSizeObj,
    }),
    setProgressBar: (progressBarObj: ProgressBarObjType): UiActionTypes => ({
        type: uiTypes.SET_PROGRESS_BAR,
        payload: progressBarObj,
    }),
    setProgressBarState: (state: boolean): UiActionTypes => ({
        type: uiTypes.SET_PROGRESS_BAR_STATE,
        payload: state,
    }),
    setProgressBarName: (name: string): UiActionTypes => ({
        type: uiTypes.SET_PROGRESS_BAR_NAME,
        payload: name,
    }),
    setProgressBarCounters: (
        counters: ProgressBarCountersType[],
    ): UiActionTypes => ({
        type: uiTypes.SET_PROGRESS_BAR_COUNTERS,
        payload: counters,
    }),
    acceptRouteLeavingAction: (): UiActionTypes => ({
        type: uiTypes.ACCEPT_ROUTE_LEAVING,
        payload: undefined,
    }),
    acceptAutoClearingFormAction: (): UiActionTypes => ({
        type: uiTypes.ACCEPT_AUTO_CLEARING_FORM,
        payload: undefined,
    }),
};
