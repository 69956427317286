// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Third party libreries
import { useForm } from 'react-final-form';

// Components
import { InputField } from 'components/InputField/InputField';

// Types
import { MainInfoFieldsTypes } from './mainInfoFieldsTypes';

// Style
import './MainInfoFields.scss';

const MainInfoFieldsInner: FC<MainInfoFieldsTypes> = ({
    mainInfoState,
    name,
    formBlockWsError,
    isLoadFromFile,
    autoLoadInn,
    autoFillByInnNavigator,
    autoFillByInnOperator,
}) => {
    const { mutators } = useForm();
    const [isEqual12, setIsEqual12] = useState(false);

    useEffect(() => {
        const inn = mainInfoState?.inn;

        if (inn && inn.length === 12) {
            setIsEqual12(true);

            if (mainInfoState?.kpp) {
                mutators.setValue(`${name}.kpp`, undefined);
            }

            if (mainInfoState?.name) {
                mutators.setValue(`${name}.name`, undefined);
            }
        } else {
            setIsEqual12(false);

            if (mainInfoState?.lastname) {
                mutators.setValue(`${name}.lastname`, undefined);
            }

            if (mainInfoState?.firstname) {
                mutators.setValue(`${name}.firstname`, undefined);
            }

            if (mainInfoState?.patronymic) {
                mutators.setValue(`${name}.patronymic`, undefined);
            }
        }
    }, [mainInfoState?.inn]);

    return (
        <div className="main-info-fields">
            <div className="form-field-row">
                <InputField
                    name={`${name}`}
                    value={mainInfoState?.inn}
                    fieldType="inn"
                    wsError={formBlockWsError?.inn}
                    required
                    isLoadFromFile={isLoadFromFile}
                    autoFillByInnNavigator={autoFillByInnNavigator}
                    autoFillByInnOperator={autoFillByInnOperator}
                    autoLoadInn={autoLoadInn}
                />

                <InputField
                    name={`${name}`}
                    value={mainInfoState?.kpp}
                    fieldType="kpp"
                    wsError={formBlockWsError?.kpp}
                    required={!isEqual12}
                    disabled={isEqual12}
                    isLoadFromFile={isLoadFromFile}
                />
            </div>

            <InputField
                visiable={!isEqual12}
                value={mainInfoState?.name}
                name={`${name}`}
                fieldType="name"
                wsError={formBlockWsError?.name}
                required={!isEqual12}
                isLoadFromFile={isLoadFromFile}
            />

            <div className="form-field-row">
                <InputField
                    visiable={isEqual12}
                    value={mainInfoState?.lastname}
                    name={`${name}`}
                    fieldType="lastname"
                    wsError={formBlockWsError?.lastname}
                    required={isEqual12}
                    isLoadFromFile={isLoadFromFile}
                />

                <InputField
                    visiable={isEqual12}
                    value={mainInfoState?.firstname}
                    name={`${name}`}
                    fieldType="firstname"
                    wsError={formBlockWsError?.firstname}
                    required={isEqual12}
                    isLoadFromFile={isLoadFromFile}
                />

                <InputField
                    visiable={isEqual12}
                    value={mainInfoState?.patronymic}
                    name={`${name}`}
                    fieldType="patronymic"
                    wsError={formBlockWsError?.patronymic}
                    isLoadFromFile={isLoadFromFile}
                />
            </div>
        </div>
    );
};

export const MainInfoFields = memo(MainInfoFieldsInner);
