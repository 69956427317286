// const stageDomain = 'edo-core-demo.cloud.astral-dev.ru';
const stageDomain = 'roaming-form.edo-1.cloud.astral-dev.ru';
const mainDomain = 'roaming.astral.ru';

const getRoamingUrl = (
    origin: string,
    protocol: 'http' | 'https' | 'ws' | 'wss',
) => {
    switch (origin) {
        // при запуске локально вне докера

        // используем абсолютный путь
        case 'http://localhost:3000':
        case 'http://localhost:3001':
        case 'http://localhost:8080':
        case 'http://localhost':
            return `${protocol}://${stageDomain}/api`;

        // при запуске на production и в локально в докере (http://localhost:8888) (условис максимально приближены к production)
        // длс запуска локально в докере нужно поднсть локальный реверс прокси в качестве бскенда длс фронта:
        // docker-compose up webserver

        // используем относительный путь
        default:
            if (protocol === 'ws' || protocol === 'wss') {
                return `${window.location.origin
                    .replace('http://', `${protocol}://`)
                    .replace('https://', `${protocol}://`)}/api`;
            }
            return '/api';
    }
};

const getRoamingWs = (host: string) => {
    if (host.startsWith('localhost')) {
        return `wss://ws.${stageDomain}/api`;
    }

    if (host === mainDomain) {
        return 'wss://roaming-ws.edo-core.astral-dev.ru/api';
    }

    return `wss://ws.${host}/api`;
};

export const CLIENT_URL = origin;

export const ROOT_URL = getRoamingUrl(window.location.origin, 'https');
export const ROOT_WS = getRoamingWs(window.location.host);
