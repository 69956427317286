// Core
import React, { memo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { FinalForm } from 'components/FinalForm/FinalForm';

// Types
import { StatusInfoType } from 'redux/form/formTypes';

// Style
import './StatusInfo.scss';

const StatusInfoInner = () => {
    const location = useLocation();
    const [statusInfo, setStatusInfo] = useState<StatusInfoType>();

    useEffect(() => {
        const statusState = location.state;

        if (statusState) {
            // @ts-ignore
            setStatusInfo(statusState);
        }
    }, [location.state]);

    return (
        <div className="status-info-page">
            <FinalForm formName="statusInfoForm" statusState={statusInfo} />
        </div>
    );
};

export const StatusInfo = memo(StatusInfoInner);
