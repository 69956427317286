// Utils
import { findIndexFunc } from '../findIndexFunc';

// Types
import { OperatorType } from '../../redux/form/formTypes';

export const doesOperatorNeedAgreement: (
    operators: OperatorType[],
    operatorPrefix: string,
) => boolean = (operators, operatorPrefix) => {
    if (operators && operators.length > 0) {
        const itemId = findIndexFunc(operators, operatorPrefix, 'prefix');

        return operators?.[itemId]?.agreement;
    }

    return false;
};
