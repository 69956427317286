// Core
import React, { FC, useState, useEffect, memo } from 'react';

// Utils
import { fieldValidator } from 'utils/validator';

// Components
import { InputField } from 'components/InputField/InputField';

// Types
import { ClientTicketTypes } from './clientTicketTypes';

// Style
import './ClientTicket.scss';

const ClientTicketInner: FC<ClientTicketTypes> = ({
    blockFormState,
    formName,
    formPartName,
}) => {
    const [ticketError, setTicketError] = useState<string | undefined>();

    const isFieldHaveError = (value: string | undefined) => {
        const answer = fieldValidator(value, 'ticket', false);

        if (typeof answer === 'string') return answer;

        return undefined;
    };

    useEffect(() => {
        setTicketError(isFieldHaveError(blockFormState));
    }, [blockFormState]);

    return (
        <div className="ticket" data-testid="ticket-field">
            <InputField
                name={`${formName}.${formPartName}`}
                value={blockFormState}
                fieldType="ticket"
                wsError={ticketError}
                required={Boolean(ticketError)}
            />
        </div>
    );
};

export const ClientTicket = memo(ClientTicketInner);
