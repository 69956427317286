// Core
import React, { FC, useEffect, memo } from 'react';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { InputField } from 'components/InputField/InputField';
import { MainInfoFields } from 'components/MainInfoFields/MainInfoFields';

// Types
import { formCombineActions } from 'redux/form/formActions';
import { ClientSenderTypes } from './clientSenderTypes';

// Style
import './ClientSender.scss';

const ClientSenderInner: FC<ClientSenderTypes> = ({
    blockFormState,
    name,
    idAdornment,
    formBlockWsError,
    ticket,
    autoLoadInn,
    autoLoadId,
    autoFillByInnNavigator,
    autoFillByInnOperator,
    autoFillByIdOperator,
}) => {
    const dispatch = useDispatch();
    const { loadFromFile } = useSelector((state: State) => state.form, isEqual);

    useEffect(() => {
        dispatch(formCombineActions.fetchSuppliersAction());
    }, [dispatch]);

    return (
        <article className="client-sender">
            <MainInfoFields
                mainInfoState={blockFormState}
                name={`${name}`}
                formBlockWsError={formBlockWsError}
                isLoadFromFile={loadFromFile}
                autoLoadInn={autoLoadInn}
                autoFillByInnNavigator={autoFillByInnNavigator}
                autoFillByInnOperator={autoFillByInnOperator}
            />

            <InputField
                name={`${name}`}
                value={blockFormState?.id}
                idAdornment={idAdornment}
                fieldType="id"
                wsError={formBlockWsError?.id}
                required
                isLoadFromFile={loadFromFile}
                autoLoadId={autoLoadId}
                autoFillByIdOperator={autoFillByIdOperator}
            />

            <InputField
                name={`${name}`}
                value={blockFormState?.email}
                fieldType="email"
                wsError={formBlockWsError?.email}
                required={!(ticket && ticket.length === 16)}
                isLoadFromFile={loadFromFile}
            />
        </article>
    );
};

export const ClientSender = memo(ClientSenderInner);
