// Core
import { put, apply } from 'redux-saga/effects';

// API
import { api } from 'api';

// Types
import {
    AutofilType,
    AutoFillByIdOperatorResponseType,
} from 'redux/form/formTypes';
import { SagaIterator } from '@redux-saga/types';

// Actions
import { formCombineActions } from 'redux/form/formActions';
import { uiCombineActions } from 'redux/ui/uiActions';

export function* autoFillByIdOperatorWorker({
    payload: autoFillObj,
}: {
    type: string;
    payload: AutofilType;
}): SagaIterator {
    try {
        const response = yield apply(api, api.autoCompleteById.fetch, [
            autoFillObj.value,
        ]);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { abonents, text }: AutoFillByIdOperatorResponseType =
            yield apply(response, response.json, []);

        if (response.status !== 200) {
            throw new Error(text);
        }

        yield put(formCombineActions.clearInitialFormStateAction());

        const optimizeData: () =>
            | undefined
            | { lastname: string; firstname: string; patronymic: string }
            | { kpp: string; name: string } = () => {
            if (abonents.abonents[0].inn.length === 10) {
                return {
                    inn: abonents.abonents[0].inn,
                    kpp: abonents.abonents[0].kpp,
                    name: abonents.abonents[0].name,
                };
            }

            if (abonents.abonents[0].inn.length === 12) {
                return {
                    inn: abonents.abonents[0].inn,
                    kpp: '',
                    lastname: abonents.abonents[0].lastname,
                    firstname: abonents.abonents[0].firstname,
                    patronymic: abonents.abonents[0].patronymic,
                };
            }

            return undefined;
        };

        const newAgentData = optimizeData();

        if (newAgentData) {
            yield put(
                formCombineActions.updateAgentDataFromIdAction({
                    name: autoFillObj.name,
                    value: newAgentData,
                }),
            );
        }
    } catch (error: any) {
        yield put(
            uiCombineActions.emitErrorAction(
                error,
                'autoFillByIdOperator worker',
            ),
        );
    }
}
