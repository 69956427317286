// Core
import React, { FC, useEffect, memo } from 'react';

// Third parti libraries
import Button from '@material-ui/core/Button';

// Store
import { State } from 'redux/store';
import { useSelector } from 'react-redux';

// Utils
import { formValidator } from 'utils/validator';
import { isEqual } from 'utils/isEqual';

// Components
import { useFormState } from 'react-final-form';
import { InputField } from 'components/InputField/InputField';

// Assets
import { Login } from 'assets/images/svgr/login';

// Types
import { LoginType } from 'redux/form/formTypes';
import { LoginFormTypes } from './loginFormTypes';

// Style
import './LoginForm.scss';

const LoginFormInner: FC<LoginFormTypes> = ({
    formName,
    mainBlockHeight,
    setIsBlockValid,
}) => {
    const formState = useFormState();
    const { formErrors } = useSelector((state: State) => state.form, isEqual);

    useEffect(() => {
        if (formName === 'loginForm' && formErrors[formName]?.login) {
            const errors: LoginType | undefined = formErrors[formName]?.login;

            if (errors) {
                setIsBlockValid(
                    formValidator(
                        formName,
                        'login',
                        errors,
                        formState.values[formName]?.login,
                    ),
                );
            }
        }
    }, [formErrors[formName]]);

    const triggerApplyBtn = () => {
        document.getElementById('formRoaming')?.dispatchEvent(
            new Event('submit', {
                cancelable: true,
                bubbles: true,
            }),
        );
    };

    return (
        <section
            data-testid="login-form"
            className="login-form"
            style={{
                minHeight: `${
                    mainBlockHeight ? mainBlockHeight - 16 : mainBlockHeight
                }px`,
            }}>
            <div className="login-form__wrapper">
                <div className="login-form__header">
                    <span className="h--5">Введите логин и пароль</span>
                </div>

                <div className="login-form__body">
                    <InputField
                        name={`${formName}.login`}
                        value={formState.values[formName]?.login?.loginName}
                        fieldType="loginName"
                        required
                    />

                    <InputField
                        name={`${formName}.login`}
                        value={formState.values[formName]?.login?.password}
                        fieldType="password"
                        required
                    />
                </div>

                <div className="login-form__footer">
                    <Button
                        type="button"
                        variant="contained"
                        className="btn btn--login"
                        startIcon={<Login />}
                        onClick={triggerApplyBtn}
                        fullWidth>
                        Войти
                    </Button>
                </div>
            </div>
        </section>
    );
};

export const LoginForm = memo(LoginFormInner);
