// Core
import React, { FC, memo } from 'react';

// Third party libraries
import Button from '@material-ui/core/Button';

// Utils
import { MAX_FILE_SIZE } from 'utils/constants';

// Assets
import { Upload } from 'assets/images/svgr/upload';

// API
import { ROOT_URL } from 'api';

// Types
import { UploadContentImageTypes } from './uploadContentImageTypes';

const UploadContentImageInner: FC<UploadContentImageTypes> = ({
    isDragAccept,
    isDragReject,
    isDragActive,
    isDragFileHavy,
    open,
}) => (
    <>
        {isDragAccept && (
            <div className="dropzone__message">
                <div className="dropzone__message-title">
                    <span className="h--5">
                        Файл соответствует, можете отпустить его!
                    </span>
                </div>
            </div>
        )}

        {isDragReject && (
            <div className="dropzone__message">
                <div className="dropzone__message-title">
                    <span className="h--5">
                        Расширение файла не соответствует - .pdf, .png, .jpg,
                        .jpeg, .pjpeg, .jfif, .pjp
                    </span>
                </div>
            </div>
        )}

        {!isDragActive && (
            <>
                <div className="dropzone__message">
                    {isDragFileHavy ? (
                        <div className="dropzone__message-title">
                            <span className="h--5">
                                Размер файла превышает лимит{' '}
                                {`${(MAX_FILE_SIZE / 1048576).toFixed(1)} MB`}
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className="dropzone__message-title">
                                <span className="h--5">
                                    Соглашение о выборе оператора
                                </span>
                            </div>

                            <div className="dropzone__message-descr">
                                <span className="p--m">
                                    Вы можете отправить уже готовое соглашение
                                    или загрузить
                                    <a
                                        href={`${ROOT_URL}/v2/template/download/agreement_template`}>
                                        {' '}
                                        шаблон соглашения.
                                    </a>{' '}
                                    Файл готового соглашения перетащите в данное
                                    поле или нажмите на кнопку, чтобы выбрать
                                    (.pdf, .png, .jpg, .jpeg, .pjpeg, .jfif,
                                    .pjp)
                                </span>
                            </div>
                        </>
                    )}
                </div>

                <Button
                    variant="contained"
                    className="btn"
                    startIcon={<Upload />}
                    onClick={open}>
                    Загрузить подготовленное соглашение
                </Button>
            </>
        )}
    </>
);

export const UploadContentImage = memo(UploadContentImageInner);
