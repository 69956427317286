// Core
import React, { useState, useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';

// Utils
import { isEqual } from 'utils/isEqual';

// Store
import { State } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { FinalForm } from 'components/FinalForm/FinalForm';
import { RouteLeavingGuard } from 'components/RouteLeavingGuard/RouteLeavingGuard';

// Actions
import { uiCombineActions } from 'redux/ui/uiActions';

// Style
import './Operator.scss';

const OperatorInner = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { acceptRouteLeaving, windowSize } = useSelector(
        (state: State) => state.ui,
        isEqual,
    );
    const { isAuthenticate } = useSelector(
        (state: State) => state.auth,
        isEqual,
    );
    const [hasTheFormChange, setHasTheFormChange] = useState(false);
    const [mainBlockHeight, setMainBlockHeight] = useState(0);

    const dontShowAcceptRouteLeaving = () => {
        dispatch(uiCombineActions.acceptRouteLeavingAction());
    };

    const definedMainBlockHeight = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                setMainBlockHeight(node.offsetHeight);
            }
        },
        [windowSize],
    );

    return (
        <div ref={definedMainBlockHeight} className="operator-page">
            <RouteLeavingGuard
                when={hasTheFormChange && !acceptRouteLeaving}
                navigate={(path: any) => {
                    history.push(path);
                }}
                shouldBlockNavigation={(location) => {
                    if (hasTheFormChange) {
                        console.log(location);
                        return true;
                    }
                    return false;
                }}
                acceptRouteLeaving={acceptRouteLeaving}
                dontShowAcceptRouteLeaving={dontShowAcceptRouteLeaving}
            />

            <FinalForm
                formName={isAuthenticate ? 'operatorForm' : 'loginForm'}
                hasTheFormChange={hasTheFormChange}
                mainBlockHeight={mainBlockHeight}
                setHasTheFormChange={setHasTheFormChange}
            />
        </div>
    );
};

export const Operator = memo(OperatorInner);
