// Core
import React, { FC, useState, useEffect, useCallback, memo } from 'react';

// Redux
import { useDispatch } from 'react-redux';

// Utils
import { fieldValidator } from 'utils/validator';
import { usePrevious } from 'hooks/usePrevious';

// Third party libraries
import { useForm } from 'react-final-form';
import { TextField, Tooltip, FormHelperText } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

// Assets
import { EyeShow } from 'assets/images/svgr/eye-show';
import { EyeHide } from 'assets/images/svgr/eye-hide';

// Actions
import { formCombineActions } from 'redux/form/formActions';

// Types
import {
    FormNameType,
    FormPartNameType,
    FormBlockNameType,
} from 'redux/form/formTypes';
import { FieldAdapterTypes } from './fieldAdapterTypes';

// Style
import './FieldAdapter.scss';

const FieldAdapterInner: FC<FieldAdapterTypes> = ({
    name,
    value,
    fieldType,
    idAdornment,
    label,
    variant,
    input,
    meta,
    wsError,
    required,
    disabled,
    readOnly,
    isLoadFromFile,
    autoLoadInn,
    autoLoadId,
    autoFillByInnNavigator,
    autoFillByInnOperator,
    autoFillByIdOperator,
}) => {
    const dispatch = useDispatch();
    const { mutators } = useForm();
    const prevValue = usePrevious(value);
    const [currRequired, setCurrRequired] = useState(false);
    const [validationError, setValidationError] = useState<
        undefined | 0 | string
    >();
    const [isShowPass, setIsShowPass] = useState(false);

    useEffect(() => {
        if (fieldType === 'email') {
            if (typeof value === 'string' && value.length > 0) {
                setCurrRequired(true);
            } else {
                setCurrRequired(required);
            }
        } else {
            setCurrRequired(required);
        }
    }, [value, required]);

    useEffect(() => {
        if (prevValue !== value) {
            if (fieldType === 'inn') {
                if (meta.active) {
                    mutators.setValue(`${name}.autoLoadInn`, false);
                }
            }

            if (fieldType === 'id') {
                if (meta.active) {
                    mutators.setValue(`${name}.autoLoadId`, false);
                }
            }

            if (meta.active) {
                mutators.setValue(`${name}.wsChecked`, false);
            }
        }
    }, [value, meta.active]);

    useEffect(() => {
        setValidationError(
            fieldValidator(value, fieldType, currRequired, wsError),
        );
    }, [value, wsError, currRequired]);

    useEffect(() => {
        // @ts-ignore
        const [formName, formPart, formBlock, blockId]: [
            FormNameType,
            FormPartNameType,
            FormBlockNameType,
            string,
        ] = name.replace(/\[/g, '.').replace(/\]/g, '').split('.');

        dispatch(
            formCombineActions.addFieldErrorAction({
                formName,
                formPart,
                formBlock,
                blockId,
                fieldType,
                error: validationError,
            }),
        );
    }, [validationError]);

    const checkFieldValidation = useCallback(() => {
        if (currRequired && (meta.touched || isLoadFromFile)) {
            return typeof validationError === 'string'
                ? validationError.replace(/\/n/g, '. ').replace(/\\n/g, '. ')
                : undefined;
        }

        return undefined;
    }, [currRequired, meta.touched, validationError, isLoadFromFile]);

    const handleClickShowPassword = () => {
        setIsShowPass(!isShowPass);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const applyInputProps = () => {
        if (idAdornment) {
            return {
                startAdornment: (
                    <InputAdornment position="start">
                        {idAdornment}
                    </InputAdornment>
                ),
                readOnly,
            };
        }

        if (fieldType === 'password') {
            return {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}>
                            {isShowPass ? <EyeShow /> : <EyeHide />}
                        </IconButton>
                    </InputAdornment>
                ),
                readOnly,
            };
        }

        return { readOnly };
    };

    const typeDefinition = (fieldName: string, passwordState: boolean) => {
        if (fieldName === 'password' && !passwordState) return 'password';

        return 'text';
    };

    const triggerAutoFill = useCallback(
        (currState: any, field: string, addres: string) => {
            if (currState && typeof currState === 'string') {
                if (autoFillByInnNavigator && field === 'inn' && !autoLoadInn) {
                    if (
                        (addres.includes('clientForm') &&
                            addres.includes('receiver')) ||
                        (addres.includes('operatorForm') &&
                            addres.includes('sender'))
                    ) {
                        autoFillByInnNavigator(addres, currState);
                    }
                }

                if (autoFillByInnOperator && field === 'inn' && !autoLoadInn) {
                    if (
                        (addres.includes('clientForm') &&
                            addres.includes('sender')) ||
                        (addres.includes('operatorForm') &&
                            addres.includes('receiver'))
                    ) {
                        autoFillByInnOperator(addres, currState);
                    }
                }

                if (autoFillByIdOperator && field === 'id' && !autoLoadId) {
                    autoFillByIdOperator(addres, currState);
                }
            }
        },
        [value, autoLoadInn, autoLoadId],
    );

    useEffect(() => {
        if ((fieldType === 'inn' || fieldType === 'id') && !validationError) {
            triggerAutoFill(value, fieldType, name);
        }
    }, [value, autoLoadInn, autoLoadId, validationError]);

    return (
        <div className="field-adapter">
            <TextField
                className="input-field"
                id={`${name}.${fieldType}`}
                fullWidth
                type={typeDefinition(fieldType, isShowPass)}
                label={label}
                InputProps={applyInputProps()}
                {...input}
                disabled={disabled}
                error={Boolean(checkFieldValidation())}
                required={currRequired}
                variant={variant}
                size="small"
            />

            <Tooltip title={checkFieldValidation() || ''}>
                <FormHelperText className="input-error">
                    <span className="p--s">{checkFieldValidation()}</span>
                </FormHelperText>
            </Tooltip>
        </div>
    );
};

export const FieldAdapter = memo(FieldAdapterInner);
