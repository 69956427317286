// Core
import React, { FC, memo } from 'react';

// Components
import { InputField } from 'components/InputField/InputField';

// Types
import { ApplicationIdentifierTypes } from './applicationIdentifierTypes';

// Style
import './ApplicationIdentifier.scss';

const ApplicationIdentifierInner: FC<ApplicationIdentifierTypes> = ({
    formName,
    formPartState,
    operatorPrefix,
}) => {

    // Define elements styles 👇🏼

    const determApplicationIdentifierClass = () => {
        let className = 'application-identifier';

        if (operatorPrefix === '2BM' && !formPartState) {
            className += ' block--with-error';
        }

        return className;
    };

    return (
        <div className={determApplicationIdentifierClass()}>
            <InputField
                name={`${formName}`}
                value={formPartState}
                fieldType="application_identifier"
                required={operatorPrefix === '2BM'}
            />
        </div>
    );
};
export const ApplicationIdentifier = memo(ApplicationIdentifierInner);
