// Core
import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

// Third party libraries
import { Tooltip } from '@material-ui/core';

// Assets
import { Arrow } from 'assets/images/svgr/arrow';

// Action
import { uiCombineActions } from 'redux/ui/uiActions';

// Types
import { HideThumbTypes } from './hideThumbTypes';

// Style
import './HideThumb.scss';

const HideThumbInner: FC<HideThumbTypes> = ({ isMenuShow }) => {
    const dispatch = useDispatch();

    const menutrigger = () => {
        dispatch(uiCombineActions.menuThumbAction());
    };

    return (
        <Tooltip
            title={!isMenuShow ? 'Развернуть меню' : ''}
            placement="right"
            arrow>
            <button
                type="button"
                className="hide-thumb"
                onClick={menutrigger}
                data-testid="hide-menu-thumb">
                <div className="hide-thumb__icon">
                    <Arrow />
                </div>

                <div className="hide-thumb__title">
                    {!isMenuShow ? 'Развернуть меню' : 'Свернуть меню'}
                </div>
            </button>
        </Tooltip>
    );
};

export const HideThumb = memo(HideThumbInner);
