// Third party libraries
import { produce } from 'immer';

// Types
import { AuthState, AuthActionsType, authTypes } from './authTypes';

const initialState: AuthState = {
    isAuthenticate: false,
    user: null,
};

export const authReducer = (
    state = initialState,
    action: AuthActionsType,
): AuthState => {
    switch (action.type) {
        case authTypes.AUTHENTICATE:
            return produce(state, (draftState) => {
                draftState.isAuthenticate = true;

                draftState.user = { ...action.payload };
            });
        case authTypes.LOGOUT:
            return produce(state, (draftState) => {
                draftState.isAuthenticate = false;
            });

        default:
            return state;
    }
};
