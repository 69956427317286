// Core
import React, { FC, memo } from 'react';

// Third party libraries
import Button from '@material-ui/core/Button';

// API
import { ROOT_URL } from 'api';

// utils
import { MAX_FILE_SIZE } from 'utils/constants';

// Assets
import { Upload } from 'assets/images/svgr/upload';

// Types
import { UploadContentXlsxTypes } from './uploadContentXlsxTypes';

const UploadContentXlsxInner: FC<UploadContentXlsxTypes> = ({
    formName,
    isDragAccept,
    isDragReject,
    isDragActive,
    isDragFileHavy,
    open,
}) => (
    <>
        {isDragAccept && (
            <span className="h--5">
                Файл соответствует, можете отпустить его!
            </span>
        )}

        {isDragReject && (
            <span className="h--5">
                Расширение файла не соответствует - .xlsx
            </span>
        )}

        {!isDragActive && (
            <>
                <div className="dropzone__message">
                    {isDragFileHavy ? (
                        <div className="dropzone__message-title">
                            <span className="h--5">
                                Размер файла превышает лимит{' '}
                                {`${(MAX_FILE_SIZE / 1048576).toFixed(1)} MB`}
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className="dropzone__message-title">
                                <span className="h--5">
                                    Заявление на роуминг
                                </span>
                            </div>

                            <div className="dropzone__message-descr">
                                <span className="p--m">
                                    Вы можете заполнить заявку вручную или из
                                    файла. Для заполнения из файла скачайте
                                    {formName === 'clientForm' && (
                                        <a
                                            href={`${ROOT_URL}/v2/template/download/abonent`}>
                                            {' '}
                                            шаблон заявки
                                        </a>
                                    )}
                                    {formName === 'operatorForm' && (
                                        <a
                                            href={`${ROOT_URL}/v2/template/download/operator`}>
                                            {' '}
                                            шаблон заявки
                                        </a>
                                    )}{' '}
                                    на роуминг в формате XLSX, заполните его и
                                    загрузите. Не меняйте порядок полей в
                                    шаблоне.
                                </span>
                            </div>
                        </>
                    )}
                </div>

                {/* <div className="dropzone__warnings">
                    <span className="h--6">Внимание!</span>
                    <span className="p--m">
                        10.06.2021 шаблон заявки был обновлен. Убедитесь, что
                        при загрузке данных, Вы использовали верный.
                    </span>{' '}
                </div> */}

                <Button
                    variant="contained"
                    className="btn"
                    startIcon={<Upload />}
                    onClick={open}>
                    Загрузить подготовленную заявку
                </Button>
            </>
        )}
    </>
);

export const UploadContentXlsx = memo(UploadContentXlsxInner);
