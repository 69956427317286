import React, { memo } from 'react';

const UploadInner = () => (
    <svg viewBox="0 0 24 24">
        <path d="M11 15L13 15 13 9 16 9 12 4 8 9 11 9z" />
        <path d="M20,18H4v-7H2v7c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-7h-2V18z" />
    </svg>
);

export const Upload = memo(UploadInner);
