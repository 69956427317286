// Core
import { takeEvery, all, call } from 'redux-saga/effects';

// Types
import { formTypes } from '../formTypes';

// Workers
import {
    fetchOperatorWorker,
    fetchSuppliersWorker,
    parseClientsFromFileWorker,
    parseOperatorsFromFileWorker,
    fetchAbonentFormWorker,
    fetchOperatorFormWorker,
    fetchStatusFormWorker,
    fetchConteragentFormWorker,
    autoFillByInnNavigatorWorker,
    autoFillByInnOperatorWorker,
    autoFillByIdOperatorWorker,
} from './workers';

function* watchFetchOperator() {
    yield takeEvery(formTypes.FETCH_OPERATORS_ASYNC, fetchOperatorWorker);
}

function* watchFetchSuppliers() {
    yield takeEvery(formTypes.FETCH_SUPPLIERS_ASYNC, fetchSuppliersWorker);
}

function* watchParseClientsFromFile() {
    yield takeEvery(
        formTypes.PARSE_CLIENTS_FROM_FILE_ASYNC,
        parseClientsFromFileWorker,
    );
}

function* watchParseOperatorsFromFile() {
    yield takeEvery(
        formTypes.PARSE_OPERATORS_FROM_FILE_ASYNC,
        parseOperatorsFromFileWorker,
    );
}

function* watchFetchAbonentForm() {
    yield takeEvery(formTypes.FETCH_ABONENT_FORM_ASYNC, fetchAbonentFormWorker);
}

function* watchFetchOperatorForm() {
    yield takeEvery(
        formTypes.FETCH_OPERATOR_FORM_ASYNC,
        fetchOperatorFormWorker,
    );
}

function* watchFetchStatusForm() {
    yield takeEvery(formTypes.FETCH_STATUS_FORM_ASYNC, fetchStatusFormWorker);
}

function* watchFetchConteragentForm() {
    yield takeEvery(
        formTypes.FETCH_CONTERAGENT_FORM_ASYNC,
        fetchConteragentFormWorker,
    );
}

function* watchAutoFillByInnNavigator() {
    yield takeEvery(
        formTypes.AUTOFILL_BY_INN_NAVIGATOR_ASYNC,
        autoFillByInnNavigatorWorker,
    );
}

function* watchAutoFillByInnOperator() {
    yield takeEvery(
        formTypes.AUTOFILL_BY_INN_OPERATOR_ASYNC,
        autoFillByInnOperatorWorker,
    );
}

function* watchAutoFillByIdOperator() {
    yield takeEvery(
        formTypes.AUTOFILL_BY_ID_OPERATOR_ASYNC,
        autoFillByIdOperatorWorker,
    );
}

export function* watcheForm() {
    yield all([
        call(watchFetchOperator),
        call(watchFetchSuppliers),
        call(watchParseClientsFromFile),
        call(watchParseOperatorsFromFile),
        call(watchFetchAbonentForm),
        call(watchFetchOperatorForm),
        call(watchFetchStatusForm),
        call(watchFetchConteragentForm),
        call(watchAutoFillByInnNavigator),
        call(watchAutoFillByInnOperator),
        call(watchAutoFillByIdOperator),
    ]);
}
