export interface AuthState {
    isAuthenticate: boolean;
    user: UserType | null;
}

export interface LoginType {
    loginName: string;
    password: string;
}

export interface UserType {
    login: string;
    prefix: string;
}

export const authTypes = {
    // Sync
    AUTHENTICATE: 'AUTHENTICATE',
    LOGOUT: 'LOGOUT',

    // Async
    LOGIN_ASYNC: 'LOGIN_ASYNC',
};

interface AuthenticateActionType {
    type: typeof authTypes.AUTHENTICATE;
    payload: UserType;
}

interface LogOutActionType {
    type: typeof authTypes.LOGOUT;
    payload?: any;
}

interface LoginActionType {
    type: typeof authTypes.LOGIN_ASYNC;
    payload: LoginType;
}

export type AuthActionsType =
    | AuthenticateActionType
    | LogOutActionType
    | LoginActionType;
